import { Suspense } from 'react'
import { Outlet as ReactRouterOutlet } from 'react-router-dom'

import { Box } from 'components/layout/box'
import { Container } from 'components/layout/container'

import * as styles from './Outlet.css'

export const Outlet = () => (
	<Box className={styles.outlet}>
		<Suspense
			fallback={
				<Container>
					<div className={styles.fallback}>Loading...</div>
				</Container>
			}>
			<ReactRouterOutlet />
		</Suspense>
	</Box>
)
