import * as styles from './InfoButton.css'
import { Box } from 'components/layout/box'
import { useState } from 'react'
import { useSpring, animated } from 'react-spring'

const val1 = '0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.5 0'
const val2 = '0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.01 0'

export const InfoButton = ({ ...props }) => {
	const [isHovered, setIsHovered] = useState(false)

	const handleMouseEnter = () => {
		setIsHovered(true)
	}

	const handleMouseLeave = () => {
		setIsHovered(false)
	}

	const { offset, stopColor } = useSpring({
		offset: isHovered ? 0.647648 : 0.0347648,
		stopColor: isHovered ? '#fcfcfc' : '#c6c6c6',
		config: { duration: 300 }
	})

	return (
		<Box className={styles.shape} {...props} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
			<svg width="66" height="66" viewBox="0 0 66 66" fill="none" xmlns="http://www.w3.org/2000/svg">
				<g filter="url(#filter0_dd_1620_9854)">
					<path
						d="M42.5905 48.0886C44.4922 47.0595 46.5341 45.9496 48.3117 44.6355C50.0897 43.321 51.6418 41.7751 52.5347 39.8527C54.3404 36.0206 53.4169 30.8929 51.3859 26.8923L51.3847 26.89C49.3087 22.8533 46.0446 19.9085 42.6515 17.3616L42.65 17.3605C39.2522 14.8295 35.5555 12.5581 31.7722 12.5003V12.5003L31.7684 12.5003C27.9588 12.4714 24.2099 14.6289 20.7769 17.1667L20.7754 17.1678C17.3686 19.7054 14.0789 22.7625 12.9653 26.5938C12.4069 28.515 12.4065 30.5928 12.6736 32.7363C12.9404 34.8782 13.4797 37.1217 14.0232 39.3729L14.0237 39.3748C14.5803 41.64 15.1648 43.984 15.9766 46.0631C16.7877 48.1401 17.8448 50.0059 19.3792 51.2815L19.3809 51.2829C22.4709 53.8287 27.2634 53.8715 31.3032 52.9536C35.3531 52.055 38.7868 50.1743 42.5905 48.0886ZM42.5905 48.0886C42.5909 48.0884 42.5913 48.0881 42.5917 48.0879L42.3513 47.6495L42.5893 48.0892C42.5897 48.089 42.5901 48.0888 42.5905 48.0886Z"
						fill="url(#paint0_radial_1620_9854)"
						fillOpacity="0.4"
						stroke="url(#paint1_linear_1620_9854)"
					/>
					<g style={{ mixBlendMode: 'overlay' }}>
						<path
							d="M13.4454 26.7334C12.3816 30.3937 13.4195 34.742 14.5093 39.2555C14.5093 35.511 22.8206 22.5 31.7646 22.5C37.5 22.5 40.8762 20.1743 42.3513 17.7614C38.9522 15.2295 35.3714 13.0553 31.7646 13.0003C28.1319 12.9728 24.4992 15.0368 21.0741 17.5688C17.6749 20.1007 14.5093 23.073 13.4454 26.7334Z"
							fill="url(#paint2_radial_1620_9854)"
						/>
					</g>

					<path
						style={{ transition: 'fill 1s' }}
						d="M32.9993 41.3327C28.397 41.3327 24.666 37.6017 24.666 32.9993C24.666 28.397 28.397 24.666 32.9993 24.666C37.6017 24.666 41.3327 28.397 41.3327 32.9993C41.3327 37.6017 37.6017 41.3327 32.9993 41.3327ZM32.9993 39.666C36.6813 39.666 39.666 36.6813 39.666 32.9993C39.666 29.3174 36.6813 26.3327 32.9993 26.3327C29.3174 26.3327 26.3327 29.3174 26.3327 32.9993C26.3327 36.6813 29.3174 39.666 32.9993 39.666ZM32.166 28.8327H33.8327V30.4993H32.166V28.8327ZM32.166 32.166H33.8327V37.166H32.166V32.166Z"
						fill={isHovered ? '#fff' : '#BABFC9'}
					/>
				</g>
				<defs>
					<filter
						id="filter0_dd_1620_9854"
						x="0"
						y="0"
						width="66"
						height="66"
						filterUnits="userSpaceOnUse"
						colorInterpolationFilters="sRGB">
						<feFlood floodOpacity="0" result="BackgroundImageFix" />
						<feColorMatrix
							in="SourceAlpha"
							type="matrix"
							values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
							result="hardAlpha"
						/>
						<feOffset dx="4" dy="4" />
						<feGaussianBlur stdDeviation="4" />
						<feComposite in2="hardAlpha" operator="out" />
						<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
						<feBlend mode="overlay" in2="BackgroundImageFix" result="effect1_dropShadow_1620_9854" />
						<feColorMatrix
							in="SourceAlpha"
							type="matrix"
							values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
							result="hardAlpha"
						/>
						<feOffset dx="-4" dy="-4" />
						<feGaussianBlur stdDeviation="4" />
						<feComposite in2="hardAlpha" operator="out" />

						<feColorMatrix id="2" type="matrix" values={isHovered ? val1 : val2} />

						<feBlend mode="overlay" in2="effect1_dropShadow_1620_9854" result="effect2_dropShadow_1620_9854" />
						<feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_1620_9854" result="shape" />
					</filter>
					<radialGradient
						id="paint0_radial_1620_9854"
						cx="0"
						cy="0"
						r="1"
						gradientUnits="userSpaceOnUse"
						gradientTransform="translate(20.1429 19.0714) rotate(31.0594) scale(36.6885 23.6743)">
						<stop offset="0.0001" stopColor="#D9D9D9" />
						<stop offset="0.0002" stopColor="#D9D9D9" />
						<stop offset="1" stopColor="#D9D9D9" stopOpacity="0" />
					</radialGradient>
					<linearGradient
						id="paint1_linear_1620_9854"
						x1="11.9326"
						y1="13"
						x2="53"
						y2="13.0006"
						gradientUnits="userSpaceOnUse">
						<stop stopColor="#0AB311" stopOpacity="0" />
						<animated.stop offset={offset} stopColor="#21D97D" />

						<stop offset="1" stopColor="#248B3B" stopOpacity="0" />
					</linearGradient>

					<radialGradient
						id="paint2_radial_1620_9854"
						cx="0"
						cy="0"
						r="1"
						gradientUnits="userSpaceOnUse"
						gradientTransform="translate(21 18.5) rotate(72.1705) scale(21.8027 24.3734)">
						<animated.stop stopColor={stopColor} />
						<stop offset="1" stopColor="#737373" />
					</radialGradient>
				</defs>
			</svg>
		</Box>
	)
}
