import "src/style/theme.css.ts.vanilla.css!=!../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"src/style/theme.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA3WWWW7bMBCG33sKAUaABKhTcri7T5Il3iOLszmxXWdzUvTunaEDlEOyEmzDH0aj2X6Si/12+9L9/tZ18/lzOKzXO7HoxM/sv8T/52D2q6ecAtESqkUnC6QRVXYGoRAnObKIdoecuPQkZ37RASdh0c0gXTm9QBoF3Tm9RDr0Q1yGnF4V+V4vOmd9TlYYCXhmdIPIaoZuMTZjGbqjMHS0ccjpPdJxGKdJ5fShGfK6GfIj0l7RndMnpMGH2E853SA1I9053SLVWveGJbqjYgaIitn+IipBA8tiv+j2t5cXp0p975T93mn8xTkRZ7nRc9NIG2b00jSy3NNr08hzo7emUeBG71TodOX0gHSKEbuS0w9K3fcyDoqV+pPaokPPfUiUzswPFgqMCppZNxpvGUYJzYTQ3hmGFb3TBa051tSxHqbAuiBRSjM1gvbc2lKSgNO0ZNjR4ECQRYAoq5kLVkYmIhnSK5UdOP4S1xg1w6SucRmXk2P4iko1RDfyReWaSmWn2PMAV1+l6tkEyps2RsnNpO2V7hkm2UnjBR9jeZ+srVE8bhKe1EYBt14fx8RPvLCPRyyXPO6nhCdfBLhJeOwdD3Cb8HLQvFQkv2h6kHxOSH9jcE5wvE9tMJPhAT7TKgBaCDbI8oVa7JURvPOvx3Rs5E7ejnHHkafznkTSx57P4CEtc8F47vsjOQk68Hp/NjGQdjAXq9krQTazBNIONljwNQlIO3qUo2T1Bt2UPJg2ts0tBVA7c9y1GPPF3gEh7WwMoVw2282KMdSK2B3SR1r8muOuRosBXZLX5eqfKejCFNiuANdp+TsVHd0/0PPJWXpO4ccefTD7VRn9TXurv23t9XBH1FX4vj4DwANt5bXfdetsAI9Ea7+oMCgZygtqB6guVTKUli4Z6qp6FkVlS4aK8iV7oT6X8JXOCSV8ozLJKqB3wqpO80Dc1rX6+A//bHZB0SHu3Ndc1t1R0OyOUq3uqHSeq+NQpm6PstSe2tS1uqZQSporR6GYbIFQTK5AqCVfINRMKBBqIxiOVvUpVNG5ThUMx18aYn/+Aus/j0EtCwAA\"}!../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
import "src/components/RightSidePanel/PanelApproach/PanelApproach.css.ts.vanilla.css!=!../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"src/components/RightSidePanel/PanelApproach/PanelApproach.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA31V2a7qNhR95yusi64EEkYZmE546cA5T/2Avl058U7i4sSp40B6qvPv3XYIOC1UFpG3vbyHtQfW188/3v6MAvL3jBAu2kayvxKSS+iPeMCkKGoqDFRtQjKoDWh7bDSr21zpKhm2khn4fREHl+vSXn9SUXPoExIeZ1+z9WAhdBaevwyWHjB6AIURqk7QC0mC9a4lwFpA3R44fgGObmDVGQ+9ceis063SCWmUGAJ63CeluoAmDTOlg+ZCyoR0Wi7mafHDSj8cwvd365BXwU2ZEMuBpaAEUZQmIfvtpbRyyrJzoVVXc5opaa1fmF5Q2r7153NzcbRZxblU14SUgnOo7VnDOBd1MYVHtcOnSnPQVDMuOkzP5tD0JMBlN+6tGjnRgDyLC9hT0Sor2ONhC34sSQqYHRh4UkhPjUF8+zZVx1J82BmnzqgmIYHdScjNbauH4IPBS2NUdRcmLou6BC3M4wZLBoNA7YIPSW2YRhf8p1b1aw4fPCfWf5HRwhpDHYvZPNraRYLvK9zHp2j/TkJfiHwhdsIv+9Ovp5/JxglhHEZhRLY+bDfcbPa7zZ4cnBBEdqHq4PtsOfqdKpcTeoX0LAytWHtOiBQ1MP1wcZ7nuU3hcsy7fbZ6DbMarSbko3LpgYRAn8mOw6QPqd+Iu383olYGq2ARcCiWx6fdtLm33tBQ3nOqMCOifkyHu539CzvhYWrp/7Uchj7EUkTqhrLaBEO9ISt07LMalTB5dFX7n9IoB2PQG+q50zUN6AzDul+2JeO2/QLbSPiLYvzMo/D0tj/5yRvARqsz0Fvfh03/CnFzaP7x8bF73x7HaHJWCYmj9jfooebkvWf3q1Z8YiLD9VZDdXfOjeIJQS3LmRZkQtTTOMINfnSRskUcr0gU7lckjLYrTOzWH2Rv0/+AWtX+aGDuVjUsEwZvg3Xsqk/0NJUYAK0Uh2Rgn3LFCxjbkWvVUBydxrZ3Kju9QLJ8u6nTPFa8GyjYiR4gexIarsPzqEKn+6cKuGCkzTRATRgyvKiwfMd0RQckZun0TqL34k+lys5Hd/Z89N2GH73lfpyAdDPKo7HARmMPJg1JyNfs6x+Z0sHxfwcAAA==\"}!../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var comingSoon = 'wzj9q2c';
export var contentBox = 'wzj9q25';
export var glassWrapper = 'wzj9q29';
export var icon = 'wzj9q26';
export var iconExpanded = 'wzj9q27';
export var notch = 'wzj9q24';
export var panelApproach = 'wzj9q20';
export var panelApproachClosing = 'wzj9q23';
export var panelApproachContent = 'wzj9q2b';
export var panelApproachExpanded = 'wzj9q21';
export var panelApproachOpening = 'wzj9q22';
export var title = 'wzj9q28';
export var topReflection = 'wzj9q2a';