import React from 'react'
import clsx from 'clsx'
import { InputHTMLAttributes } from 'react'
import * as styles from './Input.css'

interface CustomInputProps {
	hasError?: boolean
	endIcon?: JSX.Element
	startIcon?: JSX.Element
}

type Props = InputHTMLAttributes<HTMLInputElement> & CustomInputProps

export const Input = React.forwardRef<HTMLInputElement, Props>((props, ref) => (
	<input className={clsx(styles.input, props.hasError && styles.inputError)} ref={ref} {...props} />
))
