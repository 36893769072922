import React, { useState } from 'react'
import clsx from 'clsx'
import { useOktaAuth } from '@okta/okta-react'
import * as Dialog from '@radix-ui/react-dialog'
import * as dialogStyles from '../PageDialog.css'
import * as styles from './SettingsMobileDialog.css'
import { Text } from 'components/typography/text'
import { Box } from 'components/layout/box'
import { CloseButton } from '../components/CloseButton'
import { useMainAppContext } from 'context/MainAppContext'
import { InlineIcon } from 'components/icons/inline-icon'
import { ExternalLink, ExitIcon } from 'assets/icons/icons'

import { redirectToCustomerPortal } from 'helpers/redirectToCustomerPortal'

import { TermsOfService } from './components/TermsOfService'
import { UserProfileForm } from './components/UserProfileForm'
import { IconButton } from 'components/inputs/icon-button'
import { HamburgerTopMenu } from 'assets/icons/icons'
import { BlockIcon } from 'components/icons/block-icon'

type SidebarItem = {
	id: string
	label: string
	isExternal?: boolean
	link?: string
}

const sidebar: SidebarItem[] = [
	{
		id: 'user-profile',
		label: 'User Profile'
	},
	{
		id: 'contact-us',
		label: 'Contact Us'
	},
	{
		id: 'customer-portal',
		label: 'Customer Portal',
		isExternal: true
	},
	{
		id: 'terms-of-service',
		label: 'Terms of Service'
	},
	{
		id: 'privacy-statement',
		label: 'Privacy Statement',
		isExternal: true,
		link: 'https://www.iubenda.com/privacy-policy/34499126'
	}
]

interface Props {
	children: React.ReactNode
}

export const SettingsMobileDialog = ({ children }: Props) => {
	const [isSidebarOpen, setIsSidebarOpen] = useState(false)
	const [activeTab, setActiveTab] = React.useState(sidebar[0])
	const { invalidateUser } = useMainAppContext()
	const { oktaAuth } = useOktaAuth()

	const logout = () => {
		invalidateUser()
	}

	const handleTabClick = (item: SidebarItem) => {
		if (item.id === 'customer-portal') {
			redirectToCustomerPortal(() => oktaAuth.getAccessToken())
		} else {
			setActiveTab(item)
		}
		setIsSidebarOpen(false) // Close sidebar on item click
	}

	const closeSidebar = () => setIsSidebarOpen(false)

	return (
		<Dialog.Root>
			<Dialog.Trigger asChild>{children}</Dialog.Trigger>
			<Dialog.Portal>
				<Dialog.Overlay className={dialogStyles.pageDialogOverlay} />

				<Dialog.Content className={dialogStyles.pageDialogContent}>
					<div className={dialogStyles.imageLayer} />
					{isSidebarOpen && (
						<div className={dialogStyles.sidebarOverlay} onClick={closeSidebar} aria-hidden="true"></div>
					)}

					<Box
						className={clsx(dialogStyles.sidebar, {
							[dialogStyles.sidebarOpen]: isSidebarOpen
						})}>
						<Box className={styles.pageDialogSidebarMobile}>
							{/* Existing Sidebar Content */}
							<Box className={dialogStyles.buttonsWrapper}>
								{sidebar.map(item => {
									return item.link ? (
										<a
											target="_blank"
											href={item.link}
											key={item.id}
											className={clsx(dialogStyles.sidebarButton, {
												[dialogStyles.sidebarButtonActive]: activeTab.id === item.id
											})}>
											{item.label}
											{item.isExternal && (
												<InlineIcon icon={ExternalLink} color="primary.100" style={{ marginLeft: '8px' }} />
											)}
										</a>
									) : (
										<button
											key={item.id}
											className={clsx(dialogStyles.sidebarButton, {
												[dialogStyles.sidebarButtonActive]: activeTab.id === item.id
											})}
											onClick={() => handleTabClick(item)}>
											{item.label}
											{item.isExternal && (
												<InlineIcon icon={ExternalLink} color="primary.100" style={{ marginLeft: '8px' }} />
											)}
										</button>
									)
								})}
								<Box className={styles.logoutButton} onClick={logout}>
									Logout
									<InlineIcon icon={ExitIcon} color="primary.500" style={{ marginLeft: '8px' }} />
								</Box>
							</Box>
						</Box>
					</Box>

					<Box className={dialogStyles.pageDialogMain}>
						{/* Header */}
						<Box padding={4} className={dialogStyles.headerWrapper}>
							<Box onClick={() => setIsSidebarOpen(true)} className={dialogStyles.menuIcon}>
								<BlockIcon icon={HamburgerTopMenu} size="small" color="primary.500" />
							</Box>
							<Dialog.Title>
								<Text className={dialogStyles.mainTitle} as="h1" variant="body1Bold">
									{activeTab.label}
								</Text>
							</Dialog.Title>
							<Dialog.Close asChild style={{ justifySelf: 'end' }}>
								<CloseButton />
							</Dialog.Close>
						</Box>
						{/* BODY */}
						<Box className={dialogStyles.pageDialogBody}>
							{/* Scrollable Content */}
							<Box className={dialogStyles.pageDialogBodyContent}>
								<PageDialogBodyContent type={activeTab.id}></PageDialogBodyContent>
							</Box>
						</Box>
					</Box>
				</Dialog.Content>
			</Dialog.Portal>
		</Dialog.Root>
	)
}

interface PageDialogBodyContentProps {
	type: string
}
const PageDialogBodyContent = ({ type }: PageDialogBodyContentProps) => {
	const renderContent = () => {
		switch (type) {
			case 'user-profile':
				return <UserProfileForm />
			case 'contact-us':
				return (
					<Box as="article" className={dialogStyles.pageDialogBodyContentWrapper}>
						<Box className={dialogStyles.articleSection}>
							<Text as="h2" variant="h2" className={dialogStyles.articleHeader}>
								We value your feedback and would love to hear from you.
							</Text>
							<Text variant="body1" color="neutral.300">
								If you have any questions, concerns, or comments, feel free to reach out. You can contact us at{' '}
								<a href="mailto:support@mavbots.com" className={styles.contactUsEmail} style={{ color: '#7961F2' }}>
									support@mavbots.com
								</a>
								.
							</Text>
						</Box>
					</Box>
				)
			case 'terms-of-service':
				return (
					<Box as="article" className={dialogStyles.pageDialogBodyContentWrapper}>
						<TermsOfService />
					</Box>
				)
		}
	}

	return <div>{renderContent()}</div>
}
