import React, { useEffect, useState } from 'react'

const SafariVersionPopup: React.FC = () => {
	const [isBlocked, setIsBlocked] = useState(false)

	useEffect(() => {
		const userAgent = navigator.userAgent
		const isSafari = /^((?!chrome|android).)*safari/i.test(userAgent)

		if (isSafari) {
			const versionMatch = userAgent.match(/Version\/(\d+)\.(\d+)/)
			if (versionMatch) {
				const majorVersion = parseInt(versionMatch[1], 10)

				if (majorVersion < 17) {
					setIsBlocked(true)
				}
			}
		}
	}, [])

	if (!isBlocked) return null

	return (
		<div
			style={{
				position: 'fixed',
				top: 0,
				left: 0,
				width: '100%',
				height: '100%',
				backgroundColor: 'rgba(0, 0, 0, 0.8)',
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'center',
				zIndex: 1000
			}}>
			<div
				style={{
					background: '#21242B',
					padding: '40px',
					borderRadius: '20px',
					textAlign: 'center'
				}}>
				<div>
					<h1>Unsupported Browser Version</h1>
					<p>
						Your version of Safari is outdated. For the best experience, please upgrade your browser to version 17 or
						above.
					</p>
				</div>
			</div>
		</div>
	)
}

export default SafariVersionPopup
