import axios from 'axios'

const axiosClient = axios.create()

axiosClient.defaults.headers.common = {
	'Content-Type': 'application/json',
	Accept: 'application/json',
	'Access-Control-Allow-Origin': '*',
	'Access-Control-Allow-Credentials': true,
	'Access-Control-Allow-Headers': 'X-Requested-With,content-type'
}

export type EconomicDataInterface = {
	date: string
	time: string
	country: string
	importance: number
	event: string
	description: string
	forecast: string
	actual: string
	previous: string
	unit: string
}

export const fetchEconomic = async (
	period: string,
	accessToken: string | undefined
): Promise<EconomicDataInterface[]> => {
	const response = await axiosClient.get(`${process.env.REACT_APP_API_SERVER}/storage/calendar/economic`, {
		headers: {
			Authorization: `Bearer ${accessToken}`
		},
		params: {
			period: period
		}
	})

	return response.data
}

export type EarningsDataInterface = {
	date: string
	time: string
	mcap: number
	ticker: string
	importance: number
	name: string
	revenue_est: string
	revenue: string
	revenue_surprise_percent: string
	eps_est: string
	eps: string
	eps_surprise_percent: string
}

export const fetchEarnings = async (
	period: string,
	accessToken: string | undefined
): Promise<EarningsDataInterface[]> => {
	const response = await axiosClient.get(`${process.env.REACT_APP_API_SERVER}/storage/calendar/earnings`, {
		headers: {
			Authorization: `Bearer ${accessToken}`
		},
		params: {
			period: period
		}
	})

	return response.data
}
