import React from 'react'

interface SidebarMasterProps {
	height: number
}

export const SidebarMaster = ({ height }: SidebarMasterProps) => {
	return (
		<svg
			width="100%"
			height={height}
			viewBox={`0 0 288 ${height}`}
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			preserveAspectRatio="xMinYMin meet">
			<g
				// style={{ mixBlendMode: 'overlay', transform: 'scaleX(-1)', transformOrigin: 'center' }}
				filter="url(#overlay_shadow_master)">
				<rect x="0" y="0" width="100%" height="120%" fill="#D9D9D9" fillOpacity="0.002" />
			</g>
			{/* <rect opacity="0.8" x="0" y="211" width="288" height="100%" fill="url(#gradient_fill_master)" /> */}
			<path opacity="0.4" d="M0 652V0H286C286 494.925 95.3333 629.771 0 652Z" fill="url(#glassReflectionGradientId)" />

			<g filter="url(#right_border_master)">
				<line x1="286" y1="0" x2="286" y2={height} stroke="url(#border_gradient_master)" strokeWidth="2" />
			</g>
			<defs>
				<clipPath id="svgClipPath">
					<path
						d={`M0 0H288V${height - 345}C288 ${height - 335.16} 289.873 ${height - 334} 268.607 ${
							height - 311.53
						}L251 ${height - 293.03}C245.677 ${height - 282.75} 240.55 ${height - 269.4} 242.55 ${height - 245.56}V${
							height - 170.45
						}C242.55 ${height - 180.05} 254.668 ${height - 90.99} 225.257 ${height - 186.18}L280 ${height - 110}C277 ${
							height - 122
						} 288 ${height - 93} 288 ${height - 82}V${height}H0V0Z`}
					/>
				</clipPath>
				{height < 886 && (
					<filter
						id="overlay_shadow_master"
						x="0"
						y="0"
						width="100%"
						height={height}
						filterUnits="userSpaceOnUse"
						colorInterpolationFilters="sRGB">
						<feFlood floodOpacity="0" result="BackgroundImageFix" />
						<feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
						<feColorMatrix
							in="SourceAlpha"
							type="matrix"
							values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
							result="hardAlpha"
						/>
						<feOffset dx="-20" dy="0" />
						<feGaussianBlur stdDeviation="10" />
						<feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
						<feColorMatrix type="matrix" values="0 0 0 0 0.05 0 0 0 0 0.05 0 0 0 0 0.05 0 0 0 1.9 0" />
						<feBlend mode="normal" in2="shape" result="effect1_innerShadow_1632_5957" />
					</filter>
				)}
				<radialGradient
					id="glassReflectionGradientId"
					cx="0"
					cy="0"
					r="1"
					gradientUnits="userSpaceOnUse"
					gradientTransform="translate(30.5 49.1012) rotate(78.1334) scale(547.091 253.918)">
					<stop stopColor="#5FF2A9" />
					<stop offset="1" stopColor="#73CA91" stopOpacity="0" />
				</radialGradient>
				<filter
					id="right_border_master"
					x="266"
					y="-100"
					width="127.449"
					height="150%"
					filterUnits="userSpaceOnUse"
					colorInterpolationFilters="sRGB">
					<feFlood floodOpacity="0" result="BackgroundImageFix" />
					<feGaussianBlur in="BackgroundImageFix" stdDeviation="20" />
					<feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur" />
					<feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur" result="shape" />
					<feColorMatrix
						in="SourceAlpha"
						type="matrix"
						values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
						result="hardAlpha"
					/>
					<feOffset dy="1" />
					<feGaussianBlur stdDeviation="1" />
					<feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
					<feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.15 0" />
					<feBlend mode="normal" in2="shape" result="inner_shadow" />
				</filter>
				s
				{height < 886 && (
					<linearGradient id="border_gradient_master" x1="284" y1="0" x2="284" y2="100%" gradientUnits="userSpaceOnUse">
						<stop stopColor="#B7DCDA" />
						<stop offset="0.10982" stopColor="#020202" />
						<stop offset="0.197086" stopColor="#23D27E" />
						<stop offset="0.241275" stopColor="#23D27E" />
						<stop offset="0.317125" stopColor="#B7DCDA" />
						<stop offset="0.465" stopColor="#131212" />
						<stop offset="0.532142" stopColor="#23D27E" stopOpacity="0.29" />
						<stop offset="0.715" stopColor="#020202" />
						<stop offset="0.825" stopColor="#383A3A" />
						<stop offset="0.87" stopColor="#B7DCDA" />
						<stop offset="0.955" stopColor="#020202" />
					</linearGradient>
				)}
			</defs>
		</svg>
	)
}
