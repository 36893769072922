import "src/style/theme.css.ts.vanilla.css!=!../../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"src/style/theme.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA3WWWW7bMBCG33sKAUaABKhTcri7T5Il3iOLszmxXWdzUvTunaEDlEOyEmzDH0aj2X6Si/12+9L9/tZ18/lzOKzXO7HoxM/sv8T/52D2q6ecAtESqkUnC6QRVXYGoRAnObKIdoecuPQkZ37RASdh0c0gXTm9QBoF3Tm9RDr0Q1yGnF4V+V4vOmd9TlYYCXhmdIPIaoZuMTZjGbqjMHS0ccjpPdJxGKdJ5fShGfK6GfIj0l7RndMnpMGH2E853SA1I9053SLVWveGJbqjYgaIitn+IipBA8tiv+j2t5cXp0p975T93mn8xTkRZ7nRc9NIG2b00jSy3NNr08hzo7emUeBG71TodOX0gHSKEbuS0w9K3fcyDoqV+pPaokPPfUiUzswPFgqMCppZNxpvGUYJzYTQ3hmGFb3TBa051tSxHqbAuiBRSjM1gvbc2lKSgNO0ZNjR4ECQRYAoq5kLVkYmIhnSK5UdOP4S1xg1w6SucRmXk2P4iko1RDfyReWaSmWn2PMAV1+l6tkEyps2RsnNpO2V7hkm2UnjBR9jeZ+srVE8bhKe1EYBt14fx8RPvLCPRyyXPO6nhCdfBLhJeOwdD3Cb8HLQvFQkv2h6kHxOSH9jcE5wvE9tMJPhAT7TKgBaCDbI8oVa7JURvPOvx3Rs5E7ejnHHkafznkTSx57P4CEtc8F47vsjOQk68Hp/NjGQdjAXq9krQTazBNIONljwNQlIO3qUo2T1Bt2UPJg2ts0tBVA7c9y1GPPF3gEh7WwMoVw2282KMdSK2B3SR1r8muOuRosBXZLX5eqfKejCFNiuANdp+TsVHd0/0PPJWXpO4ccefTD7VRn9TXurv23t9XBH1FX4vj4DwANt5bXfdetsAI9Ea7+oMCgZygtqB6guVTKUli4Z6qp6FkVlS4aK8iV7oT6X8JXOCSV8ozLJKqB3wqpO80Dc1rX6+A//bHZB0SHu3Ndc1t1R0OyOUq3uqHSeq+NQpm6PstSe2tS1uqZQSporR6GYbIFQTK5AqCVfINRMKBBqIxiOVvUpVNG5ThUMx18aYn/+Aus/j0EtCwAA\"}!../../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
import "src/components/MavbotsArena/Arena/LatestTrades/BotCard/BotCard.css.ts.vanilla.css!=!../../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"src/components/MavbotsArena/Arena/LatestTrades/BotCard/BotCard.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA8VWwW6jMBC99yusSislUh1B0qQpvexv7NFgAw7ERrYTkq767zu2gUAgzUZaaaUGqD2eeW/mzcCiVsVrugrQ7yeEKqZ0xRLDjyxCYRAE1ekDlmtOTe4Wfth/KddVSc4RSkvm9ncHbXh6xokUhgkToQSuTNmtnPEsN+3Zr6eFDxf6cIRSLjLY3fhAsVSUKawI5QcdoWXQLp+wzgmVdYTwa3Xyly38wACpLCaz5Xr9gi6XYBFs5i/Imo1MA9j2f4vlej7Bb09OuFlatRCGPAASSYpMyYOgwLqUKkJHomYY6/dTUVR6PshTpji1C/aODdvDqmGYKEaA5HOqIG3P430la9gOU5fHjFQRCuyTUURobrgUkX9OpdoDl7VG4I91JnYZa3MuoZSVYpqpI8Mrh6OSrQPFIBRUu1eapStN5wJspAE4v2bhNqAsm/dMV87UobZkQBCWSpuelCQMH7nmMS+5gTTknFImJhJ+ndy7FDsErw7BJ+aCshMI5mMaegN8GutIzvaOKVe2ESwEKPBhL3pR1y7qNLVLbkms4aDxFZFt+UqWGvfYedvcTfgDGCeaUVe2EDEzNfMQ5ZGptJQ1Bn/kYGQPy5vD4pyDuqHdwv6BPs/Hs7b91vcFTDcXsPKy2NoW7Ny8Ozcl18arG5tzBRIXUrDe2QhtbM//RRt6nNpHt+3WwSAlzwTmYKn7I821YjOwDDsZXCu7IqS994ASB3RiOpTzLl86V1wUQz3Ek3ma8LOblobD9zrIWXILShj2+5neMlsOzJgHCPrCddO5sSxpzyL9Np6dsCrjAvteuJJg5s4+WrSO+AA278POpx23/TJd55G7y7RKuWmbrBeF34A/ctYbSGNad2XYxdsN492fD2Mk+UWPneA6/8VD5bC4b1YjnV+9XZfb5u3a6MFNySs5lNP8vvnkuNG2V++cbk64GZEclLZarSRv7RtMsTRGwlDGWw/VyV7zT/eB5JcmdF7M+3PM8QqXg5Yso9yOvluNsusrd/+/cvCvyO4fICv8dOElYIDBUh7ULFxA8p3Nzz2jnCCdKHiXISIomu2hSA2vt421c+cH35kj4gMVHoRm7kPg626A0Cl2ECFoInTViUuZFN7dLRhtZ0PAP+df4Rp/CwAA\"}!../../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var avatarWrapper = 'wrk4f3h';
export var blurValue = 'wrk4f3n';
export var boldSymbol = 'wrk4f3e';
export var card = 'wrk4f31';
export var cardBack = 'wrk4f36';
export var cardFront = 'wrk4f34';
export var cardSide = 'wrk4f33';
export var cardWrapper = 'wrk4f30';
export var dateTime = 'wrk4f3a';
export var detailsGrid = 'wrk4f3k';
export var flipButton = 'wrk4f3m';
export var flippedCard = 'wrk4f32';
export var greenMessage = 'wrk4f3c';
export var headerWrapper = 'wrk4f3i';
export var hiddenBack = 'wrk4f35';
export var infoGrid = 'wrk4f3j';
export var listItem = 'wrk4f39';
export var mainCardGrid = 'wrk4f3g';
export var message = 'wrk4f3b';
export var redMessage = 'wrk4f3d';
export var showMoreButton = 'wrk4f3l';
export var size = 'wrk4f3f';
export var tradeHistoryContainer = 'wrk4f37';
export var tradeHistoryList = 'wrk4f38';