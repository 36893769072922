import "src/style/theme.css.ts.vanilla.css!=!../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"src/style/theme.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA3WWWW7bMBCG33sKAUaABKhTcri7T5Il3iOLszmxXWdzUvTunaEDlEOyEmzDH0aj2X6Si/12+9L9/tZ18/lzOKzXO7HoxM/sv8T/52D2q6ecAtESqkUnC6QRVXYGoRAnObKIdoecuPQkZ37RASdh0c0gXTm9QBoF3Tm9RDr0Q1yGnF4V+V4vOmd9TlYYCXhmdIPIaoZuMTZjGbqjMHS0ccjpPdJxGKdJ5fShGfK6GfIj0l7RndMnpMGH2E853SA1I9053SLVWveGJbqjYgaIitn+IipBA8tiv+j2t5cXp0p975T93mn8xTkRZ7nRc9NIG2b00jSy3NNr08hzo7emUeBG71TodOX0gHSKEbuS0w9K3fcyDoqV+pPaokPPfUiUzswPFgqMCppZNxpvGUYJzYTQ3hmGFb3TBa051tSxHqbAuiBRSjM1gvbc2lKSgNO0ZNjR4ECQRYAoq5kLVkYmIhnSK5UdOP4S1xg1w6SucRmXk2P4iko1RDfyReWaSmWn2PMAV1+l6tkEyps2RsnNpO2V7hkm2UnjBR9jeZ+srVE8bhKe1EYBt14fx8RPvLCPRyyXPO6nhCdfBLhJeOwdD3Cb8HLQvFQkv2h6kHxOSH9jcE5wvE9tMJPhAT7TKgBaCDbI8oVa7JURvPOvx3Rs5E7ejnHHkafznkTSx57P4CEtc8F47vsjOQk68Hp/NjGQdjAXq9krQTazBNIONljwNQlIO3qUo2T1Bt2UPJg2ts0tBVA7c9y1GPPF3gEh7WwMoVw2282KMdSK2B3SR1r8muOuRosBXZLX5eqfKejCFNiuANdp+TsVHd0/0PPJWXpO4ccefTD7VRn9TXurv23t9XBH1FX4vj4DwANt5bXfdetsAI9Ea7+oMCgZygtqB6guVTKUli4Z6qp6FkVlS4aK8iV7oT6X8JXOCSV8ozLJKqB3wqpO80Dc1rX6+A//bHZB0SHu3Ndc1t1R0OyOUq3uqHSeq+NQpm6PstSe2tS1uqZQSporR6GYbIFQTK5AqCVfINRMKBBqIxiOVvUpVNG5ThUMx18aYn/+Aus/j0EtCwAA\"}!../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
import "src/components/RightSidePanel/PanelTop/PanelTop.css.ts.vanilla.css!=!../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"src/components/RightSidePanel/PanelTop/PanelTop.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA9VW7Y7bKBT9n6dAHUWKpTAy/ogT50+7O32OChvssMHgxc7HtJp3L2A7A0lmuqtWqiorDpd7gXvPOWAev6C6yk7VKQTfZgC0smM9kyIHinLcsyPd6t6vkAlCzzlA29nL7HEagq6G4KKT/NDbISdG+p0eEIZzYzZMwNeusD2bzh1l9a7PQRIdd8buZZuD0LTU4LDtFhPCRJ2DI1YLCLvNeb9vo31gfIVUhCqoMGGHTsfbJ46G6Qtc7mslD4L4Y/8NvJogsmsrLLpKqiYHXYk5XYSB1w2lzonpInWSQ3rGzXTBeCh+aFIfoLygejC1OJVS9FToqj582L4J2ysEnFb9HTQK2feyuRhe+UzsqGK94ykllyofqmix0ss7zq5/5lSXKzkjTvdIk0EStWfzuwZTV8JKWJtl9YyL2UOUmgeE86Vux09R9lmz7BiRa8TW+Ct7+vvpE0isgWIUoQikbthq8CTZKsnA2hphZB6rqVkwpVtImx880WLPetjgbp8DzgTF6jXFh6qqTEXBpCYzbPl2mJWsnkkD2FiiNE70XPIDoTfacfiOLNE3UkKBFxW/Rk0K4ByEj3EHKO6ontsLT94Ij8Zweei9+PSdXWnlNW0PV0mjxEbXKIEU+fs0TQabsK7l+DkHFae2459D17PqGV4kXuo3VcaFOasF1AA2ndt9r/qt3SRWsO52bSwb5UF1xtNKNs0xNiE96mk7O48PRL6TR6osHK58r1lHcUpovQSqLvAChfESREi/UJwsjWK09MDDKntK1ysrveCNPNEVzePyOa76MYn/dALc2+wQbTxiotQnZnJftoQZBs0Jcjkb0uEcdnG4W+/jZqiPsxa22CzWSv5cS7GA0RyESwvB5X+z0eBs0rm1liDL5mCVmkY8B2mmGymagzie+8isfkKhaeohkWT3JfoOEuEfJOHV75Vw9hNEodBnamLmfzCVRH/UeZP9XrLWPyJr5GPkZ+3B/94xQYl7oVtdHT2jfSfBXfCLyKt0JOzYV3rJ2vacxhSyMPSA2FggZItL1ut1NdXDDfQMC04FgY0kNB8ShkSSmk4VEyVbWDGul81BwQ9qoT+AfhFCCv+Kh+1iN/6PDSUM6++/olQALAhYuFfgaK2vwIEdepW2M1fBZbnf2r77rLrXRe/CeHP9vrnuvrgr41+5svv9ujoZXmYv3wG327AHbwwAAA==\"}!../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var contentBox = '_1gf7wfw1';
export var contentBoxClosing = '_1gf7wfw4';
export var contentBoxExpanded = '_1gf7wfw2';
export var contentBoxOpening = '_1gf7wfw3';
export var leftIconWrapper = '_1gf7wfw7';
export var middleIconWrapper = '_1gf7wfw6';
export var notificationNumber = '_1gf7wfw8';
export var panelTop = '_1gf7wfw0';
export var rightIconWrapper = '_1gf7wfw5';
export var topBackgroundReflection = '_1gf7wfwa';
export var topReflection = '_1gf7wfw9';