import React from 'react'
import clsx from 'clsx'
import { useOktaAuth } from '@okta/okta-react'
import * as Dialog from '@radix-ui/react-dialog'
import * as dialogStyles from '../PageDialog.css'
import * as styles from './SettingsDialog.css'
import { Text } from 'components/typography/text'
import { Box } from 'components/layout/box'
import { CloseButton } from '../components/CloseButton'
import { useMainAppContext } from 'context/MainAppContext'
import { InlineIcon } from 'components/icons/inline-icon'
import { ExternalLink, ExitIcon } from 'assets/icons/icons'

import { redirectToCustomerPortal } from 'helpers/redirectToCustomerPortal'

import { TermsOfService } from './components/TermsOfService'
import { UserProfileForm } from './components/UserProfileForm'

type SidebarItem = {
	id: string
	label: string
	isExternal?: boolean
	link?: string
}

const sidebar: SidebarItem[] = [
	{
		id: 'user-profile',
		label: 'User Profile'
	},
	{
		id: 'contact-us',
		label: 'Contact Us'
	},
	{
		id: 'customer-portal',
		label: 'Customer Portal',
		isExternal: true
	},
	{
		id: 'terms-of-service',
		label: 'Terms of Service'
	},
	{
		id: 'privacy-statement',
		label: 'Privacy Statement',
		isExternal: true,
		link: 'https://www.iubenda.com/privacy-policy/34499126'
	}
]

interface Props {
	children: React.ReactNode
}

export const SettingsDialog = ({ children }: Props) => {
	const [activeTab, setActiveTab] = React.useState(sidebar[0])
	const { invalidateUser } = useMainAppContext()
	const { oktaAuth } = useOktaAuth()

	const logout = () => {
		invalidateUser()
	}

	const handleTabClick = (item: SidebarItem) => {
		if (item.id === 'customer-portal') {
			redirectToCustomerPortal(() => oktaAuth.getAccessToken())
		} else {
			setActiveTab(item)
		}
	}

	return (
		<Dialog.Root>
			<Dialog.Trigger asChild>{children}</Dialog.Trigger>
			<Dialog.Portal>
				<Dialog.Overlay className={dialogStyles.pageDialogOverlay} />

				<Dialog.Content className={dialogStyles.pageDialogContent}>
					<div className={dialogStyles.imageLayer} />
					<Box className={dialogStyles.pageDialogSidebar}>
						<svg
							className={dialogStyles.topShapeSvg}
							xmlns="http://www.w3.org/2000/svg"
							width="100%"
							height="248"
							viewBox="0 0 251 248"
							fill="none"
							preserveAspectRatio="none">
							<g style={{ mixBlendMode: 'color-dodge' }} opacity="0.2" filter="url(#filter1)">
								<path
									d="M189.382 0H0V72V94.802V248C5.9921 155.608 15.3205 64.1882 86.979 64.1882C213.375 64.1882 246.334 12.5764 250.196 5.63211C250.482 5.1183 250.602 5.14282 250.602 4.55489C250.602 2.36235 248.825 0 246.632 0H189.382Z"
									fill="url(#paint)"
								/>
							</g>
							<defs>
								<filter
									id="filter1"
									x="-2.39795"
									y="-3"
									width="256"
									height="254"
									filterUnits="userSpaceOnUse"
									colorInterpolationFilters="sRGB">
									<feFlood floodOpacity="0" result="BackgroundImageFix" />
									<feGaussianBlur in="BackgroundImageFix" stdDeviation="1.5" />
									<feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_5177_9661" />
									<feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_5177_9661" result="shape" />
								</filter>
								<radialGradient
									id="paint"
									cx="0"
									cy="0"
									r="1"
									gradientUnits="userSpaceOnUse"
									gradientTransform="translate(0.602198 -4.17373e-05) scale(246.946 233.412)">
									<stop offset="0.0001" stopColor="#D9D9D9" />
									<stop offset="0.0002" stopColor="#D9D9D9" />
									<stop offset="0.935" stopColor="#D9D9D9" stopOpacity="0" />
									<stop offset="1" stopColor="#010B08" stopOpacity="0" />
								</radialGradient>
							</defs>
						</svg>

						{/* RightBorderSvg */}
						<svg
							className={dialogStyles.rightBorderSvg}
							xmlns="http://www.w3.org/2000/svg"
							width="4px"
							height="100%"
							viewBox="0 0 1 778"
							fill="none">
							<path d="M1 0V778" stroke="url(#linear_gradient)" strokeWidth="1" />
							<defs>
								<linearGradient id="linear_gradient" x1="0" y1="0" x2="1" y2="778" gradientUnits="userSpaceOnUse">
									<stop stopColor="#252525" />
									<stop offset="0.01" stopColor="#23D27E" />
									<stop offset="0.20" stopColor="#23D27E" />
									<stop offset="0.24" stopColor="#23D27E" />
									<stop offset="0.32" stopColor="#B7DCDA" />
									<stop offset="0.45" stopColor="#131212" />
									<stop offset="0.532142" stopColor="#23D27E" stopOpacity="0.29" />
									<stop offset="0.72" stopColor="#147647" stopOpacity="0.6" />
									<stop offset="1" stopColor="#020202" />
								</linearGradient>
							</defs>
						</svg>

						<Box className={dialogStyles.buttonsWrapper}>
							{sidebar.map(item => {
								return item.link ? (
									<a
										target="_blank"
										href={item.link}
										key={item.id}
										className={clsx(dialogStyles.sidebarButton, {
											[dialogStyles.sidebarButtonActive]: activeTab.id === item.id
										})}>
										{item.label}
										{item.isExternal && (
											<InlineIcon icon={ExternalLink} color="primary.100" style={{ marginLeft: '8px' }} />
										)}
									</a>
								) : (
									<button
										key={item.id}
										className={clsx(dialogStyles.sidebarButton, {
											[dialogStyles.sidebarButtonActive]: activeTab.id === item.id
										})}
										onClick={() => handleTabClick(item)}>
										{item.label}
										{item.isExternal && (
											<InlineIcon icon={ExternalLink} color="primary.100" style={{ marginLeft: '8px' }} />
										)}
									</button>
								)
							})}
							<Box className={styles.logoutButton} onClick={logout}>
								Logout
								<InlineIcon icon={ExitIcon} color="primary.500" style={{ marginLeft: '8px' }} />
							</Box>
						</Box>
					</Box>

					<Box className={dialogStyles.pageDialogMain}>
						{/* Header */}
						<Box padding={4} className={dialogStyles.headerWrapper}>
							<div></div>
							<Dialog.Title>
								<Text className={dialogStyles.mainTitle} as="h1" variant="body1Bold">
									{activeTab.label}
								</Text>
							</Dialog.Title>
							<Dialog.Close asChild style={{ justifySelf: 'end' }}>
								<CloseButton />
							</Dialog.Close>
						</Box>
						{/* BODY */}
						<Box className={dialogStyles.pageDialogBody}>
							{/* Scrollable Content */}
							<Box className={dialogStyles.pageDialogBodyContent}>
								<PageDialogBodyContent type={activeTab.id}></PageDialogBodyContent>
							</Box>
						</Box>
					</Box>
				</Dialog.Content>
			</Dialog.Portal>
		</Dialog.Root>
	)
}

interface PageDialogBodyContentProps {
	type: string
}
const PageDialogBodyContent = ({ type }: PageDialogBodyContentProps) => {
	const renderContent = () => {
		switch (type) {
			case 'user-profile':
				return <UserProfileForm />
			case 'contact-us':
				return (
					<Box as="article" className={dialogStyles.pageDialogBodyContentWrapper}>
						<Box className={dialogStyles.articleSection}>
							<Text as="h2" variant="h2" className={dialogStyles.articleHeader}>
								We value your feedback and would love to hear from you.
							</Text>
							<Text variant="body1" color="neutral.300">
								If you have any questions, concerns, or comments, feel free to reach out. You can contact us at{' '}
								<a href="mailto:support@mavbots.com" className={styles.contactUsEmail} style={{ color: '#7961F2' }}>
									support@mavbots.com
								</a>
								.
							</Text>
						</Box>
					</Box>
				)
			case 'terms-of-service':
				return (
					<Box as="article" className={dialogStyles.pageDialogBodyContentWrapper}>
						<TermsOfService />
					</Box>
				)
		}
	}

	return <div>{renderContent()}</div>
}
