import { Box } from 'components/layout/box'
import { Text } from 'components/typography/text'
import * as dialogStyles from '../../PageDialog.css'

export const TermsOfService = () => {
	return (
		<>
			<Box className={dialogStyles.articleSection}>
				<Text variant="body2Bold">
					PLEASE READ THIS AGREEMENT CAREFULLY BEFORE USING THIS SERVICE. BY USING THE SERVICE, CLICKING AGREE OR OTHER
					FORM OF ASSENT, YOU ARE AGREEING TO BE BOUND BY THIS AGREEMENT. THIS AGREEMENT IS LIMITED TO PURCHASES FOR
					INDIVIDUAL PERSONAL USE, AND NOT FOR ANY BUSINESS ENTITY OR ORGANIZATION PURCHASES. This agreement is between
					MavBots LLC, a Texas limited liability company (MavBots), and the individual Customer agreeing to these terms
					(Customer).
				</Text>
			</Box>
			<Box className={dialogStyles.articleSection}>
				<Text as="h1" variant="h1" className={dialogStyles.articleHeader}>
					SOFTWARE SERVICE
				</Text>
				<Text variant="body2">
					This agreement and the applicable order provide Customer access to and usage of an Internet-based software
					service, including, without limitation, its features, functions, and user interface, and underlying software,
					as specified on an order (Service).
				</Text>
			</Box>

			<Box className={dialogStyles.articleSection}>
				<Text as="h1" variant="h1" className={dialogStyles.articleHeader}>
					USE OF SERVICE
				</Text>
				<Text as="h2" variant="h2" className={dialogStyles.articleHeader}>
					MavBots Information
				</Text>
				<Text variant="body2">
					MavBots hereby grants Customer a non-exclusive and non-transferable license to use all data and information
					provided by as part of the Service (MavBots Information) only for Customers personal use. Each account login
					is limited to 1 active session, on 1 device at a time. *Restrictions* Customer may not (i) share or disclose
					the MavBots Information, in whole or in part publicly or with any third parties; (ii) cache or store locally
					any of the MavBots Information, other than as expressly allowed by this agreement; (iii) create, promote,
					offer, use or support any functionality designed to extract data or to “scrape” MavBots Information from the
					Service; (v) use the MavBots Information for the purpose of creating or augmenting any knowledge base,
					database, document or software (other than expressly allowed in this agreement); (vi) use the MavBots
					Information in any way that competes with MavBots; (vii) resell, rent or transfer the MavBots Information to
					any third party; or (viii) use the Service for any commercial business or organization.
				</Text>
			</Box>
			<Box className={dialogStyles.articleSection}>
				<Text as="h2" variant="h2" className={dialogStyles.articleHeader}>
					Customer Responsibilities
				</Text>
				<Text variant="body2">
					Customer: (i) must keep its passwords secure and confidential and use industry-standard password management
					practices; (ii) is responsible for its access control policies and administration of access rights to its
					account within the Service, and the acts and omissions of its users; (iii) must use commercially reasonable
					efforts to prevent unauthorized access to its account, and notify MavBots promptly of any such unauthorized
					access; (iv) may use the Service only in accordance with applicable law; and (v) represents and warrants to
					MavBots that all information provided to setup its account within the Service is true and accurate.
				</Text>
			</Box>
			<Box className={dialogStyles.articleSection}>
				<Text as="h2" variant="h2" className={dialogStyles.articleHeader}>
					Third Party Service
				</Text>
				<Text variant="body2">
					The Service interoperates with certain third party services (e.g. Okta and Stripe) (Third Party Service), and
					it depends on continuing availability of and access to Third Party Service, including application programming
					interfaces, for full functionality of the Service. Customer must comply with any Third-Party Service terms
					which are presented to Customer, and may not abuse the use of any such Third Party Service.
				</Text>
			</Box>
			<Box className={dialogStyles.articleSection}>
				<Text as="h1" variant="h1" className={dialogStyles.articleHeader}>
					WARRANTY DISCLAIMER
				</Text>
				<Text variant="body2Bold">
					THE SERVICE PROVIDES INFORMATION AND DATA BASED ON STATISTICAL MODELS THAT MavBots BELIEVES MAY BE USEFUL IN
					MAKING ANALYSIS DECISIONS. MavBots MAKES NO TYPE OF TRADING RESULT REPRESENTATION OR WARRANTY OF ANY KIND.
					CUSTOMER ASSUMES ALL TRADING RISK OF LOSS. THE MavBots INFORMATION IS PROVIDED FOR EDUCATIONAL PURPOSES TO BE
					USED AS PART OF A VARIETY OF FACTORS FOR MAKING ANALYSIS DECISIONS. CUSTOMER UNDERSTANDS THAT THE SERVICE AND
					MavBots INFORMATION MAY NOT BE ERROR FREE AND THE SERVICE MAY BE TEMPORARILY INTERRUPTED. THE SERVICE AND THE
					MavBots INFORMATION IS PROVIDED ‘AS IS' WITHOUT WARRANTY. MavBots DISCLAIMS ALL WARRANTIES, INCLUDING, WITHOUT
					LIMITATION, THE IMPLIED WARRANTIES OF MERCHANTABILITY, TITLE, AND FITNESS FOR A PARTICULAR PURPOSE. WHILE
					MavBots TAKES REASONABLE PHYSICAL, TECHNICAL, AND ADMINISTRATIVE MEASURES TO SECURE THE SERVICE, MavBots DOES
					NOT GUARANTEE THAT THE SERVICE CANNOT BE COMPROMISED. CONSUMER LAW NOTICE. SOME STATES MAY NOT ALLOW SUCH
					DISCLAIMERS, SO THE ABOVE DISCLAIMER MAY NOT APPLY TO CUSTOMER.
				</Text>
			</Box>
			<Box className={dialogStyles.articleSection}>
				<Text as="h1" variant="h1" className={dialogStyles.articleHeader}>
					PAYMENT
				</Text>
				<Text as="h2" variant="h2" className={dialogStyles.articleHeader}>
					Fees and Payment
				</Text>
				<Text variant="body2">
					Customer must pay all fees as specified on the order. The fees are exclusive of sales, use, withholding, VAT
					and other similar taxes, and Customer is responsible for payment of such taxes at the rate and in the manner
					for the time being prescribed by law. All fees for the Service are non-refundable and prices are subject to
					change on the renewal date of the order. All orders will auto renew unless terminated as described at
					<a href="https://stripe.com/legal/end-users"> https://stripe.com/legal/end-users</a>. This agreement
					contemplates one or more orders for the Service, which orders are governed by the terms of this agreement.
				</Text>
			</Box>
			<Box className={dialogStyles.articleSection}>
				<Text as="h2" variant="h2" className={dialogStyles.articleHeader}>
					Nonpayment
				</Text>
				<Text variant="body2">
					If an invoiced amount is past due, MavBots may suspend the Service until the amount is paid in full, provided
					MavBots will make reasonable efforts to provide Customer at least 2 days’ prior electronic notice (to the
					email address registered with the Service for ‘payment purposes’) that its account is past due.
				</Text>
			</Box>
			<Box className={dialogStyles.articleSection}>
				<Text as="h1" variant="h1" className={dialogStyles.articleHeader}>
					MUTUAL CONFIDENTIALITY
				</Text>
				<Text as="h2" variant="h2" className={dialogStyles.articleHeader}>
					Definition of Confidential Information
				</Text>
				<Text variant="body2">
					Confidential Information means all non-public information disclosed by a party (Discloser) to the other party
					(Recipient), whether orally, visually, or in writing, that is designated as confidential or that reasonably
					should be understood to be confidential given the nature of the information and the circumstances of
					disclosure (Confidential Information). MavBots's Confidential Information includes, without limitation, the
					Service and MavBots Information.
				</Text>
			</Box>
			<Box className={dialogStyles.articleSection}>
				<Text as="h2" variant="h2" className={dialogStyles.articleHeader}>
					Protection of Confidential Information
				</Text>
				<Text variant="body2">
					Recipient must use the same degree of care that it uses to protect the confidentiality of its own confidential
					information of like kind (but not less than reasonable care) to: (i) not use any Confidential Information of
					Discloser for any purpose outside the scope of this agreement; and (ii) limit access to Confidential
					Information of Discloser to those of its employees and contractors who need that access for purposes
					consistent with this agreement and who have signed confidentiality agreements with Recipient containing
					protections not materially less protective of the Confidential Information than those in this agreement.
				</Text>
			</Box>
			<Box className={dialogStyles.articleSection}>
				<Text as="h2" variant="h2" className={dialogStyles.articleHeader}>
					Exclusions
				</Text>
				<Text variant="body2">
					Confidential Information excludes information that: (i) is or becomes generally known to the public without
					breach of any obligation owed to Discloser; (ii) was known to the Recipient before its disclosure by the
					Discloser without breach of any obligation owed to the Discloser; (iii) is received from a third party without
					breach of any obligation owed to Discloser; or (iv) is independently developed by the Recipient without use of
					or access to the Confidential Information. The Recipient may disclose Confidential Information to the extent
					required by law or court order, but will provide Discloser with advance notice to seek a protective order.
				</Text>
			</Box>
			<Box className={dialogStyles.articleSection}>
				<Text as="h1" variant="h1" className={dialogStyles.articleHeader}>
					PROPERTY
				</Text>
				<Text as="h2" variant="h2" className={dialogStyles.articleHeader}>
					Reservation of Rights
				</Text>
				<Text variant="body2">
					MavBots and its licensors are the sole owners of the Service and the MavBots Information, including all
					associated intellectual property rights, and they remain only with MavBots and its licensors. Customer may not
					remove or modify any proprietary marking or restrictive legends in the Service or MavBots Information. MavBots
					reserves all rights that are not expressly granted in this agreement.
				</Text>
			</Box>
			<Box className={dialogStyles.articleSection}>
				<Text as="h2" variant="h2" className={dialogStyles.articleHeader}>
					Restrictions
				</Text>
				<Text variant="body2">
					Customer may not: (i) sell, resell, rent, or lease the Service or use it in a service-provider capacity; (ii)
					use the Service to store or transmit unsolicited marketing emails, libelous, or otherwise objectionable,
					unlawful, or tortious material, or to store or transmit infringing material in violation of third-party
					rights; (iii) interfere with or disrupt the integrity or performance of the Service; (iv) attempt to gain
					unauthorized access to the Service or its related systems or networks; (v) attempt to reverse engineer the
					Service or discover its underlying algorithms or processes, except as allowed by applicable law despite this
					limitation; or (vi) access the Service to build a competitive service or product, or copy any feature,
					function, or graphic for competitive purposes. MavBots may suspend Service to Customer if MavBots believes in
					good faith that Customer’s use of the Service poses an imminent threat to the security, availability or
					legality of the Service.
				</Text>
			</Box>
			<Box className={dialogStyles.articleSection}>
				<Text as="h2" variant="h2" className={dialogStyles.articleHeader}>
					Statistical Information
				</Text>
				<Text variant="body2">
					MavBots may compile statistical information related to the performance of the Service and may make such
					information publicly available, provided that such information does not identify Customer, and there is no
					means to re-identify Customer. MavBots retains all intellectual property rights in such information.
				</Text>
			</Box>
			<Box className={dialogStyles.articleSection}>
				<Text as="h1" variant="h1" className={dialogStyles.articleHeader}>
					TERM AND TERMINATION
				</Text>
				<Text as="h2" variant="h2" className={dialogStyles.articleHeader}>
					Terms
				</Text>
				<Text variant="body2">
					This agreement continues until the 30th day after all orders have expired, unless earlier terminated as
					provided below.
				</Text>
			</Box>
			<Box className={dialogStyles.articleSection}>
				<Text as="h2" variant="h2" className={dialogStyles.articleHeader}>
					Term of Orders
				</Text>
				<Text variant="body2">The term of each order must be specified in the order.</Text>
			</Box>
			<Box className={dialogStyles.articleSection}>
				<Text as="h2" variant="h2" className={dialogStyles.articleHeader}>
					Termination for Breach
				</Text>
				<Text variant="body2">
					If Customer breaches this agreement, this agreement will terminate the earlier of (i) when Customer’s account
					is de-activated or (ii) electronic notice is sent to Customer regarding the breach. If MavBots breaches this
					agreement then Customer may provide 15 days electronic or written notice of the breach and the opportunity to
					cure the breach during the 15 day period after the notice has been received.
				</Text>
			</Box>
			<Box className={dialogStyles.articleSection}>
				<Text as="h2" variant="h2" className={dialogStyles.articleHeader}>
					Effect of Termination
				</Text>
				<Text variant="body2">
					If this agreement is terminated for Customer’s breach, Customer must pay any unpaid fees for the term of all
					orders and the Service will terminate. Upon request, following any termination of this agreement, each party
					will destroy or return all of the other party’s property that it holds.
				</Text>
			</Box>
			<Box className={dialogStyles.articleSection}>
				<Text as="h1" variant="h1" className={dialogStyles.articleHeader}>
					LIABILITY LIMIT
				</Text>
				<Text variant="body2Bold">
					EXCLUSION OF INDIRECT DAMAGES. TO THE MAXIMUM EXTENT ALLOWED BY LAW, MavBots IS NOT LIABLE FOR ANY INDIRECT,
					SPECIAL, INCIDENTAL, OR CONSEQUENTIAL DAMAGES ARISING OUT OF OR RELATED TO THIS AGREEMENT (INCLUDING, WITHOUT
					LIMITATION, COSTS OF DELAY; LOSS OF OR UNAUTHORIZED ACCESS TO DATA OR INFORMATION; AND LOST PROFITS, REVENUE,
					OR ANTICIPATED COST SAVINGS), EVEN IF IT KNOWS OF THE POSSIBILITY OR FORESEEABILITY OF SUCH DAMAGE OR LOSS.
					TOTAL LIMIT ON LIABILITY. TO THE MAXIMUM EXTENT ALLOWED BY LAW, MavBots'S TOTAL LIABILITY, IRRESPECTIVE OF THE
					NEGLIGENCE OF EITHER PARTY, ARISING OUT OF OR RELATED TO THIS AGREEMENT (WHETHER IN CONTRACT, TORT, OR
					OTHERWISE) DOES NOT EXCEED THE AMOUNT PAID BY CUSTOMER WITHIN THE 6-MONTH PERIOD PRIOR TO THE EVENT THAT GAVE
					RISE TO THE LIABILITY. CONSUMER LAW NOTICE. SOME STATES MAY NOT ALLOW THE ABOVE EXCLUSION OF INCIDENTAL OR
					CONSEQUENTIAL DAMAGES, SO THE ABOVE EXCLUSION MAY NOT APPLY TO CUSTOMER. CUSTOMER MAY HAVE ADDITIONAL CONSUMER
					RIGHTS UNDER LOCAL LAW. CUSTOMER MAY ALSO HAVE OTHER RIGHTS WHICH VARY FROM JURISDICTION TO JURISDICTION.
				</Text>
			</Box>
			<Box className={dialogStyles.articleSection}>
				<Text as="h1" variant="h1" className={dialogStyles.articleHeader}>
					INDEMNIFICATION FOR THIRD-PARTY CLAIMS
				</Text>
				<Text variant="body2">
					If any third party brings a claim against MavBots related to Customer’s use or access to the Service, Customer
					must defend, indemnify, and hold MavBots harmless from and against all damages, losses, and expenses of any
					kind (including reasonable legal fees and costs) related to such claim.
				</Text>
			</Box>
			<Box className={dialogStyles.articleSection}>
				<Text as="h1" variant="h1" className={dialogStyles.articleHeader}>
					GOVERNING LAW AND FORUM
				</Text>
				<Text variant="body2">
					This agreement is governed by the laws of the State of Texas (without regard to conflicts of law principles)
					for any dispute between the parties or relating in any way to the subject matter of this agreement. Any suit
					or legal proceeding must be exclusively brought in the federal or state courts for Dallas County, Texas, and
					Customer submits to this personal jurisdiction and venue. Nothing in this agreement prevents either party from
					seeking injunctive relief in a court of competent jurisdiction. The prevailing party in any litigation is
					entitled to recover its attorneys' fees and costs from the other party.
				</Text>
			</Box>

			<Box className={dialogStyles.articleSection}>
				<Text as="h1" variant="h1" className={dialogStyles.articleHeader}>
					OTHER TERMS
				</Text>
				<Text as="h2" variant="h2" className={dialogStyles.articleHeader}>
					Entire Agreement and Changes
				</Text>
				<Text variant="body2">
					This agreement and the order constitute the entire agreement between the parties and supersede any prior or
					contemporaneous negotiations or agreements, whether oral or written, related to this subject matter. Customer
					is not relying on any representation concerning this subject matter, oral or written, not included in this
					agreement. No representation, promise, or inducement not included in this agreement is binding. No
					modification or waiver of any term of this agreement is effective unless both parties sign it, however this
					agreement may be modified through an online process provided by MavBots.
				</Text>
			</Box>
			<Box className={dialogStyles.articleSection}>
				<Text as="h2" variant="h2" className={dialogStyles.articleHeader}>
					No Assignment
				</Text>
				<Text variant="body2">
					Neither party may assign or transfer this agreement to a third party, nor delegate any duty, except that the
					agreement and all orders may be assigned, without the consent of the other party, as part of a merger or sale
					of all or substantially all a party's businesses, assets, not involving a competitor of the other party, or at
					any time to an affiliate.
				</Text>
			</Box>
			<Box className={dialogStyles.articleSection}>
				<Text as="h2" variant="h2" className={dialogStyles.articleHeader}>
					Export Compliance
				</Text>
				<Text variant="body2">
					The Service and Confidential Information may be subject to export laws and regulations of the United States
					and other jurisdictions. Each party represents that it is not named on any U.S. government denied-party list.
					Neither party will permit its personnel or representatives to access any Service in a U.S.-embargoed country
					or in violation of any applicable export law or regulation.
				</Text>
			</Box>
			<Box className={dialogStyles.articleSection}>
				<Text as="h2" variant="h2" className={dialogStyles.articleHeader}>
					Independent Contractors
				</Text>
				<Text variant="body2">
					The parties are independent contractors with respect to each other, and neither party is an agent, employee,
					or partner of the other party or the other party's affiliates.
				</Text>
			</Box>
			<Box className={dialogStyles.articleSection}>
				<Text as="h2" variant="h2" className={dialogStyles.articleHeader}>
					Enforceability and Force Majeure
				</Text>
				<Text variant="body2">
					If any term of this agreement is invalid or unenforceable, the other terms remain in effect. Neither party is
					liable for its non-performance due to events beyond its reasonable control, including but not limited to
					natural weather events and disasters, labor disruptions, and disruptions in the supply of utilities.
				</Text>
			</Box>
			<Box className={dialogStyles.articleSection}>
				<Text as="h2" variant="h2" className={dialogStyles.articleHeader}>
					Money Damages Insufficient
				</Text>
				<Text variant="body2">
					Any breach by a party of this agreement or violation of the other party's intellectual property rights could
					cause irreparable injury or harm to the other party. The other party may seek a court order to stop any breach
					or avoid any future breach of this agreement.
				</Text>
			</Box>
			<Box className={dialogStyles.articleSection}>
				<Text as="h2" variant="h2" className={dialogStyles.articleHeader}>
					No Additional Terms
				</Text>
				<Text variant="body2">
					MavBots rejects additional or conflicting terms of a Customer's form-purchasing document.
				</Text>
			</Box>
			<Box className={dialogStyles.articleSection}>
				<Text as="h2" variant="h2" className={dialogStyles.articleHeader}>
					Order of Precedence
				</Text>
				<Text variant="body2">
					If there is an inconsistency between this agreement and an order, the order prevails. If there is an
					inconsistency between this agreement and the Privacy Policy, this agreement prevails.
				</Text>
			</Box>
			<Box className={dialogStyles.articleSection}>
				<Text as="h2" variant="h2" className={dialogStyles.articleHeader}>
					Survival of Terms
				</Text>
				<Text variant="body2">
					All provisions of this agreement regarding payment, confidentiality, indemnification, limitations of
					liability, proprietary rights and such other provisions that by fair implication require performance beyond
					the term of this agreement must survive expiration or termination of this agreement until fully performed or
					otherwise are inapplicable. The UN Convention on Contracts for the International Sale of Goods does not apply.
				</Text>
			</Box>
			<Box className={dialogStyles.articleSection}>
				<Text as="h2" variant="h2" className={dialogStyles.articleHeader}>
					Feedback
				</Text>
				<Text variant="body2">
					If Customer provides feedback or suggestions about the Service, then MavBots (and those it allows to use its
					technology) may use such information without obligation to Customer.
				</Text>
			</Box>
		</>
	)
}
