import React, { useState } from 'react'
import { connect as connectStream, FeedAPIResponse } from 'getstream'
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import { useMainAppContext } from 'context/MainAppContext'
import { Box } from 'components/layout/box'
import { Text } from 'components/typography/text'
import * as styles from './Notifications.css'
import { CustomGenerics } from 'api/notifications/types'
import { NotificationItem } from './components/NotificationItem'
import { PanelType } from 'components/RightSidePanel/RightSidePanel'

dayjs.extend(relativeTime)

const FEED_API_KEY = process.env.REACT_APP_CHAT_API
const APP_ID = process.env.REACT_APP_FEED_APP_ID

interface Props {
	setExpandedPanel: (panel: PanelType | null) => void
}

export const Notifications = ({ setExpandedPanel }: Props) => {
	const { feedUserId, feedUserToken, notifications, setNotifications } = useMainAppContext()

	const [error, setError] = useState<string | null>(null)

	const handleMarkAsRead = (id: string) => {
		// Update the local state to mark the notification as read
		setNotifications(prev =>
			prev.map(notification => {
				if (notification.id === id) {
					return { ...notification, is_read: true }
				}
				return notification
			})
		)
	}

	const handleMarkAllAsRead = async () => {
		try {
			const client = connectStream<CustomGenerics>(FEED_API_KEY, null, APP_ID, { timeout: 8000 })
			const feed = client.feed('notification', feedUserId, feedUserToken)
			const response: FeedAPIResponse<CustomGenerics> = await feed.get({ limit: 50 })

			const unreadNotifications = response.results.filter(notification => !notification.is_read)
			const unreadIds = unreadNotifications.map(notification => notification.id)

			await feed.get({ mark_read: unreadIds })

			setNotifications(prev =>
				prev.map(notification => ({
					...notification,
					is_read: true // Mark all notifications as read
				}))
			)
		} catch (err: any) {
			setError(err.message)
		}
	}

	if (error) return <Text>{error}</Text>

	return (
		<Box className={styles.mainContainer}>
			<Text className={styles.header} variant="body1Bold">
				Notifications
			</Text>
			<Box style={{ cursor: 'pointer' }} onClick={handleMarkAllAsRead}>
				<Text variant="body2Bold" color="primary.500">
					Mark all as read
				</Text>
			</Box>
			<Box className={styles.notificationsList}>
				{notifications.length > 0 ? (
					notifications.flatMap(notification => {
						if (!notification.activities || notification.activities.length === 0) {
							console.warn('Notification with no activities:', notification)
							return [] // Skip notifications with no activities
						}

						return notification.activities.map(activity => (
							<NotificationItem
								key={activity.id}
								notificationId={notification.id}
								activity={activity}
								isRead={notification.is_read}
								setExpandedPanel={setExpandedPanel}
								onMarkAsRead={handleMarkAsRead}
							/>
						))
					})
				) : (
					<Text variant="body1">No notifications available</Text>
				)}
			</Box>
		</Box>
	)
}
