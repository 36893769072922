import clsx from 'clsx'
import { FC, ReactNode } from 'react'
import { getChildrenByType } from 'react-nanny'

import * as styles from './Tabs.css'
import { useTabsContext } from './TabsProvider'
import { Text } from 'components/typography/text'

interface Props {
	children: ReactNode
}

interface Tab {
	value: string
	children: ReactNode
}

interface Panel {
	value: string
	children: ReactNode
}

interface TabsComposition {
	Tab: FC<Tab>
	Panel: FC<Panel>
}

export const Tabs: FC<Props> & TabsComposition = ({ children }: Props) => {
	const tabsElements = getChildrenByType(children, [Tabs.Tab])
	const panelElements = getChildrenByType(children, [Tabs.Panel])

	return (
		<>
			{tabsElements.length > 0 && (
				<div className={styles.tabsWrapper} style={{ gridTemplateColumns: `repeat(${tabsElements.length}, 1fr)` }}>
					{tabsElements}
					<Slider />
				</div>
			)}
			{panelElements}
		</>
	)
}

Tabs.Tab = ({ value, children }: Tab) => {
	const { activeTab, onActiveTabChange, tabs } = useTabsContext()

	const tabIndices = generateTabIndices(tabs)
	const transform = tabIndices[value] * 100

	return (
		<button
			type="button"
			data-transform={transform}
			onClick={() => onActiveTabChange(value)}
			className={clsx(styles.tab, activeTab === value && styles.activeTab)}>
			<Text color="neutral.50" className={styles.text} variant={'metadata'}>
				{children}
			</Text>
		</button>
	)
}

Tabs.Panel = ({ value, children }: Panel) => {
	const { activeTab } = useTabsContext()
	return activeTab === value ? <>{children}</> : null
}

const Slider = () => {
	const { activeTab, tabs } = useTabsContext()
	const tabIndices = generateTabIndices(tabs)
	const transform = tabIndices[activeTab] * 100
	const sliderChildWidth = 100 / tabs.length

	return (
		<div className={styles.slider}>
			<div
				className={styles.sliderChild}
				style={{
					transform: `translateX(${transform}%)`,
					width: `${sliderChildWidth}%`
				}}>
				{/* <svg
					className={styles.svgContainer}
					xmlns="http://www.w3.org/2000/svg"
					width="100%"
					height="100%"
					viewBox="0 0 155 18"
					fill="none">
					<g style={{ mixBlendMode: 'color-dodge' }} filter="url(#filter0_b_8789_2228)">
						<path
							d="M30.2791 0H153.721C153.909 0 154.062 0.152993 154.062 0.341747C154.062 1.60092 111.368 8.73517 19.8749 9.86364C14.3029 9.86364 10.7214 10.6091 8.62772 11.8778C4.16479 14.5823 1.01065 21.0059 0.445465 15.8182C-0.530899 6.85641 7.27422 0 16.289 0H30.2791Z"
							fill="url(#paint0_radial_8789_2228)"
						/>
					</g>
					<defs>
						<filter
							id="filter0_b_8789_2228"
							x="-1.6373"
							y="-2"
							width="157.7"
							height="21.7832"
							filterUnits="userSpaceOnUse"
							colorInterpolationFilters="sRGB">
							<feFlood floodOpacity="0" result="BackgroundImageFix" />
							<feGaussianBlur in="BackgroundImageFix" stdDeviation="1" />
							<feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_8789_2228" />
							<feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_8789_2228" result="shape" />
						</filter>
						<radialGradient
							id="paint0_radial_8789_2228"
							cx="0"
							cy="0"
							r="1"
							gradientUnits="userSpaceOnUse"
							gradientTransform="translate(0.0625907 -4.96472e-06) scale(152.119 27.7647)">
							<stop offset="0.0001" stopColor="#D9D9D9" />
							<stop offset="0.0002" stopColor="#D9D9D9" />
							<stop offset="0.935" stopColor="#D9D9D9" stopOpacity="0" />
							<stop offset="1" stopColor="#010B08" stopOpacity="0" />
						</radialGradient>
					</defs>
				</svg> */}

				<svg
					className={styles.svgContainer}
					xmlns="http://www.w3.org/2000/svg"
					width="100%"
					height="auto"
					viewBox="0 0 97 18"
					fill="none"
					preserveAspectRatio="xMinYMin meet" // <--- This is important
				>
					<g style={{ mixBlendMode: 'color-dodge' }} filter="url(#filter0_b_920_22422)">
						<path
							// d="M19.0325 0H96.664C96.8496 0 97 0.150404 97 0.335954C97 1.57379 70.1079 8.55322 12.4792 9.66256C7.79731 9.66256 5.34663 10.971 4.37538 13.0663C2.48376 17.1473 0.554451 11 0.141219 11.5501C-0.369049 8.8077 0 0 4.8015 0H19.0325Z"
							d="M19.0325 0H96.664C96.8496 0 94 0.150404 97 0.335954C97 1.57379 60.1079 5.55322 11.4792 6.66256C7.79731 6.66256 5.34663 10.971 4.37538 13.0663C2.48376 17.1473 0.554451 11 0.141219 11.5501C-0.369049 8.8077 0 0 4.8015 0H19.0325Z"
							fill="url(#paint0_radial_920_22422)"
						/>
					</g>
					<defs>
						<filter
							id="filter0_b_920_22422"
							x="-1.79492"
							y="-2"
							width="100.795"
							height="21.8164"
							filterUnits="userSpaceOnUse"
							colorInterpolationFilters="sRGB">
							<feFlood floodOpacity="0" result="BackgroundImageFix" />
							<feGaussianBlur in="BackgroundImageFix" stdDeviation="1" />
							<feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_920_22422" />
							<feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_920_22422" result="shape" />
						</filter>
						<radialGradient
							id="paint0_radial_920_22422"
							cx="0"
							cy="0"
							r="1"
							gradientUnits="userSpaceOnUse"
							gradientTransform="scale(95.815 27.2941)">
							<stop offset="0.0001" stopColor="#D9D9D9" />
							<stop offset="0.0002" stopColor="#D9D9D9" />
							<stop offset="0.935" stopColor="#D9D9D9" stopOpacity="0" />
							<stop offset="1" stopColor="#010B08" stopOpacity="0" />
						</radialGradient>
					</defs>
				</svg>
			</div>
		</div>
	)
}

function generateTabIndices(tabValues: string[]): { [key: string]: number } {
	return tabValues.reduce((acc, value, index) => ({ ...acc, [value]: index }), {})
}

Tabs.Tab.displayName = 'Tabs.Tab'
Tabs.Panel.displayName = 'Tabs.Panel'
