import React, { useEffect } from 'react'

import { Box } from 'components/layout/box'
import * as styles from './MavbotsArena.css'
import { MainTitle } from 'components/typography/main-title'
import { TabsProvider, useTabsContext } from 'components/Tabs/TabsProvider'
import { Tabs } from 'components/Tabs'

import { MainInfoMavbotsArenaPageDialog } from 'components/Dialog/MainInfoMavbotsArenaPageDialog'
import { Arena } from './Arena'
import { Roster } from './Roster'
import { fetchBotStats, fetchBotsVideos, fetchBotTrades, fetchQuote } from 'api/mavbots-arena'
import { useOktaAuth } from '@okta/okta-react'
import { useQuery } from '@tanstack/react-query'
import { useArenaContext } from 'context/ArenaContext'
import { fetchBotsImages } from 'api/mavbots-arena/fetchBotsImages'
import { useMainAppContext } from 'context/MainAppContext'
import { useLocation, useNavigate } from 'react-router-dom'

export const MavbotsArena = () => {
	const { oktaAuth } = useOktaAuth()
	const accessToken = oktaAuth.getAccessToken()
	const { userGroup } = useMainAppContext()
	const { activeTab, onActiveTabChange } = useTabsContext()
	const { setArenaBotsData, setArenaSelectedBot, setArenaQuotes, setArenaBotsTrades } = useArenaContext()
	const location = useLocation()
	const navigate = useNavigate()

	const { data: arenaBotsData, isLoading } = useQuery({
		queryKey: ['mavbots-arena', 'mavbots'],
		queryFn: () => fetchBotStats(accessToken),

		staleTime: 24 * 60 * 60 * 1000 // 24 hours
	})

	const { data: arenaBotsTradesData } = useQuery({
		queryKey: ['arena-trades'],
		queryFn: () => fetchBotTrades(accessToken)
	})

	// const { data: botImages } = useQuery({
	// 	queryKey: ['bot-images', arenaBotsData?.map(bot => bot.avatar)],
	// 	queryFn: () =>
	// 		fetchBotsImages(
	// 			accessToken,
	// 			arenaBotsData?.map(bot => bot.avatar)
	// 		),
	// 	enabled: !!arenaBotsData
	// })

	// const { data: botVideos } = useQuery({
	// 	queryKey: ['bot-videos', arenaBotsData?.map(bot => bot.videoAvatar)],
	// 	queryFn: () =>
	// 		fetchBotsVideos(
	// 			accessToken,
	// 			arenaBotsData?.map(bot => bot.videoAvatar)
	// 		),
	// 	enabled: !!arenaBotsData
	// })

	const { data: quoteTextData } = useQuery({
		queryKey: ['arena-quote'],
		queryFn: () => fetchQuote(accessToken),
		staleTime: 24 * 60 * 60 * 1000 // 24 hours
	})

	// useEffect(() => {
	// 	if (arenaBotsData && arenaBotsData.length > 0) {
	// 		setArenaSelectedBot(arenaBotsData[0])
	// 		setArenaBotsData(arenaBotsData)
	// 	}
	// }, [arenaBotsData])

	// useEffect(() => {
	// 	if (!arenaBotsData || !arenaBotsTradesData) return
	// 	if (arenaBotsData.length === 0 || arenaBotsTradesData.length === 0) return

	// 	// Sort trades by the newest trade datetime. We assume the newest trade is at index [0].
	// 	const sortedTrades = [...arenaBotsTradesData].sort((a, b) => {
	// 		const aLatest = a.trade_history[0] ? new Date(a.trade_history[0].datetime).getTime() : 0
	// 		const bLatest = b.trade_history[0] ? new Date(b.trade_history[0].datetime).getTime() : 0
	// 		return bLatest - aLatest // descending => newest first
	// 	})

	// 	// Now reorder arenaBotsData using the sorted trades.
	// 	const newOrderedBots = sortedTrades
	// 		.map(tradeItem => {
	// 			return arenaBotsData.find(bot => bot.bot_id === tradeItem.bot_id)
	// 		})
	// 		// filter out any that couldn't be found
	// 		.filter(Boolean)

	// 	// 2) SET the newly-ordered array in context
	// 	setArenaBotsData(newOrderedBots)

	// 	// 3) Optionally select the first in the new order
	// 	if (newOrderedBots.length > 0) {
	// 		setArenaSelectedBot(newOrderedBots[0])
	// 	}
	// }, [arenaBotsData, arenaBotsTradesData, setArenaBotsData, setArenaSelectedBot])

	useEffect(() => {
		// If we don't have bot stats yet, bail out
		if (!arenaBotsData) return

		let newOrderedBots = arenaBotsData

		// Only do the sorting if we have trades data and it's not empty
		if (arenaBotsTradesData && arenaBotsTradesData.length > 0) {
			// 1) Sort trades by the newest trade date
			const sortedTrades = [...arenaBotsTradesData].sort((a, b) => {
				const aLatest = a.trade_history[0] ? new Date(a.trade_history[0].datetime).getTime() : 0
				const bLatest = b.trade_history[0] ? new Date(b.trade_history[0].datetime).getTime() : 0
				return bLatest - aLatest // descending => newest first
			})

			// 2) Reorder arenaBotsData using the sorted trades
			newOrderedBots = sortedTrades
				.map(tradeItem => arenaBotsData.find(bot => bot.bot_id === tradeItem.bot_id))
				.filter(Boolean)
		}

		// 3) Set the newly-ordered array in context
		setArenaBotsData(newOrderedBots)

		// 4) Optionally select the first in the new order
		if (newOrderedBots.length > 0) {
			setArenaSelectedBot(newOrderedBots[0])
		}
	}, [arenaBotsData, arenaBotsTradesData, setArenaBotsData, setArenaSelectedBot])

	useEffect(() => {
		if (arenaBotsTradesData && arenaBotsTradesData.length > 0) {
			setArenaBotsTrades(arenaBotsTradesData)
		}
	}, [arenaBotsTradesData])

	useEffect(() => {
		if (quoteTextData && Array.isArray(quoteTextData)) {
			// Store the array directly in arenaQuotes
			setArenaQuotes(quoteTextData)
		}
	}, [quoteTextData])

	// useEffect(() => {
	// 	if (botImages) {
	// 		setAvatarImages(botImages)
	// 	}
	// }, [botImages, setAvatarImages])

	// useEffect(() => {
	// 	if (botVideos) {
	// 		setAvatarVideos(botVideos)
	// 	}
	// }, [botVideos, setAvatarVideos])

	useEffect(() => {
		if (location.hash) {
			onActiveTabChange('Arena')
		}
	}, [location.hash, onActiveTabChange])

	// When the active tab is 'Arena' and there's a hash, scroll to the element
	useEffect(() => {
		if (activeTab === 'Arena' && location.hash) {
			const element = document.querySelector(location.hash)
			if (element) {
				element.scrollIntoView({ behavior: 'smooth' })

				// Use IntersectionObserver to detect when the element is in view
				const observer = new IntersectionObserver(
					(entries, observer) => {
						if (entries[0].isIntersecting) {
							// Element is in view, remove the hash from the URL
							navigate(location.pathname, { replace: true })
							// Disconnect the observer once done
							observer.disconnect()
						}
					},
					{
						root: null,
						threshold: 1.0
					}
				)

				observer.observe(element)
			}
		}
	}, [activeTab, location.hash])

	return (
		<Box className={styles.mavbotsArenaContainer}>
			<Box className={styles.header}>
				<MainTitle Dialog={children => <MainInfoMavbotsArenaPageDialog>{children}</MainInfoMavbotsArenaPageDialog>}>
					Mavbots Arena
				</MainTitle>
			</Box>
			<Tabs>
				<Tabs.Tab value="Arena">Arena</Tabs.Tab>
				<Tabs.Tab value="Roster">Roster</Tabs.Tab>

				<Tabs.Panel value="Arena">
					<Arena isLatestTradesLoading={isLoading} />
				</Tabs.Panel>
				<Tabs.Panel value="Roster">
					<Roster />
				</Tabs.Panel>
			</Tabs>
		</Box>
	)
}
