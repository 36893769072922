import React from 'react'

interface IconProps {
	[x: string]: any
}
interface ArrowIconProps {
	isActive?: boolean
	[x: string]: any
}
interface SortIconProps {
	sort?: 'ASC' | 'DESC'
	[x: string]: any
}

export const ArrowIcon = ({ isActive, ...props }: ArrowIconProps) => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			style={{ transform: isActive ? 'none' : 'rotate(180deg)' }}
			stroke="currentColor"
			width="24"
			height="24"
			viewBox="0 0 24 24"
			{...props}>
			<path d="M12 8L18 14H6L12 8Z" fill="currentColor" />
		</svg>
	)
}
export const SearchIcon = (props: IconProps) => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none" {...props}>
			<path
				d="M15.0258 13.8467L18.5947 17.4156L17.4162 18.5941L13.8473 15.0252C12.564 16.0518 10.9366 16.666 9.16663 16.666C5.02663 16.666 1.66663 13.306 1.66663 9.16602C1.66663 5.02602 5.02663 1.66602 9.16663 1.66602C13.3066 1.66602 16.6666 5.02602 16.6666 9.16602C16.6666 10.936 16.0525 12.5634 15.0258 13.8467ZM13.3539 13.2283C14.3729 12.1782 15 10.7457 15 9.16602C15 5.9431 12.3895 3.33268 9.16663 3.33268C5.94371 3.33268 3.33329 5.9431 3.33329 9.16602C3.33329 12.3889 5.94371 14.9993 9.16663 14.9993C10.7463 14.9993 12.1788 14.3723 13.229 13.3533L13.3539 13.2283Z"
				fill="currentColor"
			/>
		</svg>
	)
}

export const MavBotsIcon = (props: IconProps) => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
			<path
				d="M13.5 1.99805C13.5 2.4423 13.3069 2.84144 13 3.1161V4.99805H18C19.6569 4.99805 21 6.3412 21 7.99805V17.998C21 19.6549 19.6569 20.998 18 20.998H6C4.34315 20.998 3 19.6549 3 17.998V7.99805C3 6.3412 4.34315 4.99805 6 4.99805H11V3.1161C10.6931 2.84144 10.5 2.4423 10.5 1.99805C10.5 1.16962 11.1716 0.498047 12 0.498047C12.8284 0.498047 13.5 1.16962 13.5 1.99805ZM6 6.99805C5.44772 6.99805 5 7.44577 5 7.99805V17.998C5 18.5503 5.44772 18.998 6 18.998H18C18.5523 18.998 19 18.5503 19 17.998V7.99805C19 7.44577 18.5523 6.99805 18 6.99805H13H11H6ZM2 9.99805H0V15.998H2V9.99805ZM22 9.99805H24V15.998H22V9.99805ZM9 14.498C9.82843 14.498 10.5 13.8264 10.5 12.998C10.5 12.1696 9.82843 11.498 9 11.498C8.17157 11.498 7.5 12.1696 7.5 12.998C7.5 13.8264 8.17157 14.498 9 14.498ZM15 14.498C15.8284 14.498 16.5 13.8264 16.5 12.998C16.5 12.1696 15.8284 11.498 15 11.498C14.1716 11.498 13.5 12.1696 13.5 12.998C13.5 13.8264 14.1716 14.498 15 14.498Z"
				fill="currentColor"
			/>
		</svg>
	)
}

export const MarketIcon = (props: IconProps) => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
			<path
				d="M5 3V19H21V21H3V3H5ZM20.2929 6.29289L21.7071 7.70711L16 13.4142L13 10.415L8.70711 14.7071L7.29289 13.2929L13 7.58579L16 10.585L20.2929 6.29289Z"
				fill="currentColor"
			/>
		</svg>
	)
}

export const NavigatorIcon = (props: IconProps) => {
	return (
		<svg
			width="64"
			height="64"
			viewBox="0 0 91 91"
			xmlSpace="preserve"
			xmlns="http://www.w3.org/2000/svg"
			fill="currentColor"
			{...props}>
			<path d="m73.7 44.1-13.6-4 8-14.2c.4-.7.3-1.5-.3-2-.5-.5-1.4-.7-2-.3l-14.2 8-4-13.6c-.2-.5-.6-.9-1.2-.9s-1 .4-1.2.9l-4 13.6-14.2-8c-.7-.4-1.5-.3-2 .3-.5.5-.6 1.4-.3 2l8 14.2-13.6 4c-.5.2-.9.6-.9 1.2s.4 1 .9 1.2l13.6 4-8 14.2c-.4.7-.3 1.5.3 2 .3.3.8.5 1.2.5.3 0 .6-.1.8-.2l14.2-8 4 13.6c.2.5.6.9 1.2.9s1-.4 1.2-.9l4-13.6 14.2 8c.3.1.5.2.8.2.4 0 .9-.2 1.2-.5.5-.5.6-1.4.3-2l-8-14.2 13.6-4c.5-.2.9-.6.9-1.2s-.4-1.1-.9-1.2zm-22.4 1.2c0 2.7-2.2 4.8-4.8 4.8-2.7 0-4.8-2.2-4.8-4.8s2.2-4.8 4.8-4.8c2.6-.1 4.8 2.1 4.8 4.8zm10.9-15.8-5.4 9.6-3.2-.9-1-3.2 9.6-5.5zm-31.5 0 9.6 5.4-.9 3.2-3.2.9-5.5-9.5zm0 31.5 5.4-9.6 3.2.9.9 3.2-9.5 5.5zm31.5 0-9.6-5.4.9-3.2 3.2-.9 5.5 9.5z" />
		</svg>
	)
}

export const PulseIcon = (props: IconProps) => {
	return (
		<svg fill="none" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg" {...props}>
			<path
				d="M3 12H7L10 19L14 5L17 12H21"
				stroke="currentColor"
				stroke-linecap="round"
				stroke-linejoin="round"
				stroke-width="2"
			/>
		</svg>
	)
}

export const NexusIcon = (props: IconProps) => {
	return (
		<svg
			enable-background="new 0 0 32 32"
			height="32px"
			id="Layer_1"
			version="1.1"
			viewBox="0 0 32 32"
			width="32px"
			xmlSpace="preserve"
			xmlns="http://www.w3.org/2000/svg"
			xmlnsXlink="http://www.w3.org/1999/xlink"
			{...props}>
			<g id="bubble">
				<path
					clip-rule="evenodd"
					d="M16,7c-5.963,0-11,3.206-11,7c0,0.276,0.224,0.5,0.5,0.5   S6,14.276,6,14c0-3.196,4.673-6,10-6c0.275,0,0.5-0.224,0.5-0.5S16.276,7,16,7z"
					fill="currentColor"
					fill-rule="evenodd"
				/>
				<path
					clip-rule="evenodd"
					d="M16,2C7.163,2,0,7.373,0,14c0,4.127,2.779,7.766,7.008,9.926   C7.008,23.953,7,23.971,7,24c0,1.793-1.339,3.723-1.928,4.736c0.001,0,0.002,0,0.002,0C5.027,28.846,5,28.967,5,29.094   C5,29.594,5.405,30,5.906,30C6,30,6.165,29.975,6.161,29.986c3.125-0.512,6.069-3.383,6.753-4.215C13.913,25.918,14.943,26,16,26   c8.835,0,16-5.373,16-12C32,7.373,24.836,2,16,2z M16,24c-0.917,0-1.858-0.07-2.796-0.207c-0.097-0.016-0.194-0.021-0.29-0.021   c-0.594,0-1.163,0.264-1.546,0.73c-0.428,0.521-1.646,1.684-3.085,2.539c0.39-0.895,0.695-1.898,0.716-2.932   c0.006-0.064,0.009-0.129,0.009-0.184c0-0.752-0.421-1.439-1.09-1.781C4.212,20.252,2,17.207,2,14C2,8.486,8.28,4,16,4   c7.718,0,14,4.486,14,10C30,19.514,23.719,24,16,24z"
					fill="currentColor"
					fill-rule="evenodd"
				/>
			</g>
		</svg>
	)
}

export const SifterIcon = (props: IconProps) => {
	return (
		<svg
			enable-background="new 0 0 32 32"
			id="Editable-line"
			version="1.1"
			viewBox="0 0 32 32"
			xmlSpace="preserve"
			xmlns="http://www.w3.org/2000/svg"
			xmlnsXlink="http://www.w3.org/1999/xlink"
			{...props}>
			<path
				d="  M3.241,7.646L13,19v9l6-4v-5l9.759-11.354C29.315,6.996,28.848,6,27.986,6H4.014C3.152,6,2.685,6.996,3.241,7.646z"
				fill="none"
				id="XMLID_6_"
				stroke="currentColor"
				stroke-linecap="round"
				stroke-linejoin="round"
				stroke-miterlimit="10"
				stroke-width="2"
			/>
		</svg>
	)
}

export const TrendingIcon = (props: IconProps) => {
	return (
		<svg height="512" viewBox="0 0 512 512" width="512" xmlns="http://www.w3.org/2000/svg" {...props}>
			<polyline
				points="352 144 464 144 464 256"
				stroke="currentColor"
				strokeLinecap="square"
				strokeMiterlimit="10"
				strokeWidth="32px"
			/>
			<polyline
				points="48 368 192 224 288 320 448 160"
				stroke="currentColor"
				strokeLinecap="square"
				strokeMiterlimit="10"
				strokeWidth="32px"
			/>
		</svg>
	)
}

export const DecoderIcon = (props: IconProps) => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
			<path
				d="M2 13H8V21H2V13ZM16 8H22V21H16V8ZM9 3H15V21H9V3ZM4 15V19H6V15H4ZM11 5V19H13V5H11ZM18 10V19H20V10H18Z"
				fill="currentColor"
			/>
		</svg>
	)
}

export const HerdIcon = (props: IconProps) => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
			<path
				d="M12 10.998C14.7614 10.998 17 13.2366 17 15.998V21.998H15V15.998C15 14.4003 13.7511 13.0943 12.1763 13.0031L12 12.998C10.4023 12.998 9.09634 14.2469 9.00509 15.8217L9 15.998V21.998H7V15.998C7 13.2366 9.23858 10.998 12 10.998ZM5.5 13.998C5.77885 13.998 6.05009 14.0306 6.3101 14.0922C6.14202 14.592 6.03873 15.12 6.00896 15.6673L6 15.998L6.0007 16.0836C5.88757 16.0436 5.76821 16.0167 5.64446 16.0049L5.5 15.998C4.7203 15.998 4.07955 16.5929 4.00687 17.3535L4 17.498V21.998H2V17.498C2 15.565 3.567 13.998 5.5 13.998ZM18.5 13.998C20.433 13.998 22 15.565 22 17.498V21.998H20V17.498C20 16.7183 19.4051 16.0776 18.6445 16.0049L18.5 15.998C18.3248 15.998 18.1566 16.028 18.0003 16.0832L18 15.998C18 15.3323 17.8916 14.692 17.6915 14.0936C17.9499 14.0306 18.2211 13.998 18.5 13.998ZM5.5 7.99805C6.88071 7.99805 8 9.11734 8 10.498C8 11.8787 6.88071 12.998 5.5 12.998C4.11929 12.998 3 11.8787 3 10.498C3 9.11734 4.11929 7.99805 5.5 7.99805ZM18.5 7.99805C19.8807 7.99805 21 9.11734 21 10.498C21 11.8787 19.8807 12.998 18.5 12.998C17.1193 12.998 16 11.8787 16 10.498C16 9.11734 17.1193 7.99805 18.5 7.99805ZM5.5 9.99805C5.22386 9.99805 5 10.2219 5 10.498C5 10.7741 5.22386 10.998 5.5 10.998C5.77614 10.998 6 10.7741 6 10.498C6 10.2219 5.77614 9.99805 5.5 9.99805ZM18.5 9.99805C18.2239 9.99805 18 10.2219 18 10.498C18 10.7741 18.2239 10.998 18.5 10.998C18.7761 10.998 19 10.7741 19 10.498C19 10.2219 18.7761 9.99805 18.5 9.99805ZM12 1.99805C14.2091 1.99805 16 3.78891 16 5.99805C16 8.20719 14.2091 9.99805 12 9.99805C9.79086 9.99805 8 8.20719 8 5.99805C8 3.78891 9.79086 1.99805 12 1.99805ZM12 3.99805C10.8954 3.99805 10 4.89348 10 5.99805C10 7.10262 10.8954 7.99805 12 7.99805C13.1046 7.99805 14 7.10262 14 5.99805C14 4.89348 13.1046 3.99805 12 3.99805Z"
				fill="currentColor"
			/>
		</svg>
	)
}

export const NewsIcon = (props: IconProps) => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
			<path
				d="M2 4C2 3.44772 2.44772 3 3 3H21C21.5523 3 22 3.44772 22 4V20C22 20.5523 21.5523 21 21 21H3C2.44772 21 2 20.5523 2 20V4ZM4 5V19H20V5H4ZM6 7H12V13H6V7ZM8 9V11H10V9H8ZM14 9H18V7H14V9ZM18 13H14V11H18V13ZM6 15V17H18V15H6Z"
				fill="currentColor"
			/>
		</svg>
	)
}
export const HerdPowerIcon = (props: IconProps) => {
	return (
		<svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1023.99 1023.99" {...props}>
			<rect className="cls-1" width="1023.99" height="1023.99" fill="transparent" />
			<circle fill="currentColor" className="cls-2" cx="713.38" cy="309.75" r="51.92" />
			<circle fill="currentColor" className="cls-2" cx="569.94" cy="309.75" r="41.83" />
			<circle fill="currentColor" className="cls-2" cx="713.38" cy="453.2" r="41.83" />
			<circle fill="currentColor" className="cls-2" cx="426.49" cy="309.75" r="32.16" />
			<circle fill="currentColor" className="cls-2" cx="569.94" cy="453.2" r="32.16" />
			<circle fill="currentColor" className="cls-2" cx="713.38" cy="596.65" r="32.16" />
			<circle fill="currentColor" className="cls-2" cx="286.44" cy="309.75" r="27.76" />
			<circle fill="currentColor" className="cls-2" cx="426.84" cy="450.15" r="27.76" />
			<circle fill="currentColor" className="cls-2" cx="570.11" cy="593.42" r="27.76" />
			<circle fill="currentColor" className="cls-2" cx="715.08" cy="738.4" r="27.76" />
		</svg>
	)
}

export const DoubleArrowIcon = (props: IconProps) => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none" {...props}>
			<path d="M9 4.5L6 1.5L3 4.5H9ZM9 7.5L6 10.5L3 7.5H9Z" fill="currentColor" />
		</svg>
	)
}

export const BarSortIcon = (props: IconProps) => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17" fill="none" {...props}>
			<path
				d="M13.3333 3.16602V11.166H15.3333L12.6667 14.4993L10 11.166H12V3.16602H13.3333ZM8 12.4993V13.8327H2V12.4993H8ZM9.33333 7.83268V9.16602H2V7.83268H9.33333ZM9.33333 3.16602V4.49935H2V3.16602H9.33333Z"
				fill="currentColor"
			/>
		</svg>
	)
}

export const AlphabeticSortIcon = (props: IconProps) => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17" fill="none" {...props}>
			<path
				d="M3.24607 7.83333H1.77783L4.00017 2.5H5.3335L7.55576 7.83333H6.08759L5.81437 7.16667H3.51929L3.24607 7.83333ZM4.06574 5.83333H5.26792L4.66683 4.36667L4.06574 5.83333ZM12.6668 11.1667V2.5H11.3334V11.1667H9.33343L12.0001 14.5L14.6668 11.1667H12.6668ZM7.33343 9.16667H2.00011V10.5H5.23626L2.00011 13.1667V14.5H7.33343V13.1667H4.09753L7.33343 10.5V9.16667Z"
				fill="currentColor"
			/>
		</svg>
	)
}

export const ResetIcon = (props: IconProps) => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17" fill="none" {...props}>
			<path
				d="M3.64188 3.45573C4.81054 2.44524 6.33396 1.83398 8.00016 1.83398C11.682 1.83398 14.6668 4.81875 14.6668 8.50065C14.6668 9.92472 14.2203 11.2445 13.4596 12.3277L11.3335 8.50065H13.3335C13.3335 5.55513 10.9457 3.16732 8.00016 3.16732C6.56671 3.16732 5.26534 3.73283 4.30698 4.65293L3.64188 3.45573ZM12.3584 13.5456C11.1898 14.5561 9.66636 15.1673 8.00016 15.1673C4.31826 15.1673 1.3335 12.1825 1.3335 8.50065C1.3335 7.07656 1.78002 5.75676 2.5407 4.67361L4.66683 8.50065H2.66683C2.66683 11.4462 5.05464 13.834 8.00016 13.834C9.43363 13.834 10.735 13.2685 11.6934 12.3484L12.3584 13.5456Z"
				fill="currentColor"
			/>
		</svg>
	)
}

export const InfoIcon = (props: IconProps) => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none" {...props}>
			<path
				d="M8.00004 14.6667C4.31814 14.6667 1.33337 11.6819 1.33337 8.00001C1.33337 4.31811 4.31814 1.33334 8.00004 1.33334C11.6819 1.33334 14.6667 4.31811 14.6667 8.00001C14.6667 11.6819 11.6819 14.6667 8.00004 14.6667ZM8.00004 13.3333C10.9456 13.3333 13.3334 10.9455 13.3334 8.00001C13.3334 5.05449 10.9456 2.66668 8.00004 2.66668C5.05452 2.66668 2.66671 5.05449 2.66671 8.00001C2.66671 10.9455 5.05452 13.3333 8.00004 13.3333ZM7.33337 4.66668H8.66671V6.00001H7.33337V4.66668ZM7.33337 7.33334H8.66671V11.3333H7.33337V7.33334Z"
				fill="currentColor"
			/>
		</svg>
	)
}

export const ExternalLink = (props: IconProps) => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none" {...props}>
			<path
				d="M5.83333 3.5V4.66667H2.91667V11.0833H9.33333V8.16667H10.5V11.6667C10.5 11.9888 10.2388 12.25 9.91667 12.25H2.33333C2.01117 12.25 1.75 11.9888 1.75 11.6667V4.08333C1.75 3.76117 2.01117 3.5 2.33333 3.5H5.83333ZM12.25 1.75V6.41667H11.0833L11.0833 3.74092L6.53747 8.28747L5.71252 7.46252L10.2579 2.91667H7.58333V1.75H12.25Z"
				fill="currentColor"
			/>
		</svg>
	)
}

export const SmallRightArrow = (props: IconProps) => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="14" height="15" viewBox="0 0 14 15" fill="none" {...props}>
			<path
				d="M7.68327 7.50138L4.7959 4.61402L5.62086 3.78906L9.33317 7.50138L5.62086 11.2137L4.7959 10.3887L7.68327 7.50138Z"
				fill="currentColor"
			/>
		</svg>
	)
}
export const SmallLeftArrow = (props: IconProps) => {
	return (
		<svg
			style={{ transform: 'rotate(180deg)' }}
			xmlns="http://www.w3.org/2000/svg"
			width="14"
			height="15"
			viewBox="0 0 14 15"
			fill="none"
			{...props}>
			<path
				d="M7.68327 7.50138L4.7959 4.61402L5.62086 3.78906L9.33317 7.50138L5.62086 11.2137L4.7959 10.3887L7.68327 7.50138Z"
				fill="currentColor"
			/>
		</svg>
	)
}

export const SmallBottomArrow = (props: IconProps) => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none" {...props}>
			<path
				d="M9.99955 10.9763L14.1244 6.85156L15.3029 8.03007L9.99955 13.3334L4.69629 8.03007L5.8748 6.85156L9.99955 10.9763Z"
				fill="currentColor"
			/>
		</svg>
	)
}

export const Density1Icon = (props: IconProps) => {
	return (
		<svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
			<rect x="0.799805" y="1.30078" width="14.4" height="1.6" fill="currentColor" />
			<rect x="0.799805" y="7.70117" width="14.4" height="1.6" fill="currentColor" />
			<rect x="0.799805" y="14.1016" width="14.4" height="1.6" fill="currentColor" />
		</svg>
	)
}
export const Density2Icon = (props: IconProps) => {
	return (
		<svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
			<rect x="-1" y="2.5" width="18" height="2" fill="currentColor" />
			<rect x="-1" y="7.5" width="18" height="2" fill="currentColor" />
			<rect x="-1" y="12.5" width="18" height="2" fill="currentColor" />
		</svg>
	)
}

// Sifter / Filters
export const KeyboardArrowIcon = ({ isActive, props }: ArrowIconProps) => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="24"
			height="25"
			viewBox="0 0 24 25"
			fill="none"
			style={{ transform: isActive ? 'none' : 'rotate(180deg)' }}
			{...props}>
			<path
				d="M11.9997 11.3276L16.9495 16.2773L18.3637 14.8631L11.9997 8.49912L5.63574 14.8631L7.04996 16.2773L11.9997 11.3276Z"
				fill="currentColor"
			/>
		</svg>
	)
}

// Sifter / Filters
export const ExpandIcon = (props: IconProps) => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17" fill="none" {...props}>
			<path
				d="M12 1.83398H4V3.16732H12V1.83398ZM11.2998 6.80048L8 3.50065L4.70182 6.79883L7.33333 6.79949V10.2007L4.70052 10.2007L8.00033 13.5007L11.3001 10.2009L8.66667 10.2008V6.79982L11.2998 6.80048ZM12 13.834V15.1673H4V13.834H12Z"
				fill="currentColor"
			/>
		</svg>
	)
}

// Modal
export const CloseIcon = (props: IconProps) => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
			<path
				d="M11.9997 10.5865L16.9495 5.63672L18.3637 7.05093L13.4139 12.0007L18.3637 16.9504L16.9495 18.3646L11.9997 13.4149L7.04996 18.3646L5.63574 16.9504L10.5855 12.0007L5.63574 7.05093L7.04996 5.63672L11.9997 10.5865Z"
				fill="currentColor"
			/>
		</svg>
	)
}
export const CircleCloseIcon = (props: IconProps) => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="16"
			height="17"
			viewBox="0 0 16 17"
			fill="none"
			{...props}
			style={{ flexShrink: '0' }}>
			<path
				d="M7.99998 15.1673C4.31808 15.1673 1.33331 12.1825 1.33331 8.50065C1.33331 4.81875 4.31808 1.83398 7.99998 1.83398C11.6818 1.83398 14.6666 4.81875 14.6666 8.50065C14.6666 12.1825 11.6818 15.1673 7.99998 15.1673ZM7.99998 13.834C10.9455 13.834 13.3333 11.4462 13.3333 8.50065C13.3333 5.55513 10.9455 3.16732 7.99998 3.16732C5.05446 3.16732 2.66665 5.55513 2.66665 8.50065C2.66665 11.4462 5.05446 13.834 7.99998 13.834ZM7.99998 7.55785L9.88558 5.67222L10.8284 6.61503L8.94278 8.50065L10.8284 10.3863L9.88558 11.3291L7.99998 9.44345L6.11436 11.3291L5.17155 10.3863L7.05718 8.50065L5.17155 6.61503L6.11436 5.67222L7.99998 7.55785Z"
				fill="currentColor"
			/>
		</svg>
	)
}

export const CircleInfoIcon = (props: IconProps) => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="20"
			height="20"
			viewBox="0 0 20 20"
			fill="none"
			style={{ flexShrink: '0' }}
			{...props}>
			<path
				d="M9.99935 18.3337C5.39697 18.3337 1.66602 14.6027 1.66602 10.0003C1.66602 5.39795 5.39697 1.66699 9.99935 1.66699C14.6017 1.66699 18.3327 5.39795 18.3327 10.0003C18.3327 14.6027 14.6017 18.3337 9.99935 18.3337ZM9.99935 16.667C13.6813 16.667 16.666 13.6822 16.666 10.0003C16.666 6.31843 13.6813 3.33366 9.99935 3.33366C6.31745 3.33366 3.33268 6.31843 3.33268 10.0003C3.33268 13.6822 6.31745 16.667 9.99935 16.667ZM9.16602 5.83366H10.8327V7.50033H9.16602V5.83366ZM9.16602 9.16699H10.8327V14.167H9.16602V9.16699Z"
				fill="#989FAE"
			/>
		</svg>
	)
}
export const FilterIcon = (props: IconProps) => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none" {...props}>
			<path
				d="M8.33333 15H11.6667V13.3333H8.33333V15ZM2.5 5V6.66667H17.5V5H2.5ZM5 10.8333H15V9.16667H5V10.8333Z"
				fill="currentColor"
			/>
		</svg>
	)
}

export const SortIcon = (props: SortIconProps) => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="13" height="12" viewBox="0 0 13 12" fill="none" {...props}>
			<path
				d="M6.3499 3.97487L5.6428 4.68198L4.3754 3.414L4.37502 10H3.37502L3.3754 3.414L2.10726 4.68198L1.40015 3.97487L3.87502 1.5L6.3499 3.97487ZM11.3499 8.02515L8.875 10.5L6.40015 8.02515L7.10725 7.318L8.3754 8.586L8.375 2H9.375L9.3754 8.586L10.6428 7.318L11.3499 8.02515Z"
				fill="currentColor"
			/>
		</svg>
	)
}
export const EyeIcon = (props: SortIconProps) => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none" {...props}>
			<path
				d="M12.5003 3C17.8924 3 22.3784 6.87976 23.3189 12C22.3784 17.1202 17.8924 21 12.5003 21C7.10812 21 2.62215 17.1202 1.68164 12C2.62215 6.87976 7.10812 3 12.5003 3ZM12.5003 19C16.7359 19 20.3603 16.052 21.2777 12C20.3603 7.94803 16.7359 5 12.5003 5C8.2646 5 4.64022 7.94803 3.72278 12C4.64022 16.052 8.2646 19 12.5003 19ZM12.5003 16.5C10.015 16.5 8.00026 14.4853 8.00026 12C8.00026 9.51472 10.015 7.5 12.5003 7.5C14.9855 7.5 17.0003 9.51472 17.0003 12C17.0003 14.4853 14.9855 16.5 12.5003 16.5ZM12.5003 14.5C13.881 14.5 15.0003 13.3807 15.0003 12C15.0003 10.6193 13.881 9.5 12.5003 9.5C11.1196 9.5 10.0003 10.6193 10.0003 12C10.0003 13.3807 11.1196 14.5 12.5003 14.5Z"
				fill="currentColor"
			/>
		</svg>
	)
}

export const InfoButtonIcon = (props: SortIconProps) => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="42" height="42" viewBox="0 0 42 42" fill="none" {...props}>
			<path
				d="M30.65 5.36046L30.6515 5.36156C34.0446 7.90846 37.3087 10.8533 39.3847 14.89L39.3859 14.8923C41.4169 18.8929 42.3404 24.0206 40.5347 27.8527C39.6418 29.7751 38.0897 31.321 36.3117 32.6355C34.5341 33.9496 32.4922 35.0595 30.5905 36.0886C30.5901 36.0888 30.5897 36.089 30.5893 36.0892L30.3513 35.6495C26.537 37.7411 23.1638 39.585 19.1938 40.4657L30.65 5.36046ZM30.65 5.36046C27.2522 2.8295 23.5555 0.558066 19.7722 0.50033V0.500316L19.7684 0.500286C15.9588 0.471425 12.2099 2.62894 8.77687 5.16672L8.77541 5.16781C5.36865 7.70542 2.07887 10.7625 0.965293 14.5938C0.406917 16.515 0.406522 18.5928 0.673564 20.7363C0.940402 22.8782 1.47968 25.1217 2.02325 27.3729L2.02373 27.3748C2.58031 29.64 3.16481 31.984 3.97665 34.0631C4.78765 36.1401 5.84482 38.0059 7.37922 39.2815L7.38092 39.2829C10.4709 41.8287 15.2634 41.8715 19.3032 40.9536L30.65 5.36046Z"
				fill="url(#paint0_radial_1024_19072)"
				fillOpacity="0.4"
				stroke="url(#paint1_linear_1024_19072)"
			/>
			<defs>
				<radialGradient
					id="paint0_radial_1024_19072"
					cx="0"
					cy="0"
					r="1"
					gradientUnits="userSpaceOnUse"
					gradientTransform="translate(8.14286 7.07143) rotate(31.0594) scale(36.6885 23.6743)">
					<stop offset="0.0001" stopColor="#D9D9D9" />
					<stop offset="0.0002" stopColor="#D9D9D9" />
					<stop offset="1" stopColor="#D9D9D9" stopOpacity="0" />
				</radialGradient>
				<linearGradient
					id="paint1_linear_1024_19072"
					x1="-0.0674157"
					y1="1"
					x2="41"
					y2="1.00062"
					gradientUnits="userSpaceOnUse">
					<stop stopColor="#0AB311" stopOpacity="0" />
					<stop offset="0.0747648" stopColor="#21D97D" />
					<stop offset="1" stopColor="#248B3B" stopOpacity="0" />
				</linearGradient>
			</defs>
		</svg>
	)
}

export const PanelLeaderboardIcon = (props: IconProps) => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="17" height="16" viewBox="0 0 17 16" fill="none" {...props}>
			<path
				d="M8.51693 9.33329V10.6666C6.30779 10.6666 4.51693 12.4575 4.51693 14.6666H3.18359C3.18359 11.7211 5.57141 9.33329 8.51693 9.33329ZM8.51693 8.66663C6.30693 8.66663 4.51693 6.87663 4.51693 4.66663C4.51693 2.45663 6.30693 0.666626 8.51693 0.666626C10.7269 0.666626 12.5169 2.45663 12.5169 4.66663C12.5169 6.87663 10.7269 8.66663 8.51693 8.66663ZM8.51693 7.33329C9.99026 7.33329 11.1836 6.13996 11.1836 4.66663C11.1836 3.19329 9.99026 1.99996 8.51693 1.99996C7.04359 1.99996 5.85026 3.19329 5.85026 4.66663C5.85026 6.13996 7.04359 7.33329 8.51693 7.33329ZM12.5169 14.3333L10.5577 15.3634L10.9319 13.1816L9.34673 11.6366L11.5373 11.3183L12.5169 9.33329L13.4966 11.3183L15.6871 11.6366L14.102 13.1816L14.4762 15.3634L12.5169 14.3333Z"
				fill="currentColor"
			/>
		</svg>
	)
}
export const PanelCalendarIcon = (props: IconProps) => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="17" height="16" viewBox="0 0 17 16" fill="none" {...props}>
			<path
				d="M6.51432 0.666626V1.99996H10.5143V0.666626H11.8477V1.99996H14.5143C14.8825 1.99996 15.181 2.29844 15.181 2.66663V13.3333C15.181 13.7015 14.8825 14 14.5143 14H2.51432C2.14614 14 1.84766 13.7015 1.84766 13.3333V2.66663C1.84766 2.29844 2.14614 1.99996 2.51432 1.99996H5.18099V0.666626H6.51432ZM13.8477 7.33329H3.18099V12.6666H13.8477V7.33329ZM5.18099 3.33329H3.18099V5.99996H13.8477V3.33329H11.8477V4.66663H10.5143V3.33329H6.51432V4.66663H5.18099V3.33329Z"
				fill="currentColor"
			/>
		</svg>
	)
}
export const PanelApproachIcon = (props: IconProps) => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none" {...props}>
			<path
				d="M11.3346 11.3334V12.6667C11.3346 13.0349 11.6331 13.3334 12.0013 13.3334C12.3695 13.3334 12.668 13.0349 12.668 12.6667V2.66671H3.33464V10H2.0013V2.00004C2.0013 1.63185 2.29978 1.33337 2.66797 1.33337H13.3346C13.7028 1.33337 14.0013 1.63185 14.0013 2.00004V12.6667C14.0013 13.7713 13.1059 14.6667 12.0013 14.6667H2.66797C1.5634 14.6667 0.667969 13.7713 0.667969 12.6667V11.3334H11.3346Z"
				fill="currentColor"
			/>
		</svg>
	)
}

export const PanelApproachArrowIcon = (props: IconProps) => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
			<path
				d="M10.8284 12.0007L15.7782 16.9504L14.364 18.3646L8 12.0007L14.364 5.63672L15.7782 7.05093L10.8284 12.0007Z"
				fill="currentColor"
			/>
		</svg>
	)
}

export const ChevronDownIcon = (props: IconProps) => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none" {...props}>
			<path
				d="M8.00042 8.78089L11.3003 5.48108L12.2431 6.42389L8.00042 10.6666L3.75781 6.42389L4.70062 5.48108L8.00042 8.78089Z"
				fill="currentColor"
			/>
		</svg>
	)
}
export const ChevronDownBoldIcon = (props: IconProps) => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none" {...props}>
			<path
				d="M7.99993 8.78077L11.2998 5.48096L12.2426 6.42376L7.99993 10.6664L3.75732 6.42376L4.70013 5.48096L7.99993 8.78077Z"
				fill="currentColor"
			/>
		</svg>
	)
}

export const SpeedUpFillIcon = (props: IconProps) => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 10 10" fill="none" {...props}>
			<path
				d="M8.33301 5.41667C8.33301 6.33712 7.95992 7.17046 7.35672 7.77371L7.94597 8.36296C8.69997 7.60892 9.16634 6.56725 9.16634 5.41667C9.16634 3.11548 7.30084 1.25 4.99967 1.25C2.69849 1.25 0.833008 3.11548 0.833008 5.41667C0.833008 6.56725 1.29938 7.60892 2.0534 8.36296L2.64265 7.77371C2.03944 7.17046 1.66634 6.33712 1.66634 5.41667C1.66634 3.57572 3.15872 2.08333 4.99967 2.08333C6.84063 2.08333 8.33301 3.57572 8.33301 5.41667ZM6.37176 3.4554L4.37467 5.20833L5.20801 6.04167L6.96101 4.04466L6.37176 3.4554Z"
				fill="currentColor"
			/>
		</svg>
	)
}

export const GraphIcon = (props: IconProps) => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17" fill="none" {...props}>
			<path
				d="M3.33333 2.5V13.1667H14V14.5H2V2.5H3.33333ZM13.5286 4.69526L14.4714 5.63807L10.6667 9.4428L8.66667 7.44333L5.80474 10.3047L4.86193 9.36193L8.66667 5.55719L10.6667 7.55667L13.5286 4.69526Z"
				fill="currentColor"
			/>
		</svg>
	)
}
export const UpArrow = ({ isActive, ...props }: ArrowIconProps) => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="13"
			height="12"
			viewBox="0 0 13 12"
			fill="none"
			style={{ transform: isActive ? 'none' : 'rotate(180deg)' }}
			{...props}>
			<path d="M6.31218 0.75L12.3744 11.25H0.25L6.31218 0.75Z" fill="currentColor" />
		</svg>
	)
}

export const BellIcon = (props: IconProps) => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none" {...props}>
			<path
				d="M4.16667 15H15.8333V9.19279C15.8333 5.95668 13.2217 3.33329 10 3.33329C6.77834 3.33329 4.16667 5.95668 4.16667 9.19279V15ZM10 1.66663C14.1422 1.66663 17.5 5.03619 17.5 9.19279V16.6666H2.5V9.19279C2.5 5.03619 5.85787 1.66663 10 1.66663ZM7.91667 17.5H12.0833C12.0833 18.6505 11.1506 19.5833 10 19.5833C8.84942 19.5833 7.91667 18.6505 7.91667 17.5Z"
				fill="currentColor"
			/>
		</svg>
	)
}

export const HamburgerTopMenu = (props: IconProps) => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none" {...props}>
			<path
				d="M2.5 3.33337H17.5V5.00004H2.5V3.33337ZM2.5 9.16671H12.5V10.8334H2.5V9.16671ZM2.5 15H17.5V16.6667H2.5V15Z"
				fill="currentColor"
			/>
		</svg>
	)
}
export const SettingsIcon = (props: IconProps) => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none" {...props}>
			<g clipPath="url(#clip0_8595_494)">
				<path
					d="M5.79157 2.66676L7.52932 0.92903C7.78965 0.668676 8.21179 0.668676 8.47212 0.92903L10.2099 2.66676H12.6674C13.0356 2.66676 13.3341 2.96524 13.3341 3.33343V5.79096L15.0718 7.52871C15.3321 7.78904 15.3321 8.21118 15.0718 8.47151L13.3341 10.2092V12.6668C13.3341 13.035 13.0356 13.3334 12.6674 13.3334H10.2099L8.47212 15.0712C8.21179 15.3315 7.78965 15.3315 7.52932 15.0712L5.79157 13.3334H3.33404C2.96585 13.3334 2.66737 13.035 2.66737 12.6668V10.2092L0.92964 8.47151C0.669287 8.21118 0.669287 7.78904 0.92964 7.52871L2.66737 5.79096V3.33343C2.66737 2.96524 2.96585 2.66676 3.33404 2.66676H5.79157ZM4.00071 4.0001V6.34324L2.34385 8.00011L4.00071 9.65698V12.0001H6.34385L8.00072 13.657L9.65759 12.0001H12.0007V9.65698L13.6576 8.00011L12.0007 6.34324V4.0001H9.65759L8.00072 2.34324L6.34385 4.0001H4.00071ZM8.00072 10.6668C6.52795 10.6668 5.33404 9.47284 5.33404 8.00011C5.33404 6.52734 6.52795 5.33343 8.00072 5.33343C9.47345 5.33343 10.6674 6.52734 10.6674 8.00011C10.6674 9.47284 9.47345 10.6668 8.00072 10.6668ZM8.00072 9.33344C8.73712 9.33344 9.33405 8.73651 9.33405 8.00011C9.33405 7.26371 8.73712 6.66678 8.00072 6.66678C7.26432 6.66678 6.66739 7.26371 6.66739 8.00011C6.66739 8.73651 7.26432 9.33344 8.00072 9.33344Z"
					fill="currentColor"
				/>
			</g>
			<defs>
				<clipPath id="clip0_8595_494">
					<rect width="16" height="16" fill="white" />
				</clipPath>
			</defs>
		</svg>
	)
}

export const MenuDotsIcon = (props: IconProps) => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
			<path
				d="M12.0007 10.9993C11.4484 10.9993 11.0007 11.447 11.0007 11.9993C11.0007 12.5516 11.4484 12.9993 12.0007 12.9993C12.553 12.9993 13.0007 12.5516 13.0007 11.9993C13.0007 11.447 12.553 10.9993 12.0007 10.9993Z"
				stroke="currentColor"
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M12.0007 17.9993C11.4484 17.9993 11.0007 18.447 11.0007 18.9993C11.0007 19.5516 11.4484 19.9993 12.0007 19.9993C12.553 19.9993 13.0007 19.5516 13.0007 18.9993C13.0007 18.447 12.553 17.9993 12.0007 17.9993Z"
				stroke="currentColor"
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M12.0007 3.99927C11.4484 3.99927 11.0007 4.44698 11.0007 4.99927C11.0007 5.55155 11.4484 5.99927 12.0007 5.99927C12.553 5.99927 13.0007 5.55155 13.0007 4.99927C13.0007 4.44698 12.553 3.99927 12.0007 3.99927Z"
				stroke="currentColor"
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	)
}

export const PinIcon = (props: IconProps) => {
	return (
		<svg width="14" height="13" viewBox="0 0 14 13" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
			<path
				d="M13.3518 6.686L6.75251 0.0866699L5.80984 1.02867L6.75318 1.972V1.97334L3.45318 5.272L3.45251 5.27334L2.50984 4.32934L1.56718 5.27267L4.39584 8.10067L0.624512 11.8713L1.56718 12.814L5.33851 9.04334L8.16718 11.8713L9.10984 10.9293L8.16718 9.986L11.4672 6.686L12.4098 7.62867L13.3518 6.686ZM7.22451 9.04267L7.22385 9.04334L4.39584 6.21467L7.69518 2.91467L10.5232 5.74267L7.22451 9.04267Z"
				fill="currentColor"
			/>
		</svg>
	)
}

export const ExitIcon = (props: IconProps) => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none" {...props}>
			<path
				d="M2.33334 10.5H3.50001V11.6667H10.5V2.33335H3.50001V3.50002H2.33334V1.75002C2.33334 1.42786 2.59451 1.16669 2.91668 1.16669H11.0833C11.4055 1.16669 11.6667 1.42786 11.6667 1.75002V12.25C11.6667 12.5722 11.4055 12.8334 11.0833 12.8334H2.91668C2.59451 12.8334 2.33334 12.5722 2.33334 12.25V10.5ZM3.50001 6.41669H7.58334V7.58335H3.50001V9.33335L0.583344 7.00002L3.50001 4.66669V6.41669Z"
				fill="currentColor"
			/>
		</svg>
	)
}
