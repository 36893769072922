import axios from 'axios'

export const unfollowBot = async (token: string, feedUserName: string): Promise<string[]> => {
	try {
		// Validate environment variable
		if (!process.env.REACT_APP_API_SERVER) {
			throw new Error('API server URL is not defined in environment variables.')
		}

		const response = await axios.post(
			`${process.env.REACT_APP_API_SERVER}/okta/unfollow-bot`,
			{ feedUserName },
			{
				headers: {
					Authorization: `Bearer ${token}`
				}
			}
		)

		if (response.status === 200) {
			console.log('Follow bot successfully:', response.data)

			// Return the updated feed_notification_list
			return response.data.feed_notification_list
		} else {
			console.error('Failed to follow bot:', response.data.message)
			throw new Error(response.data.message || 'Failed to follow bot')
		}
	} catch (error: Error | any) {
		console.error('Error following bot:', error)
		throw new Error(error.message || 'An unexpected error occurred while following the bot.')
	}
}
