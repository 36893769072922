import React, { useState } from 'react'
import * as Dialog from '@radix-ui/react-dialog'
import { connect as connectStream, FeedAPIResponse } from 'getstream'
import * as styles from './MobileNotificationsDialog.css'
import { IconButton } from 'components/inputs/icon-button'
import { BlockIcon } from 'components/icons/block-icon'
import { CloseIcon, BellIcon } from 'assets/icons/icons'
import { Text } from 'components/typography/text'
import { Box } from 'components/layout/box'
import { CustomGenerics } from 'api/notifications/types'

import { useMainAppContext } from 'context/MainAppContext'
import { NotificationItem } from 'components/RightSidePanel/PanelTop/Notifications/components/NotificationItem'

const FEED_API_KEY = process.env.REACT_APP_CHAT_API
const APP_ID = process.env.REACT_APP_FEED_APP_ID

export const MobileNotificationsDialog = () => {
	const [open, setOpen] = useState(false)
	const [error, setError] = useState<string | null>(null)
	const { feedUserId, feedUserToken, notifications, setNotifications } = useMainAppContext()

	const unreadCount = notifications.filter(notification => !notification.is_read).length

	const handleMarkAsRead = (id: string) => {
		// Update the local state to mark the notification as read
		setNotifications(prev =>
			prev.map(notification => {
				if (notification.id === id) {
					return { ...notification, is_read: true }
				}
				return notification
			})
		)
	}

	const handleMarkAllAsRead = async () => {
		try {
			const client = connectStream<CustomGenerics>(FEED_API_KEY, null, APP_ID, { timeout: 8000 })
			const feed = client.feed('notification', feedUserId, feedUserToken)
			const response: FeedAPIResponse<CustomGenerics> = await feed.get({ limit: 50 })

			const unreadNotifications = response.results.filter(notification => !notification.is_read)
			const unreadIds = unreadNotifications.map(notification => notification.id)

			await feed.get({ mark_read: unreadIds })

			setNotifications(prev =>
				prev.map(notification => ({
					...notification,
					is_read: true // Mark all notifications as read
				}))
			)
		} catch (err: any) {
			setError(err.message)
		}
	}

	if (error) return <Text>X</Text>

	return (
		<Dialog.Root open={open} onOpenChange={setOpen}>
			<Dialog.Trigger asChild>
				<IconButton variant="secondaryModern" size="medium">
					<Box style={{ display: 'relative' }}>
						{unreadCount > 0 && <Box className={styles.notificationNumber}>{unreadCount}</Box>}
						<BlockIcon icon={BellIcon} size="medium" color="primary.100" />
					</Box>
				</IconButton>
			</Dialog.Trigger>
			<Dialog.Portal>
				<Dialog.Overlay className={styles.dialogOverlay} />

				<Dialog.Content className={styles.dialogContent}>
					{/* Header */}
					<Box display="flex" alignItems="center" justifyContent="space-between" paddingY={2} paddingX={4}>
						<Dialog.Title>
							<Text variant="body1Bold">Notifications</Text>
						</Dialog.Title>
						<Dialog.Close asChild>
							<IconButton variant="tertiary" size="small">
								<BlockIcon icon={CloseIcon} size="medium" color="neutral.500" />
							</IconButton>
						</Dialog.Close>
					</Box>
					<Box className={styles.body}>
						<Box style={{ cursor: 'pointer' }} onClick={handleMarkAllAsRead} paddingX={4}>
							<Text variant="body2Bold" color="primary.500">
								Mark all as read
							</Text>
						</Box>
						<Box className={styles.notificationsList}>
							{notifications.length > 0 ? (
								notifications.flatMap(notification => {
									if (!notification.activities || notification.activities.length === 0) {
										console.warn('Notification with no activities:', notification)
										return [] // Skip notifications with no activities
									}

									return notification.activities.map(activity => (
										<NotificationItem
											key={activity.id}
											notificationId={notification.id}
											activity={activity}
											isRead={notification.is_read}
											setExpandedPanel={() => setOpen(false)}
											onMarkAsRead={handleMarkAsRead}
										/>
									))
								})
							) : (
								<Text variant="body1">No notifications available</Text>
							)}
						</Box>
					</Box>
				</Dialog.Content>
			</Dialog.Portal>
		</Dialog.Root>
	)
}
