import React, { useRef, useState, useEffect } from 'react'
import { useIsFetching } from '@tanstack/react-query'
import { motion } from 'motion/react'
import { useOrientation, useWindowSize } from '@uidotdev/usehooks'
import clsx from 'clsx'

import { Box } from 'components/layout/box'
import * as styles from './LatestTrades.css'
import { BotCard } from './BotCard/BotCard'
import { Trader } from 'api/mavbots-arena/fetchBotStats'
import { Text } from 'components/typography/text'
import { breakpoints } from 'style/tokens/breakpoints'
import { IconButton } from 'components/inputs/icon-button'
import { BlockIcon } from 'components/icons/block-icon'
import { SmallLeftArrow, SmallRightArrow } from 'assets/icons/icons'
import { useMainAppContext } from 'context/MainAppContext'
import { NotAvailable } from 'components/NotAvailable/NotAvailable'

const containerVariants = {
	initial: { opacity: 0 },
	animate: {
		opacity: 1,
		transition: {
			staggerChildren: 0.08 // Increased delay between each item
		}
	}
}

const itemVariants = {
	initial: { scale: 0.8, opacity: 0, y: 50 },
	animate: {
		scale: 1,
		opacity: 1,
		y: 0,
		transition: {
			type: 'spring',
			stiffness: 150, // Reduced stiffness for a more gradual motion
			damping: 20, // Increased damping for slower settling
			duration: 0.6 // Custom duration for item animation
		}
	},
	exit: { scale: 0.8, opacity: 0, y: -50, transition: { duration: 0.3 } } // Exit transition duration
}

interface LatestTradesProps {
	trades: Trader[]
}

export const LatestTrades: React.FC<LatestTradesProps> = ({ trades }) => {
	const [reorderedTrades, setReorderedTrades] = useState<Trader[]>(trades || []) // Maintain state for trades
	const [currentCard, setCurrentCard] = useState(1) // Track the current card index
	const size = useWindowSize()
	const isMobile = size && size.width && size.width < Number(breakpoints.tablet)
	const { type: orientationType } = useOrientation()
	const { userGroup } = useMainAppContext()
	const isDabble = process.env.REACT_APP_DABBLE === userGroup

	const scrollContainerRef = useRef<HTMLDivElement>(null)
	const cardRefs = useRef<Array<HTMLDivElement | null>>([])

	// Listen for arena-trades invalidations

	const isFetchingArenaTrades = useIsFetching({ queryKey: ['arena-trades'] })

	// Update reordered trades when arena-trades is invalidated
	useEffect(() => {
		// Preserve the old order and reconcile with new trades
		setReorderedTrades(prevTrades => {
			// Map old trades by their ID for quick lookup
			const prevTradesMap = new Map(prevTrades.map(trade => [trade.bot_id, trade]))
			// Merge the new trades while preserving the old order
			const mergedTrades = trades.map(newTrade => prevTradesMap.get(newTrade.bot_id) || newTrade)
			return mergedTrades
		})
	}, [trades, isFetchingArenaTrades])

	useEffect(() => {
		if (!isMobile) return // Only apply on mobile

		const options = {
			root: scrollContainerRef.current,
			rootMargin: '0px',
			threshold: 0.5 // 50% of the card is visible
		}

		const callback = (entries: IntersectionObserverEntry[]) => {
			entries.forEach(entry => {
				if (entry.isIntersecting) {
					const index = Number(entry.target.getAttribute('data-index'))
					setCurrentCard(index + 1) // Assuming index starts from 0
				}
			})
		}

		const observer = new IntersectionObserver(callback, options)

		cardRefs.current.forEach(card => {
			if (card) {
				observer.observe(card)
			}
		})

		return () => {
			observer.disconnect()
		}
	}, [isMobile, trades])

	// Reset scroll position and currentCard when trades change
	useEffect(() => {
		if (scrollContainerRef.current) {
			scrollContainerRef.current.scrollTo({ left: 0, behavior: 'smooth' })
			setCurrentCard(1)
		}
	}, [trades])

	// Handle card navigation
	const handleNext = () => {
		if (scrollContainerRef.current) {
			const nextIndex = Math.min(currentCard, trades.length - 1)
			const nextCard = cardRefs.current[nextIndex]
			if (nextCard) {
				nextCard.scrollIntoView({ behavior: 'smooth', inline: 'start' })
			}
		}
	}

	const handlePrev = () => {
		if (scrollContainerRef.current) {
			const prevIndex = Math.max(currentCard - 2, 0)
			const prevCard = cardRefs.current[prevIndex]
			if (prevCard) {
				prevCard.scrollIntoView({ behavior: 'smooth', inline: 'start' })
			}
		}
	}

	return (
		<Box className={styles.latestTradesLayout}>
			{isDabble ? (
				<NotAvailable inGroupAvailable="Invest" />
			) : (
				<>
					{trades && trades.length > 0 ? (
						<>
							{isMobile ? (
								// Mobile view: Show one card at a time with navigation buttons
								<>
									<div className={styles.scrollWrapper} ref={scrollContainerRef}>
										{trades.map((bot, index) => (
											<div
												key={bot.bot_id}
												className={styles.botCardWrapper}
												ref={el => (cardRefs.current[index] = el)}
												data-index={index}>
												<BotCard key={bot.bot_id} data={bot} />
											</div>
										))}
									</div>

									{/* Single card indicator below the cards */}
									<Box className={styles.cardIndicator}>
										<Text variant="body2" color="neutral.300" textAlign="center">
											{currentCard}/{trades.length}
										</Text>
									</Box>

									<Box className={styles.navigationButtons}>
										<IconButton variant="action" onClick={handlePrev} className={styles.button}>
											<BlockIcon icon={SmallLeftArrow} />
										</IconButton>

										<IconButton variant="action" onClick={handleNext} className={styles.button}>
											<BlockIcon icon={SmallRightArrow} />
										</IconButton>
									</Box>
								</>
							) : (
								// Desktop view: Show all cards in a grid
								<motion.div
									layout
									variants={containerVariants}
									initial="initial"
									animate="animate"
									transition={{ duration: 0.5 }}
									className={clsx({
										[styles.botCardPortraitGrid]: orientationType === 'portrait-primary',
										[styles.botCardGrid]: orientationType !== 'portrait-primary'
									})}>
									{reorderedTrades.map(bot => (
										<motion.div
											key={bot.bot_id}
											layout
											variants={itemVariants}
											layoutId={`trade-${bot.bot_id}`} // Helps track item positions for smoother animations
										>
											<BotCard key={bot.bot_id} data={bot} />
										</motion.div>
									))}
								</motion.div>
							)}
						</>
					) : (
						<Text variant="body1" color="neutral.500" textAlign="center">
							No Results
						</Text>
					)}
				</>
			)}
		</Box>
	)
}
