import React from 'react'
import relativeTime from 'dayjs/plugin/relativeTime'
import { Box } from 'components/layout/box'
import { Text } from 'components/typography/text'
import { Avatar } from './Avatar/Avatar'
import * as styles from './NotificationItem.css'
import { connect as connectStream } from 'getstream'
import dayjs from 'dayjs'
import { notification } from '../data'
import { NavLink } from 'react-router-dom'
import { CustomGenerics } from 'api/notifications/types'
import clsx from 'clsx'
import { useMainAppContext } from 'context/MainAppContext'
import { PanelType } from 'components/RightSidePanel/RightSidePanel'

dayjs.extend(relativeTime)
const renderTime = (time: string) => {
	// Parse the time using the correct format
	const parsedTime = dayjs(time.slice(0, -3) + 'Z', 'YYYY-MM-DDTHH:mm:ss.SSSZ')
	return parsedTime.fromNow()
}

const FEED_API_KEY = process.env.REACT_APP_CHAT_API || ''
const APP_ID = process.env.REACT_APP_FEED_APP_ID

interface NotificationItemProps {
	notificationId: string
	activity: CustomGenerics['activity']
	isRead: boolean
	onMarkAsRead: (id: string) => void
	setExpandedPanel: (panel: PanelType | null) => void
}

export const NotificationItem = ({
	notificationId,
	activity,
	isRead,
	setExpandedPanel,
	onMarkAsRead
}: NotificationItemProps) => {
	const { feedUserId, feedUserToken } = useMainAppContext()
	const matchingNotification = notification.find(n => n.actor === activity.actor)
	const link = activity.link ?? matchingNotification?.link ?? '#'

	const handleNotificationRead = async () => {
		// Skip if the notification is already marked as read
		setExpandedPanel(null)
		if (isRead) return

		try {
			// Update on the server
			const client = connectStream(FEED_API_KEY, null, APP_ID, { timeout: 8000 })
			const feed = client.feed('notification', feedUserId, feedUserToken)
			await feed.get({ mark_read: [notificationId] })

			// Update locally
			onMarkAsRead(notificationId)
		} catch (err) {
			console.error('Error marking notification as read:', err)
		}
	}

	// Get the avatar image or fallback to a default avatar
	const avatarImg = matchingNotification ? matchingNotification.avatar : '-'
	return (
		<NavLink to={link}>
			<Box as="a" className={styles.container} href={link} onClick={handleNotificationRead}>
				<Box className={clsx(styles.redDot, { [styles.redDotHidden]: isRead })} />
				<Box>
					<Avatar avatar={avatarImg} />
				</Box>
				<Box>
					<Box display="flex" gap={2} justifyContent={'space-between'} alignItems="center">
						<Text variant="body1Bold" color="neutral.50">
							{activity.title}
						</Text>
						<Text variant="body2" color="neutral.300">
							{renderTime(activity.time)}
						</Text>
					</Box>
					<Text variant="body1" color="neutral.100">
						{activity.text}
					</Text>
				</Box>
			</Box>
		</NavLink>
	)
}
