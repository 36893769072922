import React, { useState, HTMLAttributes } from 'react'
import clsx from 'clsx'

import { Box } from 'components/layout/box'
import * as styles from './Card.css'

// import { Typewriter } from '../StateOfMarket/Typewriter/Typewriter'
import { Error } from 'components/Error'
import { CardHeader, CardHeaderProps } from './CardHeader'
import Loader from 'components/Loader/Loader'

interface CardProps extends Pick<HTMLAttributes<HTMLElement>, 'style' | 'children'>, CardHeaderProps {
	component?: React.JSX.Element
	isError?: boolean
	bodyStyle?: React.CSSProperties
	headerStyle?: React.CSSProperties
	headerChildren?: React.ReactNode
	isLoading?: boolean
}

export const Card = ({
	title,
	titleAnimate,
	component,
	tooltip,
	isError,
	children,
	bodyStyle,
	headerChildren,
	headerStyle,
	isLoading = false,
	...rest
}: CardProps) => {
	// const [isHovered, setIsHovered] = useState(false)
	// const [hasBeenHovered, setHasBeenHovered] = useState(false)

	// const handleMouseEnter = () => {
	// 	setIsHovered(true)
	// 	setHasBeenHovered(true)
	// }

	// const handleMouseLeave = () => {
	// 	setIsHovered(false)
	// }

	return (
		<Box
			{...rest}
			className={clsx(styles.cardContainer, {
				// [styles.hover]: isHovered,
				// [styles.leave]: !isHovered && hasBeenHovered,
				[styles.isLoading]: isLoading
			})}
			// className={clsx(styles.cardContainer, {
			// 	[styles.hover]: isHovered,
			// 	[styles.leave]: !isHovered && hasBeenHovered && !isHovered,
			// 	[styles.error]: isError
			// })}
			// onMouseEnter={handleMouseEnter}
			// onMouseLeave={handleMouseLeave}
		>
			{title && (
				<CardHeader
					titleAnimate={titleAnimate}
					headerStyle={headerStyle}
					title={title}
					tooltip={tooltip}
					children={headerChildren}
				/>
			)}
			<Box className={styles.body} style={bodyStyle}>
				{isLoading && <Loader />}
				{!isLoading && isError && <Error />}
				{!isLoading && !isError && children}
			</Box>
		</Box>
	)
}
