import { useState } from 'react'
import { useQuery } from '@tanstack/react-query'
import numeral from 'numeral'
import clsx from 'clsx'
import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import * as styles from './EconomicMobile.css'
import { Box } from 'components/layout/box'
import { fetchEconomic } from 'api/right-side-panel'
import { Text } from 'components/typography/text'
import { Dropdown } from 'components/Dropdown'
import { Tooltip } from 'components/Tooltip/Tooltip'
import Loader from 'components/Loader/Loader'
import { PanelCalendarIcon } from 'assets/icons/icons'
import { InlineIcon } from 'components/icons/inline-icon'
import { useOktaAuth } from '@okta/okta-react'

dayjs.extend(customParseFormat)

const getImportanceClassNames = (importance: number, index: number) => {
	return clsx(styles.importanceCircle, { [styles.importanceCircleActive]: index < importance })
}

const getUnitFormat = (value: string | number, unit?: string) => {
	if (value == '' || Number.isNaN(value)) {
		return '-'
	}
	if (unit == '%') {
		return `${value}${unit}`
	}
	if (unit == '$') {
		return `${unit}${numeral(value).format('0.00a').toUpperCase()}`
	}
	return `${numeral(value).format('0.00a').toUpperCase()} ${unit}`
}

const dropdownData = [
	{
		label: 'Today',
		value: 'today'
	},
	{
		label: 'This Week',
		value: 'this_week'
	},
	{
		label: 'Next Week',
		value: 'next_week'
	},
	// {
	// 	label: 'Next Month',
	// 	value: 'next_month'
	// }
	{
		label: 'Next 30 Days',
		value: 'next_30_days'
	}
]

export const EconomicMobile = () => {
	const { oktaAuth } = useOktaAuth()
	const accessToken = oktaAuth.getAccessToken()
	const [expandedIndex, setExpandedIndex] = useState<number | null>(null)
	const [dateFilter, setDateFilter] = useState(dropdownData[0])
	const {
		data: economicData,
		isLoading,
		isError
	} = useQuery({
		queryKey: ['calendar-economic', dateFilter.value],
		queryFn: () => fetchEconomic(dateFilter.value, accessToken)
	})

	const toggleAccordion = (index: number) => {
		setExpandedIndex(expandedIndex === index ? null : index)
	}

	return (
		<Box display="flex" flexDirection="column" gap={4} overflow="auto" paddingTop={4}>
			<Box display="flex" flexDirection="column" gap={4}>
				<Box>
					<Dropdown data={dropdownData} handleFilter={setDateFilter} filter={dateFilter} />
				</Box>
				{isLoading && (
					<>
						<Loader text="Loading..." />
					</>
				)}
				{isError && (
					<Text variant="body1Bold" color="neutral.300">
						Error fetching data
					</Text>
				)}
				{!isLoading && !isError && economicData?.length === 0 && (
					<Text variant="body1Bold" color="neutral.300">
						No data available
					</Text>
				)}
				{economicData?.map((item, index) => (
					<Box key={index} className={styles.economicCard} onClick={() => toggleAccordion(index)}>
						<Box display="flex" justifyContent="space-between" gap={1}>
							<Text variant="body1Bold" color={'secondary.400'}>
								{item.event}
							</Text>
							<Text variant="body1Bold" color={'primary.500'}>
								{item.country || '-'}
							</Text>
						</Box>

						<Box display="flex" alignItems="center" justifyContent="space-between" gap={1}>
							<Text variant="body1Bold" style={{ display: 'flex', alignItems: 'center' }}>
								<InlineIcon icon={PanelCalendarIcon} color="neutral.300" style={{ marginRight: '4px' }} />
								{dateFilter.value === 'today'
									? dayjs(`${item.date} ${item.time}`, 'YYYY-MM-DD HH:mm').format('h:mm A')
									: dayjs(item.date, 'YYYY-MM-DD').format('MMM D, YYYY')}
							</Text>
							<Tooltip content="Event importance">
								<div className={styles.importanceWrapper}>
									{[...Array(5)].map((_, index) => (
										<Box key={index} className={getImportanceClassNames(item.importance, index)}></Box>
									))}
								</div>
							</Tooltip>
						</Box>

						<Box display="flex" gap={4} flexWrap="wrap">
							{/* Forecast */}
							<Box display="flex" flexDirection="column">
								<Text variant="metadata" color="neutral.300">
									Forecast
								</Text>
								<Text variant="body2Bold" color="neutral.50">
									{getUnitFormat(item.forecast, item.unit)}
								</Text>
							</Box>
							{/* Actual */}
							<Box display="flex" flexDirection="column">
								<Text variant="metadata" color="neutral.300">
									Actual
								</Text>
								<Text variant="body2Bold" color="neutral.50">
									{getUnitFormat(item.actual, item.unit)}
								</Text>
							</Box>
							{/* Previous */}
							<Box display="flex" flexDirection="column">
								<Text variant="metadata" color="neutral.300">
									Previous
								</Text>
								<Text variant="body2Bold" color="neutral.50">
									{getUnitFormat(item.previous, item.unit)}
								</Text>
							</Box>
						</Box>

						<Box className={clsx(styles.accordionContent, expandedIndex === index && styles.expandedAccordionContent)}>
							<Text variant="body2" color="neutral.300">
								{item.description || 'No description available'}
							</Text>
						</Box>
					</Box>
				))}
			</Box>
		</Box>
	)
}
