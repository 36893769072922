import React, { useMemo, useEffect, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useOktaAuth } from '@okta/okta-react'

import { Box } from 'components/layout/box'
import * as styles from './MeetBots.css'
import { Card } from 'components/Card'
import { MeetAvatar } from '../components/Avatar/Avatar'
import { useArenaContext } from 'context/ArenaContext'
import { BotFilter } from '../components/BotFilters/BotFilter'
import { Text } from 'components/typography/text'
import { fetchBotStats, Trader } from 'api/mavbots-arena/fetchBotStats'
import { useQuery } from '@tanstack/react-query'

const renderBots = (bots: Trader[]) => {
	const { arenaSelectedBot, setArenaSelectedBot } = useArenaContext()
	return bots.map(bot => (
		<MeetAvatar
			handleClick={() => setArenaSelectedBot(bot)}
			key={bot.bot_id}
			avatar={bot.avatar}
			botName={bot.bot_name}
			isSelected={arenaSelectedBot?.bot_id === bot.bot_id}
			role={bot.role}
			isAvailable={bot.trades.length > 0}
		/>
	))
}

interface FormValues {
	selectedStrategies: string[]
	selectedTickers: string[]
	selectedBotIds: number[]
}

interface MeetBotsProps {}

export const MeetBots = ({}: MeetBotsProps) => {
	const { arenaBotsData } = useArenaContext()
	const { oktaAuth } = useOktaAuth()
	const accessToken = oktaAuth.getAccessToken()
	const [didInitialReset, setDidInitialReset] = useState(false)

	const strategies = useMemo(() => {
		const strategySet = new Set<string>()
		arenaBotsData.forEach(bot => {
			if (bot.strategy) {
				strategySet.add(bot.strategy)
			}
		})
		return Array.from(strategySet)
	}, [arenaBotsData])

	const tickers = useMemo(() => {
		const tickerSet = new Set<string>()
		arenaBotsData.forEach(bot => {
			if (bot.trades && bot.trades.length > 0) {
				bot.trades.forEach(trade => {
					tickerSet.add(trade)
				})
			}
		})
		return Array.from(tickerSet)
	}, [arenaBotsData])

	const botOptions = useMemo(() => {
		return arenaBotsData.map(bot => ({
			label: bot.bot_name,
			value: bot.bot_id,
			avatar: bot.avatar
		}))
	}, [arenaBotsData])

	// Set up form methods
	const methods = useForm<FormValues>({
		defaultValues: {
			selectedStrategies: [],
			selectedTickers: [],
			selectedBotIds: []
		}
	})
	const { watch, reset } = methods
	const selectedStrategies = watch('selectedStrategies') || []
	const selectedTickers = watch('selectedTickers') || []
	const selectedBotIds = watch('selectedBotIds') || []

	useEffect(() => {
		if (!didInitialReset && (strategies.length > 0 || tickers.length > 0 || botOptions.length > 0)) {
			reset({
				selectedStrategies: strategies,
				selectedTickers: tickers,
				selectedBotIds: botOptions.map(option => option.value)
			})
			setDidInitialReset(true)
		}
	}, [didInitialReset, strategies, tickers, botOptions, reset])

	// Split, sort, and filter bots
	const groupedBots = useMemo(() => {
		// 1) Filter first
		const filteredBots = arenaBotsData.filter(bot => {
			// 1) Filter by selected strategies *only if* the user selected any
			if (
				selectedStrategies.length > 0 && // Only apply if user actually selected strategies
				!selectedStrategies.includes(bot.strategy) // and the bot’s strategy is not included
			) {
				return false
			}

			// 2) Filter by selected tickers *only if* the user selected any
			if (selectedTickers.length > 0) {
				const hasMatchingTrade = bot.trades?.some(trade => selectedTickers.includes(trade))
				// If user picked tickers but none of them is in this bot’s trades, exclude it
				if (!hasMatchingTrade) return false
			}

			// 3) Filter by selected bot IDs *only if* the user selected any
			if (selectedBotIds.length > 0 && !selectedBotIds.includes(bot.bot_id)) {
				return false
			}

			return true
		})

		// 2) Sort your filtered bots
		const sortedAndGroupedBots = (bots: Trader[]) => {
			return bots
				.sort((a, b) => {
					// Sort by risk_adjusted_return first
					const riskAdjustedA = a.bot_details.widgets.risk_adjusted_return.text_1
					const riskAdjustedB = b.bot_details.widgets.risk_adjusted_return.text_1
					if (riskAdjustedA > riskAdjustedB) return -1
					if (riskAdjustedA < riskAdjustedB) return 1
					return 0
				})
				.sort((a, b) => {
					// Then push bots with no returns to the end
					const hasReturnsA = a.bot_details.cumulative_returns.length > 0
					const hasReturnsB = b.bot_details.cumulative_returns.length > 0
					if (hasReturnsA && !hasReturnsB) return -1
					if (!hasReturnsA && hasReturnsB) return 1
					return 0
				})
		}

		// 3) Split them by role (portfolio vs. trader)
		const portfolioBots = sortedAndGroupedBots(filteredBots.filter(bot => bot.role === 'portfolio'))
		const traderBots = sortedAndGroupedBots(filteredBots.filter(bot => bot.role === 'trader'))

		return { portfolio: portfolioBots, trader: traderBots }
		// Make sure to include the form values in the dependency array
	}, [arenaBotsData, selectedStrategies, selectedTickers, selectedBotIds])

	const { isLoading } = useQuery({
		queryKey: ['mavbots-arena', 'mavbots'],
		queryFn: () => fetchBotStats(accessToken),
		staleTime: 24 * 60 * 60 * 1000 // 24 hours
	})

	console.log('arenaBotsData', arenaBotsData)
	console.log('groupedBots', groupedBots)

	return (
		<FormProvider {...methods}>
			<Box className={styles.meetBotsContainer}>
				<Card
					title="Meet the bots"
					headerChildren={
						<Box display="flex" gap={2}>
							<BotFilter<'selectedBotIds'> title="Bots" options={botOptions} field="selectedBotIds" />
							<BotFilter<'selectedStrategies'>
								title="Strategy"
								options={strategies.map(strategy => ({ label: strategy, value: strategy }))}
								field="selectedStrategies"
							/>
							<BotFilter<'selectedTickers'>
								title="Ticker"
								options={tickers.map(ticker => ({ label: ticker, value: ticker }))}
								field="selectedTickers"
							/>
						</Box>
					}
					isLoading={isLoading}
					style={{ minHeight: 'auto' }}
					bodyStyle={{
						marginLeft: '-12px',
						marginRight: '-12px',
						paddingLeft: '24px',
						paddingRight: '24px'
					}}>
					<Box className={styles.botsWrapper}>
						{groupedBots.portfolio.length > 0 && renderBots(groupedBots.portfolio)}
						{groupedBots.portfolio.length !== 0 && groupedBots.trader.length !== 0 && (
							<Box className={styles.divider}></Box>
						)}
						{groupedBots.trader.length > 0 && renderBots(groupedBots.trader)}
						{isLoading && (
							<Box display="flex" justifyContent="center" alignItems="center" style={{ width: '100%' }}>
								<Text variant="body1" color="neutral.500" textAlign="center">
									Loading...
								</Text>
							</Box>
						)}
						{groupedBots.portfolio.length === 0 && groupedBots.trader.length === 0 && !isLoading && (
							<Box display="flex" justifyContent="center" alignItems="center" style={{ width: '100%' }}>
								<Text variant="body1" color="neutral.500" textAlign="center">
									No Results
								</Text>
							</Box>
						)}
					</Box>
				</Card>
			</Box>
		</FormProvider>
	)
}
