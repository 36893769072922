import { useState, useEffect } from 'react'
import clsx from 'clsx'
import { useLocation } from 'react-router-dom'
import { BellIcon, PanelCalendarIcon, PanelLeaderboardIcon } from 'assets/icons/icons'
import { Box } from 'components/layout/box'
import * as styles from './PanelTop.css'
import { TopReflection } from './TopReflection'
import { Calendar } from './Calendar'
import { TopBackgroundReflection } from './TopBackgroundReflection'
import { Leaderboard } from './Leaderboard'
import { ArenaProvider } from 'context/ArenaContext'
import { PanelType } from '../RightSidePanel'
import { Notifications } from './Notifications'
import { useMainAppContext } from 'context/MainAppContext'

interface Props {
	expandedPanel: PanelType | null
	expanded: boolean
	onToggle: any
	setExpandedPanel: (panel: PanelType | null) => void
}


export const PanelTop = ({ expanded, onToggle, expandedPanel, setExpandedPanel }: Props) => {
	const [transitionClass, setTransitionClass] = useState('')
	const { notifications } = useMainAppContext()
	const location = useLocation()

	const unreadCount = notifications.filter(notification => !notification.is_read).length

	useEffect(() => {
		if (expanded) {
			setTransitionClass(styles.contentBoxClosing)
		} else {
			setTransitionClass(styles.contentBoxOpening)
		}
	}, [expanded])

	useEffect(() => {
		// Close the panel whenever the route changes
		if (expanded) {
			setExpandedPanel(null)
		}
	}, [location.pathname]) // Listen for changes in the route

	return (
		<Box className={styles.panelTop}>
			<Box className={clsx(styles.contentBox, expanded && styles.contentBoxExpanded, transitionClass)}>
				<Box className={styles.topReflection}>
					<TopReflection />
				</Box>
				<Box className={styles.topBackgroundReflection}>
					<TopBackgroundReflection />
				</Box>
				<Box style={{ pointerEvents: 'all', display: 'flex', height: '100%' }}>
					{(() => {
						switch (expandedPanel) {
							case 'leaderboard':
								return (
									<ArenaProvider>
										<Leaderboard />
									</ArenaProvider>
								)
							case 'notifications':
								return <Notifications setExpandedPanel={setExpandedPanel} />
							default:
								return <Calendar />
						}
					})()}
				</Box>
			</Box>
			<Box style={{ pointerEvents: 'none' }}>
				{/* <svg
					xmlns="http://www.w3.org/2000/svg"
					width="144"
					height="83"
					viewBox="0 0 144 83"
					fill="none"
					style={{ zIndex: 2 }}>
					<path
						d="M34.0661 2.0084H2.0084C1.45148 2.0084 1 1.55693 1 1H143V82C142.448 82 142 81.5523 142 81V62.3548C142 51.6655 133.335 43 122.645 43H75.0577C56.8004 43 42 28.1995 42 9.94226C42 5.56051 38.4479 2.0084 34.0661 2.0084Z"
						fill="url(#paint0_radial_1607_1082)"
						fillOpacity="0.4"
						stroke="url(#border)"
						strokeWidth="2"
					/>
					<defs>
						<radialGradient
							id="paint0_radial_1607_1082"
							cx="0"
							cy="0"
							r="1"
							gradientUnits="userSpaceOnUse"
							gradientTransform="translate(1 5.05) rotate(0.532156) scale(142.207 63.7763)">
							<stop offset="0.0001" stopColor="#D9D9D9" />
							<stop offset="0.0002" stopColor="#D9D9D9" />
							<stop offset="1" stopColor="#D9D9D9" stopOpacity="0.01" />
						</radialGradient>
						<linearGradient id="border" x1="25.1685" y1="1" x2="100.988" y2="104.239" gradientUnits="userSpaceOnUse">
							<stop stopColor="#252525" />
							<stop offset="0.0334989" stopColor="#23D27E" />
							<stop offset="0.197086" stopColor="#23D27E" />
							<stop offset="0.241275" stopColor="#23D27E" />
							<stop offset="0.317125" stopColor="#B7DCDA" />
							<stop offset="0.45" stopColor="#131212" />
							<stop offset="0.532142" stopColor="#23D27E" stopOpacity="0.29" />
							<stop offset="0.72" stopColor="#147647" stopOpacity="0.605423" />
							<stop offset="0.955" stopColor="#020202" />
						</linearGradient>
						<linearGradient id="greenLight" x1="94" y1="0" x2="72" y2="43" gradientUnits="userSpaceOnUse">
							<stop offset="0.1" stopColor="transparent" />
							<stop offset="0.35" stopColor="#23D27E" stopOpacity="0.9" />
							<stop offset="0.65" stopColor="#23D27E" stopOpacity="0.9" />
							<stop offset="0.9" stopColor="transparent" />
						</linearGradient>
					</defs>
					<path d="M90 0V43" stroke="url(#greenLight)" strokeWidth="2" />
				</svg> */}

				<svg
					xmlns="http://www.w3.org/2000/svg"
					width="227"
					height="110"
					viewBox="0 0 227 110"
					fill="none"
					style={{ zIndex: 2 }}>
					<path
						d="M55.6495 1.83082H2.34779C1.60343 1.83082 1 1.2274 1 0.483032H227V108.744C226.121 108.744 225.408 108.032 225.408 107.153V87.4226C225.408 70.4099 211.617 56.6184 194.604 56.6184H110.437C86.0352 56.6184 66.2535 36.8368 66.2535 12.4349C66.2535 6.57842 61.5059 1.83082 55.6495 1.83082Z"
						fill="url(#paint0_linear_8347_3123)"
						fillOpacity="0.5"
						style={{ boxShadow: '-10px -10px 20px 0px rgba(103, 213, 134, 0.60) inset, 10px 10px 20px' }}
					/>
					<path
						d="M55.6495 1.83082H2.34779C1.60343 1.83082 1 1.2274 1 0.483032H227V108.744C226.121 108.744 225.408 108.032 225.408 107.153V87.4226C225.408 70.4099 211.617 56.6184 194.604 56.6184H110.437C86.0352 56.6184 66.2535 36.8368 66.2535 12.4349C66.2535 6.57842 61.5059 1.83082 55.6495 1.83082Z"
						fill="url(#paint1_linear_8347_3123)"
						fillOpacity="0.9"
						style={{ mixBlendMode: 'overlay' }}
					/>
					<path
						d="M55.6495 1.83082H2.34779C1.60343 1.83082 1 1.2274 1 0.483032H227V108.744C226.121 108.744 225.408 108.032 225.408 107.153V87.4226C225.408 70.4099 211.617 56.6184 194.604 56.6184H110.437C86.0352 56.6184 66.2535 36.8368 66.2535 12.4349C66.2535 6.57842 61.5059 1.83082 55.6495 1.83082Z"
						stroke="url(#paint2_linear_8347_3123)"
					/>
					<defs>
						<linearGradient
							id="paint0_linear_8347_3123"
							x1="227.074"
							y1="54.6136"
							x2="15.1089"
							y2="4.13822"
							gradientUnits="userSpaceOnUse">
							<stop stopColor="#479975" stopOpacity="0" />
							<stop offset="1" stopColor="#67D586" />
						</linearGradient>
						<linearGradient
							id="paint1_linear_8347_3123"
							x1="114"
							y1="-24.3268"
							x2="114"
							y2="108.744"
							gradientUnits="userSpaceOnUse">
							<stop stopColor="#67D586" />
							<stop offset="0.403918" stopColor="#67D586" stopOpacity="0" />
						</linearGradient>
						<linearGradient
							id="paint2_linear_8347_3123"
							x1="39.4653"
							y1="0.483033"
							x2="134.368"
							y2="154.359"
							gradientUnits="userSpaceOnUse">
							<stop stopColor="#252525" />
							<stop offset="0.0334989" stopColor="#23D27E" />
							<stop offset="0.197086" stopColor="#23D27E" />
							<stop offset="0.241275" stopColor="#23D27E" />
							<stop offset="0.317125" stopColor="#B7DCDA" />
							<stop offset="0.45" stopColor="#131212" />
							<stop offset="0.532142" stopColor="#23D27E" stopOpacity="0.29" />
							<stop offset="0.72" stopColor="#147647" stopOpacity="0.605423" />
							<stop offset="0.955" stopColor="#020202" />
						</linearGradient>
					</defs>
				</svg>
				<Box className={styles.rightIconWrapper} onClick={() => onToggle('leaderboard')}>
					<PanelLeaderboardIcon />
				</Box>
				<Box className={styles.middleIconWrapper} onClick={() => onToggle('calendar')}>
					<PanelCalendarIcon />
				</Box>
				<Box className={styles.leftIconWrapper} onClick={() => onToggle('notifications')}>
					{unreadCount > 0 && <Box className={styles.notificationNumber}>{unreadCount}</Box>}

					<BellIcon />
				</Box>
			</Box>
		</Box>
	)
}
