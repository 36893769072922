import { ReactNode } from 'react'

import { Box } from 'components/layout/box'

import { container, glassWrapper, topReflection } from './Container.css'
import { GlassIcon } from '../GlassIcon'
import { TopReflectionIcon } from '../TopReflectionIcon'
import { Text } from 'components/typography/text'

interface Props extends React.HTMLAttributes<any> {
	children: ReactNode
	size?: keyof typeof container
}

export const Container = ({ children, size = 'large', ...rest }: Props) => {
	return (
		<Box className={container[size]} {...rest}>
			<Box className={`${glassWrapper} ${topReflection}`}>
				<TopReflectionIcon />
			</Box>
			<Box className={glassWrapper}>
				<GlassIcon />
			</Box>
			{children}
		</Box>
	)
}
