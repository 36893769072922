import React from 'react'
import * as Dialog from '@radix-ui/react-dialog'
import * as styles from './MobileCalendarDialog.css'
import { IconButton } from 'components/inputs/icon-button'
import { BlockIcon } from 'components/icons/block-icon'
import { CloseIcon, PanelCalendarIcon } from 'assets/icons/icons'
import { Text } from 'components/typography/text'
import { Box } from 'components/layout/box'

import clsx from 'clsx'
import { TabsProvider } from 'components/Tabs/TabsProvider'
import { Tabs } from 'components/Tabs'
import { EconomicMobile } from './EconomicMobile'
import { EarningMobile } from './EarningMobile'

export const MobileCalendarDialog = () => {
	return (
		<Dialog.Root>
			<Dialog.Trigger asChild>
				<IconButton variant="secondaryModern" size="small" className={styles.hamburgerMenuOpen}>
					<BlockIcon icon={PanelCalendarIcon} size="small" color="primary.500" />
				</IconButton>
			</Dialog.Trigger>
			<Dialog.Portal>
				<Dialog.Overlay className={styles.dialogOverlay} />

				<Dialog.Content className={clsx(styles.dialogContent, styles.content)}>
					{/* Header */}
					<Box display="flex" alignItems="center" justifyContent="space-between">
						<Dialog.Title>
							<Text variant="body1Bold">Calendar</Text>
						</Dialog.Title>
						<Dialog.Close asChild>
							<IconButton variant="tertiary" size="small">
								<BlockIcon icon={CloseIcon} size="medium" color="neutral.500" />
							</IconButton>
						</Dialog.Close>
					</Box>
					<Box className={styles.body}>
						<Box>
							<TabsProvider defaultTab="Economic" tabs={['Economic', 'Earnings']}>
								<Tabs>
									<Tabs.Tab value="Economic">Economic</Tabs.Tab>
									<Tabs.Tab value="Earnings">Earnings</Tabs.Tab>
								</Tabs>

								<Tabs>
									<Tabs.Panel value="Economic">
										<EconomicMobile />
									</Tabs.Panel>
									<Tabs.Panel value="Earnings">
										<EarningMobile />
									</Tabs.Panel>
								</Tabs>
							</TabsProvider>
						</Box>
					</Box>
				</Dialog.Content>
			</Dialog.Portal>
		</Dialog.Root>
	)
}
