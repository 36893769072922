import React, { useState, useEffect } from 'react'
import { motion, AnimatePresence } from 'motion/react'
import { Box } from 'components/layout/box'
import * as styles from './Card.css'
import { Text } from 'components/typography/text'
import { IconButton } from 'components/inputs/icon-button'
import { BlockIcon } from 'components/icons/block-icon'
import { InfoIcon } from 'assets/icons/icons'
import { Tooltip } from 'components/Tooltip/Tooltip'

type ContentChild = {
	text: string
}

export interface CardHeaderProps {
	title?: string
	titleAnimate?: string[]
	tooltip?: string | ContentChild[]
	children?: React.ReactNode
	headerStyle?: React.CSSProperties
}

export const CardHeader = ({ title, titleAnimate, tooltip, children, headerStyle }: CardHeaderProps) => {
	const [currentIndex, setCurrentIndex] = useState(0)

useEffect(() => {
	if (!titleAnimate || titleAnimate.length === 0) return

	// Only set up the interval if we haven't reached the last item
	if (currentIndex < titleAnimate.length - 1) {
		const interval = setInterval(() => {
			setCurrentIndex(prevIndex => prevIndex + 1)
		}, 3000)

		return () => clearInterval(interval)
	}
}, [currentIndex, titleAnimate])

	return (
		<Box className={styles.header} style={headerStyle}>
			<Box className={styles.headerContent}>
				<Text variant="body1Bold" style={{ display: 'flex', flexGrow: 1, whiteSpace: 'nowrap', gap: '4px' }}>
					{title ?? ''}{' '}
					{titleAnimate && (
						<div style={{ position: 'relative', overflow: 'hidden', height: '1.5em', flexGrow: '1' }}>
							<AnimatePresence>
								<motion.span
									key={titleAnimate[currentIndex]}
									initial={{ y: -50, opacity: 0 }}
									animate={{ y: 0, opacity: 1 }}
									exit={{ y: 50, opacity: 0 }}
									transition={{ duration: 0.6 }}
									style={{ position: 'absolute' }}>
									<Text variant="body1Bold">{titleAnimate[currentIndex]}</Text>
								</motion.span>
							</AnimatePresence>
						</div>
					)}
				</Text>
				{tooltip && (
					<Tooltip content={tooltip}>
						<div style={{ display: 'inline-block' }}>
							<IconButton variant="tertiary" size="xSmall">
								<BlockIcon icon={InfoIcon} size="medium" />
							</IconButton>
						</div>
					</Tooltip>
				)}
				{children && <Box>{children}</Box>}
			</Box>
			<div className={styles.lineWrapper}>
				<div className={styles.line1}></div>
				<div className={styles.line2}></div>
			</div>
		</Box>
	)
}
