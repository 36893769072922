import React, { createContext, useContext, useState, useEffect, ReactNode } from 'react'
import { connect as connectStream, FeedAPIResponse } from 'getstream'

import logoutUser from 'helpers/logoutUser'
import oktaAuth from 'oktaAuthInstance'
import { CustomGenerics } from 'api/notifications/types'

// Define the context type for MainAppContext
interface MainAppContextType {
	userId: string | undefined
	setUserId: (userId: string) => void
	userGroup: string | undefined
	setUserGroup: (group: string) => void
	isSessionAllowedToShowData: boolean
	setSessionAllowedToShowData: (value: boolean) => void
	isSessionVerifiedAsCurrent: boolean
	setSessionVerifiedAsCurrent: (value: boolean) => void
	// User Guide
	loginCountGuide: number
	setLoginCountGuide: (value: number) => void
	// Loading while extending session
	loadingWhileExtendingSession: boolean
	setLoadingWhileExtendingSession: (value: boolean) => void
	// last graph time
	lastGraphUpdateTime: string
	setLastGraphUpdateTime: (value: string) => void
	// Invalidate user
	invalidateUser: (onlyOkta?: boolean) => void
	// CHAT
	chatId: string | undefined
	setChatId: (chatId: string) => void
	chatToken: string | undefined
	setChatToken: (chatToken: string) => void
	chatName: string
	setChatName: (chatName: string) => void
	avatarId: string | undefined
	setAvatarId: (avatarId: string) => void
	chatNameUpdated: boolean
	setChatNameUpdated: (value: boolean) => void
	chatAvatarBackground: string
	setChatAvatarBackground: (value: string) => void
	// FEED
	feedUserId: string | undefined
	setFeedUserId: (feedUserId: string) => void
	feedUserToken: string | undefined
	setFeedUserToken: (feedUserToken: string) => void
	feedNotificationList: string[] | undefined
	setFeedNotificationList: (feedNotificationList: string[]) => void
	feedHomepageList: string[] | undefined
	setFeedHomepageList: (feedHomepageList: string[]) => void
	// NOTIFICATIONS
	notifications: CustomGenerics['notification'][]
	// setNotifications: (notifications: CustomGenerics['notification'][]) => void
	setNotifications: React.Dispatch<React.SetStateAction<CustomGenerics['notification'][]>>
}

const FEED_API_KEY = process.env.REACT_APP_CHAT_API || ''
const APP_ID = process.env.REACT_APP_FEED_APP_ID

const MainAppContext = createContext<MainAppContextType | undefined>(undefined)

// Create a custom hook to use the MainAppContext
export const useMainAppContext = () => {
	const context = useContext(MainAppContext)
	if (!context) {
		throw new Error('useMainAppContext must be used within a MainAppProvider')
	}
	return context
}

interface MainAppProviderProps {
	children: ReactNode
}

export const MainAppProvider = ({ children }: MainAppProviderProps) => {
	const [userId, setUserId] = useState<string | undefined>()
	const [userGroup, setUserGroup] = useState<string | undefined>()
	const [isSessionAllowedToShowData, setSessionAllowedToShowData] = useState<boolean>(
		localStorage.getItem('checked') === 'true' || false
	)
	const [isSessionVerifiedAsCurrent, setSessionVerifiedAsCurrent] = useState<boolean>(false)
	// User Guide
	const [loginCountGuide, setLoginCountGuide] = useState<number>(0)
	// Loading while extending session
	const [loadingWhileExtendingSession, setLoadingWhileExtendingSession] = useState<boolean>(
		localStorage.getItem('tokenUpdating') === 'true'
	)
	// last graph time
	const [lastGraphUpdateTime, setLastGraphUpdateTime] = useState('')
	// CHAT
	const [chatId, setChatId] = useState<string | undefined>()
	const [chatToken, setChatToken] = useState<string | undefined>()
	const [chatName, setChatName] = useState('')
	const [avatarId, setAvatarId] = useState<string | undefined>()
	const [chatNameUpdated, setChatNameUpdated] = useState<boolean>(false)
	const [chatAvatarBackground, setChatAvatarBackground] = useState('linear-gradient(135deg, #f6d365 0%, #fda085 100%)')
	// FEED
	const [feedUserId, setFeedUserId] = useState<string | undefined>()
	const [feedUserToken, setFeedUserToken] = useState<string | undefined>()
	const [feedNotificationList, setFeedNotificationList] = useState<string[] | undefined>()
	const [feedHomepageList, setFeedHomepageList] = useState<string[] | undefined>()
	// NOTIFICATIONS
	const [notifications, setNotifications] = useState<CustomGenerics['notification'][]>([])

	// const oktaAuth = new OktaAuth(oktaAuthConfig)
	const invalidateUser = async (onlyOkta = false) => {
		const token = oktaAuth.getAccessToken()

		const logoutOkta = async () => {
			try {
				await oktaAuth.revokeRefreshToken()
				await oktaAuth.revokeAccessToken()

				// Get the idToken to pass to signOut

				// Get the idToken as an IDToken object
				const idToken = await oktaAuth.tokenManager.get('idToken')

				if (!idToken) {
					console.error('No idToken found in Token Manager')
					// Proceed with signOut without idToken if necessary
					await oktaAuth.signOut({
						postLogoutRedirectUri: `${window.location.origin}/logout`,
						clearTokensAfterRedirect: true
					})
					return
				}

				// Sign out from Okta and end the Okta session
				await oktaAuth.signOut({
					postLogoutRedirectUri: `${window.location.origin}/logout`,
					idToken, // Pass the idToken object
					clearTokensAfterRedirect: true
				})

				// Clear storage and session state after logout
				localStorage.removeItem('checked')
				localStorage.removeItem('email')
				sessionStorage.removeItem('sessionGuide')

				setSessionAllowedToShowData(false)
				setSessionVerifiedAsCurrent(false)
			} catch (error) {
				console.log('Failed to revoke tokens', error)

				// Ensure session state is cleared even if token revocation fails
				localStorage.removeItem('checked')
				localStorage.removeItem('email')
				sessionStorage.removeItem('sessionGuide')

				setSessionAllowedToShowData(false)
				setSessionVerifiedAsCurrent(false)
			}

			// window.location.href = '/login'
		}

		if (onlyOkta) {
			console.log('Logging out from Okta only')
			await logoutOkta()
		} else {
			console.log('Logging out user from backend and Okta')
			try {
				await logoutUser(token)
				console.log('User backend call to logout - success')
			} catch (err) {
				console.error('User backend call to logout - error', err)
			} finally {
				await logoutOkta()
			}
		}
	}

	// Feed
	useEffect(() => {
		const fetchInitialNotifications: () => Promise<void> = async () => {
			if (!feedUserId || !feedUserToken) return

			const client = connectStream<CustomGenerics>(FEED_API_KEY, null, APP_ID, { timeout: 8000 })
			const feed = client.feed('notification', feedUserId, feedUserToken)

			try {
				const response: FeedAPIResponse<CustomGenerics> = await feed.get({ limit: 50 })

				// Collect "notify" activities
				const notifyActivities: CustomGenerics['notification'][] = []
				response.results.forEach(notification => {
					;(notification.activities as CustomGenerics['activity'][]).forEach(activity => {
						if (activity.verb === 'notify') {
							notifyActivities.push({
								...notification,
								is_read: notification.is_read,
								is_seen: notification.is_seen
							} as CustomGenerics['notification'])
						}
					})
				})

				// Update state with sorted and deduplicated notifications
				setNotifications(prev => {
					const newNotifications = [...notifyActivities, ...prev]
					const sortedNotifications = newNotifications.sort(
						(a, b) => new Date(b.updated_at).getTime() - new Date(a.updated_at).getTime()
					)

					const uniqueNotifications: CustomGenerics['notification'][] = []
					const seenIds = new Set()
					for (const notification of sortedNotifications) {
						if (!seenIds.has(notification.id)) {
							uniqueNotifications.push(notification)
							seenIds.add(notification.id)
						}
					}
					return uniqueNotifications
				})
			} catch (err) {
				console.error('Error fetching initial notifications:', err)
			}
		}

		fetchInitialNotifications()
	}, [feedUserId, feedUserToken])

	return (
		<MainAppContext.Provider
			value={{
				userId,
				setUserId,
				userGroup,
				setUserGroup,
				isSessionAllowedToShowData,
				setSessionAllowedToShowData,
				isSessionVerifiedAsCurrent,
				setSessionVerifiedAsCurrent,
				loginCountGuide,
				setLoginCountGuide,
				loadingWhileExtendingSession,
				setLoadingWhileExtendingSession,
				lastGraphUpdateTime,
				setLastGraphUpdateTime,
				invalidateUser,
				// CHAT
				chatId,
				setChatId,
				chatToken,
				setChatToken,
				chatName,
				setChatName,
				avatarId,
				setAvatarId,
				chatNameUpdated,
				setChatNameUpdated,
				chatAvatarBackground,
				setChatAvatarBackground,
				// FEED
				feedUserId,
				setFeedUserId,
				feedUserToken,
				setFeedUserToken,
				feedNotificationList,
				setFeedNotificationList,
				feedHomepageList,
				setFeedHomepageList,
				notifications,
				setNotifications
			}}>
			{children}
		</MainAppContext.Provider>
	)
}
