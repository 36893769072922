import axios from 'axios'

const axiosClient = axios.create()

axiosClient.defaults.headers.common = {
	'Content-Type': 'application/json',
	Accept: 'application/json',
	'Access-Control-Allow-Origin': '*',
	'Access-Control-Allow-Credentials': true,
	'Access-Control-Allow-Headers': 'X-Requested-With,content-type'
}

export type QuoteResponse = {
	text: string
}

export const fetchQuote = async (accessToken: string | undefined): Promise<QuoteResponse> => {
	const response = await axiosClient.get(`${process.env.REACT_APP_API_SERVER}/storage/arena/quotes`, {
		headers: {
			Authorization: `Bearer ${accessToken}`
		}
	})

	return response.data
}
