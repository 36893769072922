import React, { useEffect } from 'react'
import { connect as connectStream, StreamClient, DefaultGenerics } from 'getstream'
import { useMainAppContext } from 'context/MainAppContext'
import { useQueryClient } from '@tanstack/react-query'
import { CustomGenerics } from 'api/notifications/types'

const FEED_API_KEY = process.env.REACT_APP_CHAT_API || ''
const APP_ID = process.env.REACT_APP_FEED_APP_ID

const NotificationListener: React.FC = () => {
	const queryClient = useQueryClient()
	const { feedUserId, feedUserToken, setNotifications } = useMainAppContext()

	useEffect(() => {
		if (!feedUserId || !feedUserToken) return

		const client: StreamClient<DefaultGenerics> = connectStream(FEED_API_KEY, null, APP_ID, { timeout: 8000 })
		const feed = client.feed('notification', feedUserId, feedUserToken)

		let subscription: { cancel: () => void } | null = null

		// Use an async IIFE for the subscription logic
		;(async () => {
			try {
				subscription = await feed.subscribe(data => {
					console.log(`New activity in notification:${feedUserId}:`, data)

					const newNotifications: CustomGenerics['notification'][] = []

					data.new.forEach(activity => {
						if (!activity) return

						if (activity.verb === 'invalidate' && activity.actionKey) {
							// Invalidate React Query cache
							console.log(`Invalidating query key: ${activity.actionKey}`)
							queryClient.invalidateQueries({ queryKey: activity.actionKey })
						} else if (activity.verb === 'notify') {
							if (activity.actor.startsWith('arena')) {
								console.log(`Invalidating query for arena-trades: ${activity.actionKey}`)
								queryClient.invalidateQueries({ queryKey: ['arena-trades'] })
							}
							// Normalize WebSocket activity to match state format
							newNotifications.push({
								id: activity.id,
								group: activity.group || activity.id, // Use activity ID as fallback
								activities: [activity],
								is_read: false, // New notifications are unread by default
								is_seen: false,
								updated_at: activity.time,
								created_at: activity.time
							} as CustomGenerics['notification'])
						}
					})

					// Update centralized notification state
					setNotifications(prev => {
						const combinedNotifications = [...newNotifications, ...prev]
						const sortedNotifications = combinedNotifications.sort(
							(a, b) =>
								new Date(b.updated_at || b.created_at).getTime() - new Date(a.updated_at || a.created_at).getTime()
						)

						// Deduplication
						const uniqueNotifications: CustomGenerics['notification'][] = []
						const seenIds = new Set()
						for (const notification of sortedNotifications) {
							if (!seenIds.has(notification.id)) {
								uniqueNotifications.push(notification)
								seenIds.add(notification.id)
							}
						}

						return uniqueNotifications
					})
				})
			} catch (err) {
				console.error('Error subscribing to feed:', err)
			}
		})()

		// Cleanup subscription on unmount
		return () => {
			if (subscription) {
				subscription.cancel()
			}
		}
	}, [feedUserId, feedUserToken, setNotifications, queryClient])

	return null
}

export default NotificationListener
