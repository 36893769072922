import React from 'react'

import { Box } from 'components/layout/box'
import * as styles from './Avatar.css'

interface AvatarProps {
	avatar: string
}

export const Avatar = ({ avatar }: AvatarProps) => {
	return (
		<Box className={styles.avatar}>
			<Box className={styles.avatarBackground} />
			<Box className={styles.imgWrapper}>
				<img src={avatar} className={styles.img} />
			</Box>
		</Box>
	)
}
