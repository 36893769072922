import React, { useState, useEffect, useRef } from 'react'
import clsx from 'clsx'
import { useNavigate, useLocation } from 'react-router-dom'
import * as Dialog from '@radix-ui/react-dialog'
import * as dialogStyles from './PageDialog.css'
import arjunImg from 'assets/avatars/arjun-nobg.png'
import ingridImg from 'assets/avatars/ingrid-nobg.png'
import geoffreyImg from 'assets/avatars/geoffrey-nobg.png'
import { Text } from 'components/typography/text'
import { Box } from 'components/layout/box'
import { CloseButton } from './components/CloseButton'
import { BlockIcon } from 'components/icons/block-icon'
import { HamburgerTopMenu } from 'assets/icons/icons'

const tabImages = {
	'mavbots-arena': { src: arjunImg, alt: 'mavbot-arjun' },
	roster: { src: ingridImg, alt: 'mavbot-ingrid' },
	'performance-evaluation': { src: geoffreyImg, alt: 'mavbot-julian' }
}

type SidebarItem = {
	id: string
	label: string
	title: string
	botMessage?: string
}

const sidebar: SidebarItem[] = [
	{
		id: 'mavbots-arena',
		label: 'Mavbots Arena',
		title: 'Mavbots Arena',
		botMessage:
			'"The Arena is a live competition of precision and performance. Follow top bots, analyze their strategies, and make data-driven decisions to grow your wealth."'
	},
	{
		id: 'roster',
		label: 'Roster',
		title: 'Roster',
		botMessage:
			'"Each bot is purpose-built to excel in a specific strategy, offering precision and diversity to help you achieve your goals."'
	},
	{
		id: 'performance-evaluation',
		label: 'Performance Evaluation',
		title: 'Performance Evaluation',
		botMessage:
			'"Use backtested data to validate strong strategies, then confirm their real-time effectiveness by analyzing YTD performance and recent trades."'
	}
]
interface Props {
	children: React.ReactNode
	hashId?: string
}

export const MainInfoMavbotsArenaPageDialog = ({ children, hashId }: Props) => {
	const [activeTab, setActiveTab] = useState<SidebarItem>(sidebar[0])
	const [isSidebarOpen, setIsSidebarOpen] = useState(false)

	const navigate = useNavigate()
	const location = useLocation()

	// Track whether it's the initial load
	const isInitialLoad = useRef(true)

	const handleTabClick = (item: SidebarItem) => {
		setActiveTab(item)
		navigate(`#${item.id}`, { replace: true })
		setIsSidebarOpen(false)
	}

	const closeSidebar = () => setIsSidebarOpen(false)

	useEffect(() => {
		if (isInitialLoad.current) {
			// Set initial tab based on hashId or URL hash
			const hash = hashId || location.hash.slice(1)
			const initialTab = sidebar.find(item => item.id === hash) || sidebar[0]
			setActiveTab(initialTab)

			// Sync URL if hashId is provided
			if (hashId) {
				navigate(`#${hashId}`, { replace: true })
			}

			// Mark initial load as complete
			isInitialLoad.current = false
		}
	}, [hashId, location.hash, navigate])
	return (
		<Dialog.Root>
			<Dialog.Trigger asChild>{children}</Dialog.Trigger>
			<Dialog.Portal>
				<Dialog.Overlay className={dialogStyles.pageDialogOverlay} />

				<Dialog.Content className={dialogStyles.pageDialogContent}>
					{/* Sidebar with Overlay */}
					{isSidebarOpen && <div className={dialogStyles.sidebarOverlay} onClick={closeSidebar}></div>}
					<Box
						className={clsx(dialogStyles.sidebar, {
							[dialogStyles.sidebarOpen]: isSidebarOpen
						})}>
						<Box className={dialogStyles.sidebarContent}>
							{sidebar.map(item => (
								<button
									key={item.id}
									className={clsx(dialogStyles.sidebarButton, {
										[dialogStyles.sidebarButtonActive]: activeTab.id === item.id
									})}
									onClick={() => handleTabClick(item)}>
									{item.label}
								</button>
							))}
						</Box>
					</Box>

					<div className={dialogStyles.imageLayer} />
					<Box className={dialogStyles.pageDialogSidebar}>
						<svg
							className={dialogStyles.topShapeSvg}
							xmlns="http://www.w3.org/2000/svg"
							width="100%"
							height="248"
							viewBox="0 0 251 248"
							fill="none"
							preserveAspectRatio="none">
							<g style={{ mixBlendMode: 'color-dodge' }} opacity="0.2" filter="url(#filter1)">
								<path
									d="M189.382 0H0V72V94.802V248C5.9921 155.608 15.3205 64.1882 86.979 64.1882C213.375 64.1882 246.334 12.5764 250.196 5.63211C250.482 5.1183 250.602 5.14282 250.602 4.55489C250.602 2.36235 248.825 0 246.632 0H189.382Z"
									fill="url(#paint)"
								/>
							</g>
							<defs>
								<filter
									id="filter1"
									x="-2.39795"
									y="-3"
									width="256"
									height="254"
									filterUnits="userSpaceOnUse"
									colorInterpolationFilters="sRGB">
									<feFlood floodOpacity="0" result="BackgroundImageFix" />
									<feGaussianBlur in="BackgroundImageFix" stdDeviation="1.5" />
									<feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_5177_9661" />
									<feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_5177_9661" result="shape" />
								</filter>
								<radialGradient
									id="paint"
									cx="0"
									cy="0"
									r="1"
									gradientUnits="userSpaceOnUse"
									gradientTransform="translate(0.602198 -4.17373e-05) scale(246.946 233.412)">
									<stop offset="0.0001" stopColor="#D9D9D9" />
									<stop offset="0.0002" stopColor="#D9D9D9" />
									<stop offset="0.935" stopColor="#D9D9D9" stopOpacity="0" />
									<stop offset="1" stopColor="#010B08" stopOpacity="0" />
								</radialGradient>
							</defs>
						</svg>

						{/* RightBorderSvg */}
						<svg
							className={dialogStyles.rightBorderSvg}
							xmlns="http://www.w3.org/2000/svg"
							width="4px"
							height="100%"
							viewBox="0 0 1 778"
							fill="none">
							<path d="M1 0V778" stroke="url(#linear_gradient)" strokeWidth="1" />
							<defs>
								<linearGradient id="linear_gradient" x1="0" y1="0" x2="1" y2="778" gradientUnits="userSpaceOnUse">
									<stop stopColor="#252525" />
									<stop offset="0.01" stopColor="#23D27E" />
									<stop offset="0.20" stopColor="#23D27E" />
									<stop offset="0.24" stopColor="#23D27E" />
									<stop offset="0.32" stopColor="#B7DCDA" />
									<stop offset="0.45" stopColor="#131212" />
									<stop offset="0.532142" stopColor="#23D27E" stopOpacity="0.29" />
									<stop offset="0.72" stopColor="#147647" stopOpacity="0.6" />
									<stop offset="1" stopColor="#020202" />
								</linearGradient>
							</defs>
						</svg>

						<Box className={dialogStyles.buttonsWrapper}>
							{sidebar.map(item => (
								<button
									key={item.id}
									className={clsx(dialogStyles.sidebarButton, {
										[dialogStyles.sidebarButtonActive]: activeTab.id === item.id
									})}
									onClick={() => handleTabClick(item)}>
									{item.label}
								</button>
							))}
						</Box>
					</Box>

					<Box className={dialogStyles.pageDialogMain}>
						{/* Header */}
						<Box className={dialogStyles.headerWrapper} padding={4}>
							{/* Sidebar Trigger for Mobile */}
							<div>
								<Box onClick={() => setIsSidebarOpen(true)} className={dialogStyles.menuIcon}>
									<BlockIcon icon={HamburgerTopMenu} size="small" color="primary.500" />
								</Box>
							</div>
							<Dialog.Title>
								<Text className={dialogStyles.mainTitle} as="h1" variant="body1Bold">
									{activeTab.title}
								</Text>
							</Dialog.Title>
							<Dialog.Close asChild style={{ justifySelf: 'end' }}>
								<CloseButton />
							</Dialog.Close>
						</Box>
						{/* BODY */}
						<Box className={dialogStyles.pageDialogBody}>
							{/* Scrollable Content */}
							<Box className={dialogStyles.pageDialogBodyContent}>
								<PageDialogBodyContent type={activeTab.id}></PageDialogBodyContent>
							</Box>
							{/* Footer */}
							<Box className={dialogStyles.pageDialogBodyFooter}>
								<Box display="flex" alignItems="center" gap={4} className={dialogStyles.footerContent}>
									<Box className={dialogStyles.imgWrapper}>
										{tabImages[activeTab.id] && (
											<img
												className={dialogStyles.botAvatar}
												src={tabImages[activeTab.id].src}
												alt={tabImages[activeTab.id].alt}
											/>
										)}
									</Box>
									{activeTab.botMessage && (
										<Box className={dialogStyles.botMessage}>
											<Text variant="body1" fontStyle="italic">
												{activeTab.botMessage}
											</Text>
										</Box>
									)}
								</Box>
							</Box>
						</Box>
					</Box>
				</Dialog.Content>
			</Dialog.Portal>
		</Dialog.Root>
	)
}

interface PageDialogBodyContentProps {
	type: string
}
const PageDialogBodyContent = ({ type }: PageDialogBodyContentProps) => {
	const renderContent = () => {
		switch (type) {
			case 'mavbots-arena':
				return (
					<Box as="article" className={dialogStyles.pageDialogBodyContentWrapper}>
						<Box className={dialogStyles.articleSection}>
							<Text as="h2" variant="h2" className={dialogStyles.articleHeader}>
								MAVBOTS ARENA: A New Standard For Performance Transparency
							</Text>
							<Text variant="body1" color="neutral.300">
								The MavBots Arena is a live battleground where AI portfolio managers and traders showcase their
								strategies in action. Here, every bot’s performance is ranked on a leaderboard based on Year-to-Date
								(YTD) returns, giving you unparalleled transparency into their results. Whether you’re tracking
								top-performing bots or evaluating their adaptability to real-time market conditions, the Arena provides
								a dynamic, data-driven view of your MavBots team.
							</Text>
						</Box>
						<Box className={dialogStyles.articleSection}>
							<Text as="h2" variant="h2" className={dialogStyles.articleHeader}>
								Why It Matters
							</Text>
							<ul className={dialogStyles.ulList}>
								<Text as="li" variant="body1" color="neutral.300">
									Transparency Redefined: Each bot’s live trading decisions are directly linked to its YTD performance,
									ensuring every move is measurable and results-driven.
								</Text>
								<Text as="li" variant="body1" color="neutral.300">
									Live Competition: MavBots continuously adapt, trade, and improve in a competitive environment,
									ensuring only the strongest strategies rise to the top.
								</Text>
							</ul>
						</Box>
						<Box className={clsx(dialogStyles.articleSection, dialogStyles.proTipSection)}>
							<Text as="h2" variant="h2" className={dialogStyles.articleHeader}>
								💡 Use the leaderboard to track consistent top performers and uncover strategies that align with your
								goals.
							</Text>
						</Box>
					</Box>
				)
			case 'roster':
				return (
					<Box as="article" className={dialogStyles.pageDialogBodyContentWrapper}>
						<Box className={dialogStyles.articleSection}>
							<Text as="h2" variant="h2" className={dialogStyles.articleHeader}>
								ROSTER: Meet the Bots
							</Text>
							<Text variant="body1" color="neutral.300">
								The Roster introduces you to your entire team of AI portfolio managers and traders. Each bot is designed
								to excel in a specific strategy, targeting opportunities across asset classes, sectors, and themes.
							</Text>
						</Box>
						<Box className={dialogStyles.articleSection}>
							<Text as="h2" variant="h2" className={dialogStyles.articleHeader}>
								Portfolio Managers (Yellow):
							</Text>
							<Text variant="body1" color="neutral.300">
								Designed for long-term growth, these bots craft diversified portfolios tailored to stability, risk
								tolerance, and trends.
							</Text>
						</Box>

						<Box className={dialogStyles.articleSection}>
							<Text as="h2" variant="h2" className={dialogStyles.articleHeader}>
								Traders (Green):
							</Text>
							<Text variant="body1" color="neutral.300">
								Traders specialize in single ticker trades, serving as the execution layer for portfolio managers'
								strategies.
							</Text>
						</Box>
						<Box className={dialogStyles.articleSection}>
							<Text as="h2" variant="h2" className={dialogStyles.articleHeader}>
								For Every Bot, See:
							</Text>
							<ul className={dialogStyles.ulList}>
								<Text as="li" variant="body1" color="neutral.300">
									<strong>What They Trade:</strong> The asset classes, sectors, themes, and individual companies they
									specialize in.
								</Text>
								<Text as="li" variant="body1" color="neutral.300">
									<strong>Their Strategy:</strong> Clear explanations of their goal, trading logic, and risk management.
								</Text>
								<Text as="li" variant="body1" color="neutral.300">
									<strong>Backtested Performance:</strong> Historical results that demonstrate how their strategies
									performed over time.
								</Text>
							</ul>
						</Box>
					</Box>
				)
			case 'performance-evaluation':
				return (
					<Box as="article" className={dialogStyles.pageDialogBodyContentWrapper}>
						<Box className={dialogStyles.articleSection}>
							<Text as="h2" variant="h2" className={dialogStyles.articleHeader}>
								PERFORMANCE EVALUATION: From Strategy to Real-World Results
							</Text>
							<Text variant="body1" color="neutral.300">
								MavBots Arena equips you with the tools to evaluate both historical and real-time bot performance. By
								combining backtesting results with current performance metrics, you gain a complete picture of how each
								bot performs and whether its strategy aligns with your goals.
							</Text>
						</Box>

						<Box className={dialogStyles.articleSection}>
							<Text as="h2" variant="h2" className={dialogStyles.articleHeader}>
								Backtesting Widgets
							</Text>
							<Text variant="body1" color="neutral.300">
								These high-level widgets summarize each bot’s backtested performance starting from January 2013 (or as
								early as the asset’s data allows), using a $100,000 portfolio benchmarked against the S&P 500 (ETF:
								SPY).
							</Text>
							<ul className={dialogStyles.ulList}>
								<Text as="li" variant="body1" color="neutral.300">
									<strong>Current Value:</strong> Tracks portfolio growth since the backtest start date.
								</Text>
								<Text as="li" variant="body1" color="neutral.300">
									<strong>Total Return Percentage:</strong> See overall growth compared to SPY’s total return over the
									same period.
								</Text>
								<Text as="li" variant="body1" color="neutral.300">
									<strong>Risk-Adjusted Return (Sortino Ratio):</strong> Evaluate performance based on downside risk,
									with a comparison to SPY’s Sortino Ratio.
								</Text>
								<Text as="li" variant="body1" color="neutral.300">
									<strong>Risk Rating:</strong> Based on max drawdown, offering insight into the bot’s stability
									relative to SPY.
								</Text>
							</ul>
						</Box>

						<Box className={dialogStyles.articleSection}>
							<Text as="h2" variant="h2" className={dialogStyles.articleHeader}>
								Summary Stats Table
							</Text>
							<Text variant="body1" color="neutral.300">
								Dive deeper into detailed performance metrics across two key timeframes — 5 years and 10 years:
							</Text>
							<ul className={dialogStyles.ulList}>
								<Text as="li" variant="body1" color="neutral.300">
									<strong>Performance:</strong> See total return and risk-adjusted return over each period.
								</Text>
								<Text as="li" variant="body1" color="neutral.300">
									<strong>Win Rate:</strong> Percentage of successful trades.
								</Text>
								<Text as="li" variant="body1" color="neutral.300">
									<strong>Average Win and Loss:</strong> Gain insight into the size of gains vs. losses.
								</Text>
								<Text as="li" variant="body1" color="neutral.300">
									<strong>Max Drawdown:</strong> The largest observed decline from a peak during the time period.
								</Text>
								<Text as="li" variant="body1" color="neutral.300">
									<strong>Sortino Ratio:</strong> Measure of risk-adjusted returns focusing on downside risk.
								</Text>
								<Text as="li" variant="body1" color="neutral.300">
									<strong>Risk Rating:</strong> Stability of the bot compared to SPY during volatile conditions.
								</Text>
							</ul>
						</Box>

						<Box className={dialogStyles.articleSection}>
							<Text as="h2" variant="h2" className={dialogStyles.articleHeader}>
								How It Works
							</Text>
							<ul className={dialogStyles.ulList}>
								<Text as="li" variant="body1" color="neutral.300">
									Use backtested results from the widgets to validate each bot’s long-term performance.
								</Text>
								<Text as="li" variant="body1" color="neutral.300">
									Analyze detailed summary stats to understand the consistency and quality of the bot’s trades.{' '}
								</Text>
								<Text as="li" variant="body1" color="neutral.300">
									Compare all bots, both against SPY and each other, to find the best fit for your risk tolerance and
									goals.
								</Text>
							</ul>
						</Box>

						<Box className={clsx(dialogStyles.articleSection, dialogStyles.proTipSection)}>
							<Text as="h2" variant="h2" className={dialogStyles.articleHeader}>
								💡 Why the Sortino Ratio Matters
							</Text>
							<Text variant="body1" color="neutral.300">
								The Sortino Ratio, used to measure risk-adjusted return, prioritizes downside risk over overall
								volatility. Unlike traditional metrics like the Sharpe Ratio, it focuses on what truly impacts you:
								losses. By comparing performance to downside deviations, it gives you a clearer picture of consistent,
								reliable growth.
							</Text>
						</Box>
					</Box>
				)
			case 'faq':
				return <Box as="article" className={dialogStyles.pageDialogBodyContentWrapper}></Box>
		}
	}

	return <div>{renderContent()}</div>
}
