import {
	MarketIcon,
	MavBotsIcon,
	DecoderIcon,
	HerdIcon,
	NewsIcon,
	MenuDotsIcon,
	NavigatorIcon,
	PulseIcon,
	NexusIcon,
	SifterIcon,
	TrendingIcon,
	HerdPowerIcon
} from 'assets/icons/icons'

export type SubNavItem = {
	to: string
	label: string
	isOpen?: boolean
	icon?: any
}

export type MenuItem = {
	to?: string
	label: string
	mobileLabel: string
	icon?: any
	subNav: SubNavItem[]
}

export const menuList: MenuItem[] = [
	{
		to: '/mavbots-arena',
		label: 'MavBots Arena',
		mobileLabel: 'MavBots',
		icon: MavBotsIcon,
		subNav: []
	},
	{
		to: '/state-of-market',
		label: 'State of Market',
		mobileLabel: 'Market',
		icon: MarketIcon,
		subNav: []
	},
	{
		label: 'Decoder',
		mobileLabel: 'Decoder',
		icon: DecoderIcon,
		subNav: [
			{
				to: '/decoder/navigator',
				label: 'Navigator',
				icon: NavigatorIcon
			},
			{
				to: 'decoder/sifter',
				label: 'Sifter',
				icon: SifterIcon
			}
		]
	},
	{
		label: 'Herd',
		mobileLabel: 'Herd',
		icon: HerdIcon,
		subNav: [
			{
				to: 'herd/herd-pulse',
				label: 'Herd Pulse',
				icon: PulseIcon
			},
			{
				to: 'herd/trending',
				label: 'Trending',
				icon: TrendingIcon
			},
			{
				to: 'herd/herdpower',
				label: 'HerdPower®',
				icon: HerdPowerIcon
			}
		]
	},
	{
		to: '/news',
		icon: NewsIcon,
		label: 'News',
		mobileLabel: 'News',
		subNav: []
	},
	{
		to: '/nexus',
		icon: NexusIcon,
		label: 'Nexus',
		mobileLabel: 'Nexus',
		subNav: []
	},
	{
		label: 'More',
		icon: MenuDotsIcon,
		mobileLabel: 'More',
		subNav: [
			{ to: '/watchlist', label: 'Watchlist' },
			{ to: '/nexus', label: 'Nexus', icon: NexusIcon }
		]
	}
]
