import axios from 'axios'

export const createFeedUser = async (token: string | undefined) => {
	try {
		const response = await axios.post(
			`${process.env.REACT_APP_API_SERVER}/okta/create-feed-user`,
			{},
			{
				headers: {
					Authorization: `Bearer ${token}`
				}
			}
		)

		if (response.status === 200) {
			console.log('Feed user created or already exists:', response.data)

			// Return the chat details
			return {
				feedUserId: response.data.feed_user_id,
				feedUserToken: response.data.feed_user_token,
				feedNotificationList: response.data.feed_notification_list,
				feedHomepageList: response.data.feed_homepage_list
			}
		} else {
			console.error('Failed to create chat user:', response.data.message)
			return null
		}
	} catch (error) {
		console.error('Error creating chat user:', error)
		return null
	}
}

export const addNotification = async (activity: object) => {
	try {
		const response = await axios.post(
			`${process.env.REACT_APP_API_SERVER}/okta/create-notification`,
			{ activity }, // Pass the activity as part of the request body
	
		)

		if (response.status === 200) {
			console.log('Notification created successfully:', response.data)

			// Return the response data if needed
			return {
				feedToken: response.data.feed_token,
				activity: response.data.activity
			}
		} else {
			console.error('Failed to create notification:', response.data.message)
			return null
		}
	} catch (error) {
		console.error('Error creating notification:', error)
		return null
	}
}