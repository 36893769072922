import ava from 'assets/avatars/ava.jpg'
import cody from 'assets/avatars/cody.jpg'
import geoffrey from 'assets/avatars/geoffrey.jpg'
import jannik from 'assets/avatars/jannik.jpg'
import julian from 'assets/avatars/julian.jpg'
import kaya from 'assets/avatars/kaya.jpg'
import marco from 'assets/avatars/marco.jpg'
import max from 'assets/avatars/max.jpg'
import sophie from 'assets/avatars/sophie.jpg'
import alex from 'assets/avatars/alex.jpg'

interface Notification {
	actor: string
	avatar: string
	link: string
}

export const notification: Notification[] = [
	{
		actor: 'news_sophie',
		avatar: sophie,
		link: '/news'
	},
	{
		actor: 'herd_alex',
		avatar: alex,
		link: '/herd/herd-pulse'
	},
	{
		actor: 'som_julian',
		avatar: julian,
		link: '/state-of-market'
	},
	{
		actor: 'arena_max',
		avatar: max,
		link: '/mavbots-arena'
	},
	{
		actor: 'arena_marco',
		avatar: marco,
		link: '/mavbots-arena'
	},
	{
		actor: 'arena_kaya',
		avatar: kaya,
		link: '/mavbots-arena'
	},
	{
		actor: 'arena_jannik',
		avatar: jannik,
		link: '/mavbots-arena'
	},
	{
		actor: 'arena_cody',
		avatar: cody,
		link: '/mavbots-arena'
	},
	{
		actor: 'arena_ava',
		avatar: ava,
		link: '/mavbots-arena'
	},
	{
		actor: 'arena_geoffrey',
		avatar: geoffrey,
		link: '/mavbots-arena'
	}
]
