import "src/style/theme.css.ts.vanilla.css!=!../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"src/style/theme.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA3WWWW7bMBCG33sKAUaABKhTcri7T5Il3iOLszmxXWdzUvTunaEDlEOyEmzDH0aj2X6Si/12+9L9/tZ18/lzOKzXO7HoxM/sv8T/52D2q6ecAtESqkUnC6QRVXYGoRAnObKIdoecuPQkZ37RASdh0c0gXTm9QBoF3Tm9RDr0Q1yGnF4V+V4vOmd9TlYYCXhmdIPIaoZuMTZjGbqjMHS0ccjpPdJxGKdJ5fShGfK6GfIj0l7RndMnpMGH2E853SA1I9053SLVWveGJbqjYgaIitn+IipBA8tiv+j2t5cXp0p975T93mn8xTkRZ7nRc9NIG2b00jSy3NNr08hzo7emUeBG71TodOX0gHSKEbuS0w9K3fcyDoqV+pPaokPPfUiUzswPFgqMCppZNxpvGUYJzYTQ3hmGFb3TBa051tSxHqbAuiBRSjM1gvbc2lKSgNO0ZNjR4ECQRYAoq5kLVkYmIhnSK5UdOP4S1xg1w6SucRmXk2P4iko1RDfyReWaSmWn2PMAV1+l6tkEyps2RsnNpO2V7hkm2UnjBR9jeZ+srVE8bhKe1EYBt14fx8RPvLCPRyyXPO6nhCdfBLhJeOwdD3Cb8HLQvFQkv2h6kHxOSH9jcE5wvE9tMJPhAT7TKgBaCDbI8oVa7JURvPOvx3Rs5E7ejnHHkafznkTSx57P4CEtc8F47vsjOQk68Hp/NjGQdjAXq9krQTazBNIONljwNQlIO3qUo2T1Bt2UPJg2ts0tBVA7c9y1GPPF3gEh7WwMoVw2282KMdSK2B3SR1r8muOuRosBXZLX5eqfKejCFNiuANdp+TsVHd0/0PPJWXpO4ccefTD7VRn9TXurv23t9XBH1FX4vj4DwANt5bXfdetsAI9Ea7+oMCgZygtqB6guVTKUli4Z6qp6FkVlS4aK8iV7oT6X8JXOCSV8ozLJKqB3wqpO80Dc1rX6+A//bHZB0SHu3Ndc1t1R0OyOUq3uqHSeq+NQpm6PstSe2tS1uqZQSporR6GYbIFQTK5AqCVfINRMKBBqIxiOVvUpVNG5ThUMx18aYn/+Aus/j0EtCwAA\"}!../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
import "src/components/typography/main-title/MainTitle.css.ts.vanilla.css!=!../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"src/components/typography/main-title/MainTitle.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA41UwY6bMBC95yss7SWRcAQE0pRc2sPm1J8weAA3YCPbSdit8u8dG8jCNq0qhYQ8zzw/v5nxlpV1l0NIfq0I4cJ0DXvLSKUFPyLAGlFJKiy0JiMFSAvawW6ZIojBFmihmksrMSAqNWEXq9zLcXVfbQfuaMldNtD/g/vnxVhRviErItJmxHSsAJqDvQHIGW3saUsMo0a8A26/jeJUQ3uc4BuIqkaGJAwd1ggJtB4xqXTLGgejfKUzcmV6Tan52p/PXb1xCxZ6S61m0pQYnJFL14EumIHHoqkZV7eMhF3vn3iHX7rK17tdQOLoS0BQkedCLflZIJ1Ps1qdgd4EtzXK7vq/RYzSXk6n0/41fZyrZK1o0Mkf0IPk5LVn3hbDSqYFWdjzVGaUDDLZQmdAwq0T+zB45xmmehhoyozgfrOI5HllK9Yt/YyrOW/qszplhBVKYprowXebVZg3lApKO75OLoXh9eb+TwV0QO2AnBXnSquL5JNf/mghnmf4bIcSvFMhOfQZiWda9p+0aMCWFldf4lz1C+uca1E8WTfjjzx/xzgXssrIYShorjQHTTXj4oIdngzoH2a5X8qFhmJQMEzTw8aR7Kn4gxf/cf7MtzjTtHKb4vCso33IoQrIS5Ik39NDMEiP0zSYHpQfbzbPfZyXUPkKfmuBC0ZMoXEWCcPmW7dCPjo5PqBPG69qO79X/vPGIOT+kRmPmYsBn8Z7bGx/hcwvj/vq/hvcm2iv0QQAAA==\"}!../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var actionWrapper = 'afhpbe4';
export var dropdownMenu = 'afhpbe6';
export var hamburgerMenu = 'afhpbe7';
export var hamburgerMenuOpen = 'afhpbe8';
export var iconWrapper = 'afhpbe3';
export var mainTitle = 'afhpbe2';
export var mainTitleWrapperDesktop = 'afhpbe0';
export var mainTitleWrapperMobile = 'afhpbe1';
export var overlay = 'afhpbe5';