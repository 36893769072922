import React from 'react'

import { Box } from 'components/layout/box'
import * as styles from './Roster.css'
import { BotProfile } from './BotProfile'
import { Quote } from './Quote'
import { MeetBots } from './MeetBots'

export const Roster = () => {
	return (
		<Box className={styles.rosterContainer}>
			<Quote />
			<MeetBots />
			<BotProfile />
		</Box>
	)
}
