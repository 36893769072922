import React from 'react'
import * as Dialog from '@radix-ui/react-dialog'
import { motion } from 'motion/react'

import * as styles from './MobileApproachDialog.css'
import { IconButton } from 'components/inputs/icon-button'
import { BlockIcon } from 'components/icons/block-icon'
import { CloseIcon, PanelApproachIcon } from 'assets/icons/icons'
import { Text } from 'components/typography/text'
import { Box } from 'components/layout/box'

interface Props {}

export const MobileApproachDialog = ({}: Props) => {
	return (
		<Dialog.Root>
			<Dialog.Trigger asChild>
				<IconButton variant="secondaryModern" size="small" className={styles.hamburgerMenuOpen}>
					<BlockIcon icon={PanelApproachIcon} size="small" color="primary.500" />
				</IconButton>
			</Dialog.Trigger>
			<Dialog.Portal>
				<Dialog.Overlay className={styles.dialogOverlay} />

				<Dialog.Content className={styles.dialogContent}>
					{/* Header */}
					<Box display="flex" alignItems="center" justifyContent="space-between" paddingY={2} paddingX={4}>
						<Dialog.Title>
							<Text variant="body1Bold">Mavbots Approach</Text>
						</Dialog.Title>
						<Dialog.Close asChild>
							<IconButton variant="tertiary" size="small">
								<BlockIcon icon={CloseIcon} size="medium" color="neutral.500" />
							</IconButton>
						</Dialog.Close>
					</Box>
					<Box className={styles.body}>
						<motion.div
							initial={{ opacity: 0.4 }}
							animate={{ opacity: 1 }}
							transition={{
								duration: 2,
								ease: 'easeInOut',
								repeat: Infinity,
								repeatType: 'reverse' // Smooth reverse loop
							}}>
							<Text variant="h2" textAlign="center" color="neutral.500" className={styles.comingSoon}>
								Work In Progress
							</Text>
						</motion.div>
					</Box>
				</Dialog.Content>
			</Dialog.Portal>
		</Dialog.Root>
	)
}
