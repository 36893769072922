import { useState, useEffect } from 'react'
import { motion } from 'motion/react'
import clsx from 'clsx'

import { Box } from 'components/layout/box'
import * as styles from './PanelApproach.css'
import { TopReflection } from './TopReflection'
import { TopReflectionBackground } from './TopReflectionBackground'
import { Text } from 'components/typography/text'

interface Props {
	expanded: boolean
	onToggle: () => void
}

export const PanelApproach = ({ expanded, onToggle }: Props) => {
	const [transitionClass, setTransitionClass] = useState('')

	useEffect(() => {
		if (expanded) {
			setTransitionClass(styles.panelApproachClosing)
		} else {
			setTransitionClass(styles.panelApproachOpening)
		}
	}, [expanded])

	return (
		<Box
			style={{ pointerEvents: 'all' }}
			className={clsx(styles.panelApproach, expanded && styles.panelApproachExpanded, transitionClass)}>
			{/* Notch */}
			<svg
				className={styles.notch}
				width="44"
				height="212"
				viewBox="0 0 44 212"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
				onClick={onToggle}>
				<path
					d="M22.0647 41.5819L25.3149 39.0704C34.7619 31.7704 41.152 21.3747 43.5 9.88952L43.5 202.11C41.152 190.625 34.7619 180.23 25.3149 172.93L22.0647 170.418C8.46385 159.908 0.5 143.69 0.5 126.502L0.5 85.4982C0.5 68.31 8.46386 52.0916 22.0647 41.5819Z"
					fill="url(#bg_fill)"
					fillOpacity="0.4"
					stroke="url(#border)"
				/>
				<path
					className={clsx(styles.icon, expanded && styles.iconExpanded)}
					d="M20.8284 106.001L25.7782 110.95L24.364 112.365L18 106.001L24.364 99.6367L25.7782 101.051L20.8284 106.001Z"
					fill="#EFFDEE"
				/>

				<defs>
					{/* Background of Notch */}
					<radialGradient
						id="bg_fill"
						cx="0"
						cy="0"
						r="1"
						gradientUnits="userSpaceOnUse"
						gradientTransform="translate(6.50376e-07 10.6) rotate(4.48589) scale(44.1975 166.417)">
						<stop offset="0.0001" stopColor="#D9D9D9" />
						<stop offset="0.0002" stopColor="#D9D9D9" />
						<stop offset="1" stopColor="#D9D9D9" stopOpacity="0.01" />
					</radialGradient>

					<linearGradient
						id="bg_fill_hover"
						x1="28.1707"
						y1="119.25"
						x2="-89.5827"
						y2="52.0114"
						gradientUnits="userSpaceOnUse">
						<stop stopColor="#2C665E" />
						<stop offset="1" stopColor="#67D586" />
					</linearGradient>

					{/* Border */}
					<linearGradient
						id="border"
						x1="7.48882"
						y1="5.6219e-07"
						x2="72.8419"
						y2="10.5352"
						gradientUnits="userSpaceOnUse">
						<stop stopColor="#252525" />
						<stop offset="0.0334989" stopColor="#23D27E" />
						<stop offset="0.197086" stopColor="#23D27E" />
						<stop offset="0.241275" stopColor="#23D27E" />
						<stop offset="0.317125" stopColor="#B7DCDA" />
						<stop offset="0.45" stopColor="#131212" />
						<stop offset="0.532142" stopColor="#23D27E" stopOpacity="0.29" />
						<stop offset="0.72" stopColor="#147647" stopOpacity="0.605423" />
						<stop offset="0.955" stopColor="#020202" />
					</linearGradient>
				</defs>
			</svg>
			{/* Content */}
			<Box className={styles.contentBox}>
				<Box className={clsx(styles.glassWrapper, styles.topReflection)}>
					<TopReflection />
				</Box>
				<Box className={clsx(styles.glassWrapper)}>
					<TopReflectionBackground />
				</Box>
				<Box as="h2" className={styles.title}>
					Mavbots Approach
				</Box>
				<Box className={styles.panelApproachContent}>
					<motion.div
						initial={{ opacity: 0.4 }}
						animate={{ opacity: 1 }}
						transition={{
							duration: 2,
							ease: 'easeInOut',
							repeat: Infinity,
							repeatType: 'reverse' // Smooth reverse loop
						}}>
						<Text variant="h2" textAlign="center" color="neutral.500" className={styles.comingSoon}>
							Work In Progress
						</Text>
					</motion.div>
				</Box>
			</Box>
		</Box>
	)
}
