const hexColors = [
	'#FF5733', // Vibrant Red-Orange
	'#FF8D33', // Soft Orange
	'#FFC300', // Warm Yellow
	'#A8D5BA', // Muted Green
	'#33FFBD', // Teal Green
	'#33C4FF', // Sky Blue
	'#337BFF', // Bright Blue
	'#9D33FF', // Vivid Purple
	'#FF33A8', // Pink
	'#FF3377', // Coral
	'#FF6F33', // Deep Orange
	'#FFD333', // Golden Yellow
	'#7D7D7D', // Neutral Gray
	'#000000', // Black
	'#2D1E2F' // Dark Plum
]

export function generateAvatarBackground(): string {
	// Generate a random degree value between 1 and 360
	const degValue = Math.floor(Math.random() * 360) + 1

	// Randomly pick two distinct colors from the hexColors array
	const color1 = hexColors[Math.floor(Math.random() * hexColors.length)]
	let color2 = hexColors[Math.floor(Math.random() * hexColors.length)]

	// Ensure color1 and color2 are different
	while (color1 === color2) {
		color2 = hexColors[Math.floor(Math.random() * hexColors.length)]
	}

	// Return the linear-gradient string
	return `linear-gradient(${degValue}deg, ${color1}, ${color2})`
}
