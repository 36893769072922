import React, { useState, useEffect } from 'react'

import { Box } from 'components/layout/box'

import { breakpoints, Breakpoint } from 'style/tokens/breakpoints'
import { MobileSidebar } from './MobileSidebar'
import { DesktopSidebar } from './DesktopSidebar'

export const Sidebar = ({ isAllowed }: { isAllowed: boolean }) => {
	const [breakpoint, setBreakpoint] = useState<Breakpoint>('mobile')

	useEffect(() => {
		const checkBreakpoint = () => {
			if (window.matchMedia(`(min-width: ${breakpoints.desktop}px)`).matches) {
				setBreakpoint('desktop')
			} else {
				setBreakpoint('mobile')
			}
		}

		checkBreakpoint()
		window.addEventListener('resize', checkBreakpoint)

		return () => {
			window.removeEventListener('resize', checkBreakpoint)
		}
	}, [])

	return <Box>{breakpoint === 'mobile' ? <MobileSidebar /> : <DesktopSidebar isAllowed={isAllowed} />}</Box>
}
