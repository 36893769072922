import axios from 'axios'

export const updateSettings = async (
	accessToken: string,
	data: {
		firstName: string
		lastName: string
		chatName: string
		chatAvatarBackground: string
	}
): Promise<void> => {
	try {
		const response = await axios.post(`${process.env.REACT_APP_API_SERVER}/okta/update-user-settings`, data, {
			headers: {
				Authorization: `Bearer ${accessToken}`,
				'Content-Type': 'application/json' // Ensure content type is JSON
			}
		})

		if (response.status === 200) {
			console.log('All good')
		} else {
			console.error('Failed to update user:', response.data.message)
			throw new Error(response.data.message || 'Failed to update user')
		}
	} catch (error) {
		console.error('Error updating user in backend:', error)
		throw error // Rethrow for caller to handle
	}
}
