import { Box } from 'components/layout/box'
import { useState } from 'react'
import { useSpring, animated } from 'react-spring'

export const CloseButton = ({ ...props }) => {
	const [isHovered, setIsHovered] = useState(false)

	const handleMouseEnter = () => {
		setIsHovered(true)
	}

	const handleMouseLeave = () => {
		setIsHovered(false)
	}

	const { color1, color2, color3 } = useSpring({
		color1: isHovered ? '#67D586' : '#21242c00',
		color2: isHovered ? '#67D586' : '#29392A',
		color3: isHovered ? '#29392A' : '#67D586',
		config: { duration: 500 } // Smooth and quick transition
	})

	return (
		<Box {...props} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
			<svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none">
				{/* <!-- Outer Background --> */}
				<g filter="url(#background_filter)">
					<path
						d="M13.1568 39.9516C17.9128 40.2773 22.7573 38.9033 28.0745 37.5191C37.1096 33.9343 41.0612 24.9982 39.7573 18C38.428 13.6575 38.2363 12.7396 36.2867 9.21126C34.3666 5.7101 31.6784 3.11473 27.8678 1.35058C24.0571 -0.440714 19.1239 -0.413573 15.0474 1.21487C10.9709 2.81618 8.95455 5.7101 6.79813 9.21125C4.61216 12.7124 1.56437 17.8155 0.619089 22.2123C-0.32619 26.6362 -0.342971 29.6754 1.66575 33.6651C3.64494 37.6819 8.37134 39.6531 13.1568 39.9516Z"
						fill="url(#main_radial)"
						fill-opacity="0.4"
					/>
				</g>
				<path
					d="M13.1862 39.523L13.1836 39.5228C8.46713 39.2285 3.93162 37.2917 2.05117 33.4752L2.0495 33.4719C1.06679 31.52 0.587367 29.8178 0.463232 28.0711C0.338659 26.3181 0.57009 24.4978 1.03918 22.3023C1.96613 17.9911 4.96984 12.9508 7.16258 9.4388L7.16395 9.43657C9.3156 5.94316 11.2692 3.16058 15.2045 1.61477L15.2045 1.61478L15.2068 1.61386C19.1868 0.02396 23.995 0.0048412 27.685 1.73941L27.6873 1.74047C31.4125 3.46508 34.0329 5.99514 35.91 9.41785L35.9106 9.41905C37.6614 12.5874 37.9703 13.6033 39.0098 17.0213C39.1119 17.357 39.221 17.7159 39.3393 18.1024C40.5914 24.8904 36.7556 33.5993 27.9405 37.11C27.6185 37.1939 27.2986 37.2775 26.9806 37.3607C22.0335 38.6548 17.5674 39.823 13.1862 39.523Z"
					stroke="url(#grad)"
					stroke-width="1"
				/>

				{/* <!-- Inner Shape --> */}
				<g style={{ mixBlendMode: 'overlay' }}>
					<path
						d="M0.514801 21.25C-0.409466 24.4565 -0.235209 31.75 3.62744 34.7503C-2.62256 26.7503 6.37744 9.37281 19.6799 7.82238C24.6292 7.24552 28.9832 5.61361 30.2648 3.49994C27.2648 0.999935 23.5109 0.0485249 20.3774 0.000307351C17.2214 -0.0238014 12.819 1.31134 10.2648 4C5.5148 9 1.43907 18.0435 0.514801 21.25Z"
						fill="url(#radial_gradient)"
					/>
				</g>
				{/* <!-- Icon in the Middle --> */}
				<path
					d="M20 18L17.1126 15.1126L16.2876 15.9376L19.175 18.825L16.2876 21.7124L17.1126 22.5374L20 19.65L22.8874 22.5374L23.7124 21.7124L20.825 18.825L23.7124 15.9376L22.8874 15.1126L20 18Z"
					fill={isHovered ? '#fff' : '#BABFC9'}
					style={{ transition: 'fill 1s ease-in-out' }}
				/>
				<defs>
					<filter
						id="background_filter"
						x="-4.29619"
						y="-4.29643"
						width="48.5929"
						height="48.5929"
						filterUnits="userSpaceOnUse"
						colorInterpolationFilters="sRGB">
						<feFlood floodOpacity="0" result="BackgroundImageFix" />
						<feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
						<feColorMatrix
							in="SourceAlpha"
							type="matrix"
							values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
							result="hardAlpha"
						/>
						<feOffset dx="-4.29643" dy="-4.29643" />
						<feGaussianBlur stdDeviation="4.29643" />
						<feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
						<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0" />
						<feBlend mode="hard-light" in2="shape" result="effect1_innerShadow_9011_1948" />
						<feColorMatrix
							in="SourceAlpha"
							type="matrix"
							values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
							result="hardAlpha"
						/>
						<feOffset dx="4.29643" dy="4.29643" />
						<feGaussianBlur stdDeviation="4.29643" />
						<feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
						<feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.25 0" />
						<feBlend mode="hard-light" in2="effect1_innerShadow_9011_1948" result="effect2_innerShadow_9011_1948" />
					</filter>
					<radialGradient
						id="main_radial"
						cx="0"
						cy="0"
						r="1"
						gradientUnits="userSpaceOnUse"
						gradientTransform="translate(0.000244732 2) rotate(0.93286) scale(40.0618 31.4917)">
						<stop offset="0.0001" stopColor="#D9D9D9" />
						<stop offset="0.0002" stopColor="#D9D9D9" />
						<stop offset="1" stopColor="#D9D9D9" stopOpacity="0.01" />
					</radialGradient>
					<linearGradient
						id="grad"
						x1="31.0002"
						y1="34.5"
						x2="0.000240535"
						y2="19.50001"
						gradientUnits="userSpaceOnUse">
						{/* <stop stop-color="#21242c00" />
						<stop offset="0.105" stop-color="#29392A" />
						<stop offset="0.656989" stop-color="#29392A" />
						<stop offset="1" stop-color="#67D586" /> */}

						<animated.stop offset="0" stopColor={color1} />
						<animated.stop offset="0.105" stopColor={color2} />
						<animated.stop offset="1" stopColor={color3} />
					</linearGradient>

					<radialGradient
						id="radial_gradient"
						cx="0"
						cy="0"
						r="1"
						gradientUnits="userSpaceOnUse"
						gradientTransform="translate(8.28467 7.27949) rotate(75.9548) scale(28.3174 25.5692)">
						<stop offset={'0'} stopColor="#279445" />
						<stop offset="1" stopColor="#737373" stopOpacity="0" />
					</radialGradient>
				</defs>
			</svg>
		</Box>
	)
}
