import React, { useEffect, useState } from 'react'

import { Box } from 'components/layout/box'
import * as styles from './Quote.css'
import { Text } from 'components/typography/text'
import { useArenaContext } from 'context/ArenaContext'

// Helper function to select a random index
const getRandomIndex = (currentIndex: number | null, arrayLength: number): number => {
	if (arrayLength <= 1) return 0
	let randomIndex
	do {
		randomIndex = Math.floor(Math.random() * arrayLength)
	} while (randomIndex === currentIndex)
	return randomIndex
}

export const Quote = () => {
	const { arenaQuotes } = useArenaContext()
	// const fullText =
	// 	'You’re not a systematic investor until you can know your max drawdown under various market conditions.'
	const [displayedText, setDisplayedText] = useState('')
	const [selectedQuoteIndex, setSelectedQuoteIndex] = useState<number | null>(null)
	const [blinkerTransform, setBlinkerTransform] = useState('scaleY(0)')

	const [isTyping, setIsTyping] = useState(true)

	useEffect(() => {
		if (!arenaQuotes || arenaQuotes.length === 0) return

		// Select a new random quote index
		const randomIndex = getRandomIndex(selectedQuoteIndex, arenaQuotes.length)
		setSelectedQuoteIndex(randomIndex)

		const arenaQuote = arenaQuotes[randomIndex]
		let i = 0
		const speed = 40
		setDisplayedText('')
		setIsTyping(true)
		setBlinkerTransform('scaleY(1)')

		const typeWriter = () => {
			if (arenaQuote && i < arenaQuote.length) {
				setDisplayedText(arenaQuote.substring(0, i + 1))
				i++
				setTimeout(typeWriter, speed)
			} else {
				setIsTyping(false) // Stop blinking animation when typing is complete
			}
		}

		const delay = 600
		const timeoutId = setTimeout(typeWriter, delay)

		return () => clearTimeout(timeoutId) // Cleanup timeout on unmount
	}, [arenaQuotes])

	return (
		<Box className={styles.quoteContainer}>
			{!arenaQuotes || selectedQuoteIndex === null ? (
				''
			) : (
				<>
					<Box
						className={isTyping ? styles.blinkingBlinker : styles.blinker}
						style={{ transform: blinkerTransform }}></Box>
					<Text className={styles.typedText}>{displayedText}</Text>
					<Text style={{ opacity: 0 }}>{arenaQuotes[selectedQuoteIndex]}</Text>
				</>
			)}
		</Box>
	)
}
